import React from 'react';

import { Link } from '../../../../../parts/Document/Document';
import truncateString from '../../../../../../utils/truncateString';

import { ReactComponent as Document } from '../../../../../../assets/svg/document.svg';

const Attachment = (props) => {
  const { name, ext, link } = props;
  const linkProp = link
    ? { target: '_blank', rel: 'noreferrer', download: true }
    : { color: 'grey' };

  return (
    <div className="attach__item">
      <Document className="attach__item-icon" />
      <Link href={link} {...linkProp}>
        {truncateString(name, 15)}
        {ext}
      </Link>
    </div>
  );
};

export default Attachment;
