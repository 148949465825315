import React, { useEffect, useState } from 'react';
import getBrowserInfo from '../../../utils/getBrowser';
import { ReactComponent as ArrowRightIcon } from '../../../assets/svg/arrow-right.svg';
import cn from 'classnames';

export default function ExtensionDownload({ home }) {

    const [browser, setBrowser] = useState('chrome')

    useEffect(() => {
        let browserData = 'chrome'
        browserData = getBrowserInfo();
        setBrowser(browserData);
        // const onScroll = () => {
        //     if (browserData === '') {
        //         browserData = getBrowserInfo();
        //         setBrowser(browserData);
        //     }
        // }
        // window.addEventListener('scroll', onScroll)
        // return () => {
        //     window.removeEventListener("scroll", onScroll);
        // };
    }, [])



    const data = {
        'chrome': {
            title: 'Chrome',
            link: 'https://chrome.google.com/webstore/detail/mail1io-temporary-disposa/hcilmjejkckjojnhpoaajidieiekkaje',
            image: '/platform/chrome.png'
        },
        'firefox': {
            title: 'Firefox',
            link: 'https://addons.mozilla.org/en-US/firefox/addon/mail1-io-temp-mail/',
            image: '/platform/firefox.png'
        },
        'safari': {
            title: 'Safari',
            link: 'https://apps.apple.com/app/mail1-io-temp-mail/id1634440626',
            image: '/platform/safari.png'
        },
        // 'edge': {
        //     title: 'Edge',
        //     link: 'https://chrome.google.com/webstore/detail/mail1io-temporary-disposa/hcilmjejkckjojnhpoaajidieiekkaje',
        //     image: '/platform/edge.png'
        // }
    }



    return (<>
        {(browser === 'chrome' || browser === 'firefox' || browser === 'safari') ?
            <div className={cn("main mailbox browser-card",
                { 'browser-card-margin': home },
                { 'browser-card-account': !home })}>
                <a className="browser-ing"
                    href={data[browser].link}
                    target='_blank'>
                    <div className='browser-image'>
                        <img src={data[browser].image}
                            width='100%'
                            height='100%'
                            alt='extension download' />
                    </div>
                    <div className='browser-definition'>
                        <h4 className='browser-title'> {data[browser].title} </h4>
                        <p className='browser-descr'> Download Mail1 <span style={{ fontWeight: 700 }}> {data[browser].title} Extension </span>  to Manage faster.</p>
                    </div>
                    <div>
                        <ArrowRightIcon />
                    </div>
                </a>
            </div> : null}
    </>)
}
