import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '../../parts/Document/Document';

import styles from './button.module.scss';
import './button.scss';
export const variants = {
  cancel: styles['cancel'],
  submit: styles['submit'],

  reset: styles['reset'],
  delete: styles['delete'],
};

export const iconPositions = {
  left: Symbol.for('icon-left'),
  right: Symbol.for('icon-right'),
};

export const iconVariants = {
  lined: styles['lined'],
  centered: styles['centered'],
};

const Button = (props) => {
  const {
    children,
    value,
    className,
    variant,
    type,
    icon,
    iconPosition = 'right',
    iconClassName,
    iconVariant = 'lined',
    adaptive = false,
    ...rest
  } = props;
  const icP = iconPositions[iconPosition];
  const Icon = icon;
  const content = children ?? value ?? 'Submit';
  const isAdaptive = adaptive && !!icon;

  const { t } = useTranslation();

  const getBody = () => {
    if (!Icon) return <Trans t={t}>{content}</Trans>;

    if (icP === iconPositions.left)
      return (
        <div className={classnames(styles['wrapper'], iconVariants[iconVariant])}>
          <Icon
            className={classnames(
              styles['icon'],
              iconVariants[iconVariant],
              { [styles['adaptive-icon']]: isAdaptive },
              iconClassName
            )}
          />
          <Text
            size="unset"
            className={classnames(styles['text'], { [styles['adaptive-text']]: isAdaptive })}
          >
            {children}
          </Text>
        </div>
      );

    return (
      <div className={classnames(styles['wrapper'], iconVariants[iconVariant])}>
        <Text
          size="unset"
          className={classnames(styles['text'], { [styles['adaptive-text']]: isAdaptive })}
        >
          {children}
        </Text>
        <Icon
          className={classnames(
            styles['icon'],
            iconVariants[iconVariant],
            { [styles['adaptive-icon']]: isAdaptive },
            iconClassName
          )}
        />
      </div>
    );
  };

  return (
    <button
      className={classnames(
        styles['button'],
        variants[variant ?? type],
        {
          [styles['adaptive']]: isAdaptive,
        },
        className
      )}
      type={type ?? 'button'}
      {...rest}
    >
      {getBody()}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
};

export default Button;
