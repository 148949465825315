import urlJoin from 'url-join';
import Connector from './Connector';
import Debug from '../../utils/debug';

const debug = Debug.extend('connector:mailbox');

class MailboxConnector extends Connector {
  constructor(baseURL, { base, paths }) {
    super(urlJoin(baseURL + base));

    this.paths = {
      getCurrent: paths.getCurrent ?? '',
      refresh: paths.refresh ?? '',
      create: paths.create ?? '',
      getLocalWithCurrent: paths.getLocalWithCurrent ?? '',
      getAllRemote: paths.getAllRemote ?? '',
      getAll: paths.getAll ?? '',
      delete: paths.delete ?? '',
    };
  }

  async getCurrent() {
    debug('Get in getCurrent');
    const result = await super.get(this.paths.getCurrent);
    debug(`Get out getCurrent with mailbox: '%s'`, result.mailbox);
    return result;
  }

  async refreshMailbox(mailbox) {
    debug('Get in refreshMailbox by name: %s', !!mailbox);
    const result = await super.post(this.paths.refresh, { mailbox });
    debug(`Get out refreshMailbox`);
    return result;
  }

  async createMailbox(name) {
    debug('Get in createMailbox by name: %s', name);
    const result = await super.post(this.paths.create, { name });
    debug(`Get out createMailbox with mailbox: '%s'`, result.mailbox);
    return result;
  }

  async getLocalWithCurrent() {
    debug('Get in getLocalWithCurrent');
    const result = await super.get(this.paths.getLocalWithCurrent);
    debug(`Get out getLocalWithCurrent`);
    return result;
  }

  async getAllRemote() {
    debug('Get in getAllRemote');
    const result = await super.get(this.paths.getAllRemote);
    debug(`Get out getAllRemote`);
    return result;
  }

  async getAll() {
    debug('Get in getAll');
    const result = await super.get(this.paths.getAll);
    debug(`Get out getAll`);
    return result;
  }

  async delete(name) {
    debug(`Get in delete with mailbox name: '%s'`, name);
    const result = await super.post(this.paths.delete, { name });
    debug(`Get out delete`);
    return result;
  }
}

export default MailboxConnector;
