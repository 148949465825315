export default {
  home: { path: '/' },
  mailbox: { path: '/mailbox' },
  mail: { path: '/mail' },
  register: { path: '/register' },
  recoverPassword: { path: '/recover-password' },
  completePasswordRecovery: { path: '/password-recovery/confirm' },
  login: { path: '/login' },
  account: { path: '/account' },
  emailConfirm: { path: '/email/confirm' },
  resendConfirmation: { path: '/email/resend' },
  identityTransfer: { path: '/identity-transfer' },
  authTransfer: { path: '/auth-transfer' },
  terms: { path: '/terms-conditions' },
  pp: { path: '/privacy-policy' },
  contacts: { path: '/contacts' },
  faq: { path: '/faq' },

};
