import React from 'react';

import withScrollToTop from '../../../hoc/withScrollToTop';
import { PagePaper, Advertisement } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import { Container, Title, Text } from '../../parts/Document/Document';
import routeConfig from '../../../config/routes.config';
import emailsConfig from '../../../config/emails.config';

import './contact-us.scss';

export const config = { path: routeConfig.contacts.path };

const ContactUs = (props) => {
  return (
    <PagePaper variant="adaptive" className="main">
      <PageToolbar backLink={routeConfig.home.path} 
      backLinkText="Back to Main page" />

      <div className="main__body main__body_secondary">
        <Container variant="centered" withMargin className="main__title">
          <Title className="title-text">Contact Us</Title>
        </Container>
        <div className="main__content">
          <div className="main__section main__section_contact">
            <div className="main__sub-section">
              <div className="row">
                <div className="col col_4 col_tab-12">
                  <div className="contact__content">
                    <div className="contact__description">
                      <p>
                        <strong>Def24 INC</strong>
                        <br />
                        815 Ponce de Leon Blvd Second Floor
                        <br />
                        Coral Gables, FL 33134, USA
                      </p>
                    </div>
                    <div className="contact__support contact-support">
                      <div className="contact-support__list">
                        <div className="contact-support__item">
                          <a href={`mailto:${emailsConfig.contact}`} className="contact-support__link">
                            <span className="contact-support__text">{emailsConfig.contact}</span>
                          </a>
                        </div>
                        <div className="contact-support__item">
                          <a href={`mailto:${emailsConfig.abuse}`} className="contact-support__link">
                            <svg
                              className="contact-support__ico"
                              width="19"
                              height="16"
                              viewBox="0 0 19 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.7556 14.6231L10.5792 0.455392C10.2147 -0.151797 9.34422 -0.151797 8.98056 0.455392L0.804151 14.6231C0.439675 15.2303 0.884794 15.9996 1.61374 15.9996H17.9872C18.6545 16.0202 19.1003 15.2509 18.7556 14.6231ZM9.76958 13.4696C9.26359 13.4696 8.87936 13.0648 8.87936 12.5794C8.87936 12.0734 9.28415 11.6892 9.76958 11.6892C10.2756 11.6892 10.6598 12.094 10.6598 12.5794C10.6598 13.0648 10.2756 13.4696 9.76958 13.4696ZM11.0045 5.90042L10.5191 9.96899C10.5191 10.3738 10.1752 10.7177 9.77039 10.7177C9.3656 10.7177 9.02169 10.3738 9.02169 9.96899L8.53626 5.90042L8.5157 5.73835C8.5157 5.23236 9.08257 4.82756 9.7704 4.82756C10.4582 4.82756 11.0251 5.23236 11.0251 5.73835L11.0045 5.90042Z"
                                fill="#DD0000"
                              />
                            </svg>
                            <Text className="contact-support__text">Report Abuse</Text>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="contact__socials contact-socials">
                      <Text className="contact-socials__title">Follow us:</Text>
                      <ul className="contact-socials__list">
                        <li className="contact-socials__item">
                          <a href="#" className="contact-socials__link">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g>
                                <path
                                  d="M18.4243 0.0253906H6.42432C3.11061 0.0253906 0.424316 2.71168 0.424316 6.02539V18.0254C0.424316 21.3391 3.11061 24.0254 6.42432 24.0254H18.4243C21.738 24.0254 24.4243 21.3391 24.4243 18.0254V6.02539C24.4243 2.71168 21.738 0.0253906 18.4243 0.0253906Z"
                                  fill="#5FA9DD"
                                />
                                <path
                                  d="M19.8243 7.42578C19.3243 7.62578 18.7243 7.82578 18.1243 7.92578C18.7243 7.52578 19.2243 6.92578 19.4243 6.22578C18.8243 6.52578 18.2243 6.82578 17.5243 6.92578C16.9243 6.32578 16.2243 5.92578 15.3243 5.92578C13.6243 5.92578 12.3243 7.32578 12.3243 8.92578C12.3243 9.12578 12.3243 9.42578 12.4243 9.62578C9.82432 9.62578 7.52432 8.42578 6.02432 6.52578C5.82432 7.02578 5.62432 7.52578 5.62432 8.12578C5.62432 9.22578 6.12432 10.1258 7.02432 10.6258C6.52432 10.6258 6.02432 10.4258 5.62432 10.2258C5.62432 11.7258 6.62432 12.9258 8.02432 13.2258C7.72432 13.3258 7.52432 13.3258 7.22432 13.3258C7.02432 13.3258 6.82432 13.3258 6.62432 13.2258C7.02432 14.4258 8.12432 15.3258 9.42432 15.3258C8.42432 16.1258 7.02432 16.6258 5.62432 16.6258C5.42432 16.6258 5.12432 16.6258 4.92432 16.6258C6.32432 17.5258 7.92432 18.0258 9.62432 18.0258C15.2243 18.0258 18.3243 13.4258 18.3243 9.32578C18.3243 9.22578 18.3243 9.02578 18.3243 8.92578C18.9243 8.52578 19.4243 8.02578 19.8243 7.42578Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect
                                    width="24"
                                    height="24"
                                    fill="white"
                                    transform="translate(0.424316 0.0253906)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </li>
                        <li className="contact-socials__item">
                          <a href="#" className="contact-socials__link">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g>
                                <path
                                  d="M18.4243 0.974609H6.42432C3.11061 0.974609 0.424316 3.6609 0.424316 6.97461V18.9746C0.424316 22.2883 3.11061 24.9746 6.42432 24.9746H18.4243C21.738 24.9746 24.4243 22.2883 24.4243 18.9746V6.97461C24.4243 3.6609 21.738 0.974609 18.4243 0.974609Z"
                                  fill="#E70000"
                                />
                                <path
                                  d="M19.5243 9.37441C19.3243 8.77441 18.8243 8.27441 18.2243 8.07441C17.0243 7.77441 12.4243 7.77441 12.4243 7.77441C12.4243 7.77441 7.82429 7.77441 6.62429 8.07441C6.02429 8.27441 5.52429 8.77441 5.32429 9.37441C5.02429 10.5744 5.02429 12.9744 5.02429 12.9744C5.02429 12.9744 5.02429 15.3744 5.32429 16.5744C5.52429 17.1744 6.02429 17.6744 6.62429 17.8744C7.82429 18.1744 12.4243 18.1744 12.4243 18.1744C12.4243 18.1744 17.0243 18.1744 18.2243 17.8744C18.8243 17.6744 19.3243 17.1744 19.5243 16.5744C19.8243 15.3744 19.8243 12.9744 19.8243 12.9744C19.8243 12.9744 19.8243 10.5744 19.5243 9.37441Z"
                                  fill="white"
                                />
                                <path
                                  d="M10.9243 15.1744V10.7744L14.8243 12.9744L10.9243 15.1744Z"
                                  fill="#E70000"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect
                                    width="24"
                                    height="24"
                                    fill="white"
                                    transform="translate(0.424316 0.974609)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col col_8 col_tab-12">
                  <div className="contact__map contact-map">
                    {/* <Map /> */}
                    <img src='/img/map.png' alt='def24 inc'  className='map-img'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main__footer">
        <Advertisement />
      </div>
    </PagePaper>
  );
};

export default withScrollToTop(ContactUs);
