import React, { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';

import { Text } from '../../parts/Document/Document';

const offset = ({ placement }) => {
  if (/top|bottom/.test(placement)) {
    return [0, 10];
  } else if (/left|right/.test(placement)) {
    return [10, 0];
  } else {
    return [0, 0];
  }
};

const Tooltip = (props) => {
  const {
    innerRef,
    message,
    enabled = false,
    placement = 'top',
    timeout,
    onClose = () => {},
  } = props;
  const [popperElement, setPopperElement] = useState(null);
  const [innerEnabled, setInnerEnabled] = useState(enabled);

  const { styles: pStyles, attributes } = usePopper(innerRef, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: { offset },
      },
      { name: 'preventOverflow', enabled: true },
    ],
  });

  useEffect(() => {
    let ts = null;

    if (enabled === true && timeout) {
      ts = setTimeout(() => {
        onClose();
      }, timeout);
    }

    return () => clearTimeout(ts);
  }, [enabled, timeout]);

  useEffect(() => {
    setInnerEnabled(enabled);
  }, [enabled]);

  if (!innerEnabled) return null;
  return (
    <div
      ref={setPopperElement}
      className="tooltip-message"
      style={pStyles.popper}
      {...attributes.popper}
    >
      <Text>{message}</Text>
    </div>
  );
};

export default Tooltip;
