import { useQuery } from 'react-query';

import NotificationService from './NotificationService';
import { hostConnector } from '../connectors';

class HostService {
  static useGetAll(config) {
    return useQuery('HostService.useGetAll', () => hostConnector.getAll(), {
      onError() {
        NotificationService.error('Error occurred');
      },
      retry: false,
      refetchOnWindowFocus: false,
      ...config,
    });
  }
}

export default HostService;
