import React, { useState } from 'react';

import Validator from '../../../../libs/Validator';
import AuthorizationService from '../../../../api/services/AuthorizationService';

import Form, { InputField, FieldGroup, Submit, Toolbar } from '../../../parts/Form/Form';
import { Text, Title } from '../../../parts/Document/Document';

const validator = new Validator({
  email: Validator.Presets.email,
});

const FormComponent = (props) => {
  const { onSuccess = () => {} } = props;

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const [recoverPassword, { isLoading }] = AuthorizationService.useRecoverPassword({ onSuccess });

  const validate = () => {
    const { error } = validator.validate({ email });

    if (error) {
      setEmailValid(error.email);
      return false;
    } else {
      setEmailValid(null);
      return true;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) recoverPassword({ email });
  };

  return (
    <>
      <Title popUpTitle className="title-text">
        Retrieve your password
      </Title>

      <div className="popup__content">
        <Form onSubmit={submitHandler} loading={isLoading}>
          <FieldGroup>
            <InputField
              label="Email that was used for registration:"
              placeholder="name@domain.com"
              name="email"
              value={email}
              onChange={setEmail}
              valid={!emailValid}
              errorMessage={emailValid}
            />
          </FieldGroup>

          <div className="form-actions">
            <div className="form-actions__item">
              <Submit wide>Send me my password</Submit>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default FormComponent;
