import React from 'react';
import classnames from 'classnames';

import TranslationService from '../../../../../../api/services/TranslationService';
import concatMailbox from '../../../../../../utils/concatMailbox';

import { Text } from '../../../../../parts/Document/Document';

import { ReactComponent as Warning } from '../../../../../../assets/svg/warning.svg';

const Info = (props) => {
  const { mailbox, domain, expirationDate } = props;
  const moment = TranslationService.useMoment();

  const date = expirationDate ? moment(expirationDate) : null;
  const isDate = date && date.isValid();
  const expDate = isDate ? date.diff(moment(), 'days') < 31 : false;
  const warningExpDate = isDate ? date.diff(moment(), 'days') < 4 : false;

  return (
    <div className="mailboxes-item__header">
      <Text className="title-text">{concatMailbox(mailbox, domain)}</Text>
      {expDate && (
        <Text className={classnames('expiration', { ['warning']: warningExpDate })}>
          {warningExpDate && <Warning className="expiration-icon" />}
          <Text>Expires {' '} </Text> {moment(expirationDate).fromNow()}
        </Text>
      )}
    </div>
  );
};

export default React.memo(Info);
