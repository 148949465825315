import React, { useEffect, useRef } from 'react';

import MailAlert from './MailAlert/MailAlert';
import Attachments from './Attachments/Attachments';
import Header from './Header/Header';

const Body = (props) => {
  const { mail, onDelete = () => {} } = props;

  const body = useRef();

  useEffect(() => {
    if (body.current) body.current.innerHTML = mail.html;
  }, [mail]);

  return (
    <div className="main__section main__section_mail">
      <Header mail={mail} onDelete={onDelete} />

      <div className="main__sub-section main__sub-section_mail-body mail-body">
        {mail.spam && <MailAlert />}
        <div className="mail__content" ref={body}></div>
      </div>

      {mail.attachments.length ? (
        <div className="main__sub-section main__sub-section_remove-padding main__sub-section_mail-attach">
          <Attachments attachments={mail.attachments} />
        </div>
      ) : null}
    </div>
  );
};

export default Body;
