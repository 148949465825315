import React from 'react';
import { useSelector } from 'react-redux';

import Attachment from './Attachment/Attachment';
import { Text, Space } from '../../../../parts/Document/Document';

const Attachments = (props) => {
  const { attachments } = props;
  const { isRegistered } = useSelector((state) => state.auth);

  const getAttachments = (list = []) => {
    return list.map((att) => (
      <Attachment key={att.filename} name={att.baseName} ext={att.baseExt} link={att.url} />
    ));
  };

  return (
    <div className="attach">
      <div className="attach__title">
        <svg
          className="title-ico"
          width="25"
          height="12"
          viewBox="0 0 25 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.6227 12L4.41816 11.9777H4.41709C4.07127 11.9777 2.8571 11.9149 1.71854 11.1211C0.934292 10.5741 -0.00105851 9.53023 3.89441e-06 7.66471C-0.00212418 5.75571 0.868314 4.71722 1.59936 4.18411C2.64857 3.41796 3.86802 3.33389 4.34689 3.33389H4.34902L17.628 3.35411C18.11 3.35624 19.2699 3.35305 20.0616 4.14367C20.5457 4.62782 20.7916 5.29716 20.7905 6.13248C20.7916 7.70416 19.6072 8.64485 17.6215 8.64591L4.39683 8.65336C3.94566 8.65336 3.57959 8.28729 3.57959 7.83719C3.57959 7.38602 3.94566 7.01995 4.39576 7.01889L17.6183 7.01251C19.1538 7.01144 19.1538 6.39533 19.1538 6.13248C19.1538 5.74621 19.0698 5.46636 18.9037 5.29929C18.592 4.98751 17.9375 4.98645 17.6258 4.98751L4.34573 4.9673H4.34467C4.02861 4.9673 3.2231 5.01944 2.56334 5.50253C1.94511 5.9537 1.63224 6.68155 1.63331 7.66373C1.63331 8.59589 1.97594 9.30669 2.65272 9.77916C3.24862 10.1942 3.97541 10.3421 4.41808 10.3421H4.41914L17.6237 10.3644C19.5593 10.3634 22.8112 9.78873 22.8123 5.94945C22.8113 2.3836 19.9923 1.63447 17.6281 1.63447L6.68588 1.66958H6.68268C6.23258 1.66958 5.86651 1.30567 5.86545 0.855536C5.86439 0.404369 6.22936 0.0372413 6.6795 0.0351165L17.625 0C21.9601 0 24.4459 2.16864 24.447 5.94945C24.4448 9.73651 21.8941 11.9978 17.6227 12Z"
            fill="#6F6F6F"
          />
        </svg>

        <Text className="title-text">attachments</Text>
        {!isRegistered && (
          <>
            <Space />
            <Text className="title-text">For registered users only. Please Register or Login.</Text>
          </>
        )}
      </div>
      <div className="attach__list">{getAttachments(attachments)}</div>
    </div>
  );
};

export default Attachments;
