import React from 'react';

import { Text } from '../../../parts/Document/Document';

import './about.scss';

const About = (props) => {
  return (
    <div className="about">
      <div className="about__wysiwyg wysiwyg">
        <h1>
          <Text>Benefits of Mail1 Disposable Temporary E-mail</Text>
        </h1>
        <p>
          <Text>
            Many forums, Wi-Fi owners, websites and blogs ask visitors to register before they can
            view content, post comments or download something. Mail1 — is most advanced throwaway
            email service that helps you avoid spam and stay safe.
          </Text>
        </p>
      </div>
      <div className="about__cards about-cards">
        <div className="about-cards__list">
          <div className="row">
            <div className="col col_4 col_mob-12">
              <div className="about-cards__item card-item">
                <div className="card-item__media">
                  <svg
                    className="ico"
                    width="95"
                    height="95"
                    viewBox="0 0 95 95"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9758 26.4204L46.0654 58.1417L80.1549 26.4204H11.9758ZM9.44763 28.7357V71.6876L31.6687 49.3865L9.44763 28.7357ZM82.6834 28.7357L60.4623 49.3865L82.6834 71.6876V28.7357ZM34.1434 51.7019L11.8423 74.1087H80.2879L57.9868 51.7019L47.2356 61.708C46.5803 62.33 45.549 62.33 44.8938 61.708L34.1434 51.7019Z"
                      fill="#12141B"
                    />
                    <path
                      d="M74.9428 13.1317C74.9428 13.1317 74.7482 15.2732 74.1561 15.8682C73.5651 16.4624 71.3936 16.6996 71.3936 16.6996C71.3936 16.6996 73.5246 16.9223 74.1197 17.5133C74.7138 18.1043 74.9615 20.2479 74.9615 20.2479C74.9615 20.2479 75.2153 18.1034 75.8064 17.5082C76.3974 16.9141 78.5106 16.68 78.5106 16.68C78.5106 16.68 76.3411 16.4687 75.747 15.8767C75.1528 15.2857 74.9426 13.1306 74.9426 13.1306L74.9428 13.1317ZM82.7915 15.4365C82.7915 15.4365 82.188 22.0855 80.3525 23.9305C78.517 25.7764 71.7765 26.5099 71.7765 26.5099C71.7765 26.5099 78.3922 27.2009 80.2372 29.0374C82.0831 30.8729 82.8499 37.526 82.8499 37.526C82.8499 37.526 83.6366 30.8698 85.4721 29.0238C87.3076 27.1779 93.8662 26.4527 93.8662 26.4527C93.8662 26.4527 87.134 25.7971 85.288 23.9606C83.4421 22.1251 82.7915 15.4365 82.7915 15.4365ZM91.1987 31.0217C91.1987 31.0217 91.0041 33.1641 90.4131 33.7581C89.8221 34.3522 87.6505 34.5885 87.6505 34.5885C87.6505 34.5885 89.7826 34.8112 90.3766 35.4032C90.9708 35.9943 91.2184 38.1379 91.2184 38.1379C91.2184 38.1379 91.4723 35.9933 92.0634 35.3992C92.6544 34.8052 94.7676 34.571 94.7676 34.571C94.7676 34.571 92.5991 34.3597 92.005 33.7687C91.4109 33.1777 91.2007 31.0218 91.2007 31.0218L91.1987 31.0217Z"
                      fill="#DD0000"
                    />
                  </svg>
                </div>
                <div className="card-item__content">
                  <p>
                    <Text>Keep your real mailbox clean and secure.</Text>
                  </p>
                </div>
              </div>
            </div>
            <div className="col col_4 col_mob-12">
              <div className="about-cards__item card-item">
                <div className="card-item__media">
                  <svg
                    className="ico"
                    width="94"
                    height="95"
                    viewBox="0 0 94 95"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M60.9425 15.7081C60.5798 15.7141 60.2231 15.804 59.8993 15.9689L34.8703 29.0316V59.5734L59.8993 72.6362C60.6427 73.0258 61.533 72.9959 62.2494 72.5612C62.9688 72.1266 63.4064 71.3473 63.4064 70.5109V18.0963C63.4034 17.4489 63.1396 16.8314 62.678 16.3817C62.2134 15.9321 61.5869 15.6893 60.9425 15.7073L60.9425 15.7081ZM77.7438 27.0174C77.1204 27.0384 76.5329 27.2992 76.0982 27.7428L71.7249 32.1132C71.2573 32.5598 70.9905 33.1743 70.9815 33.8188C70.9755 34.4633 71.2243 35.0837 71.6799 35.5394C72.1355 35.998 72.753 36.2528 73.3975 36.2468C74.042 36.2408 74.6594 35.977 75.1061 35.5154L79.4945 31.127C80.1989 30.4346 80.4057 29.3825 80.0191 28.4743C79.6324 27.566 78.73 26.9904 77.7438 27.0174ZM11.9849 30.5874C10.66 30.5874 9.58691 31.6605 9.58691 32.9854V55.6226V55.6196C9.58691 56.9445 10.66 58.0176 11.9849 58.0176H30.0931V30.5844L11.9849 30.5874ZM77.7623 41.9029C77.1239 41.9029 76.5124 42.1547 76.0628 42.6044C75.6131 43.054 75.3584 43.6655 75.3584 44.3039C75.3584 44.9394 75.6131 45.5509 76.0628 46.0005C76.5124 46.4531 77.1239 46.7049 77.7623 46.7019H83.9582C84.5966 46.7049 85.2081 46.4531 85.6577 46.0005C86.1073 45.5508 86.3621 44.9394 86.3621 44.3039C86.3621 43.6654 86.1073 43.054 85.6577 42.6044C85.2081 42.1548 84.5966 41.9029 83.9582 41.9029H77.7623ZM73.389 52.3674C72.4088 52.3674 71.5305 52.9609 71.1649 53.8692C70.7992 54.7744 71.021 55.8146 71.7254 56.492L76.0987 60.8624C76.5394 61.345 77.1599 61.6267 77.8133 61.6417C78.4668 61.6567 79.0993 61.4049 79.5608 60.9433C80.0255 60.4817 80.2802 59.8522 80.2653 59.1987C80.2533 58.5453 79.9745 57.9248 79.4949 57.4812L75.1065 53.0928C74.6539 52.6282 74.0365 52.3674 73.389 52.3674ZM15.1178 62.8166L18.3582 75.1481H18.3552C18.6309 76.2002 19.5842 76.9346 20.6752 76.9346H30.1982H30.1952C30.9385 76.9346 31.6399 76.5899 32.0926 76.0024C32.5482 75.4149 32.7041 74.6475 32.5153 73.9281L29.6017 62.8137L15.1178 62.8166Z"
                      fill="#12141B"
                    />
                    <line x1="9.05191" y1="17.8148" x2="68.1224" y2="76.8853" stroke="#DD0000" />
                  </svg>
                </div>
                <div className="card-item__content">
                  <p>
                    <Text>Forget about SPAM, ADs, hacking and attacking robots.</Text>
                  </p>
                </div>
              </div>
            </div>
            <div className="col col_4 col_mob-12">
              <div className="about-cards__item card-item">
                <div className="card-item__media">
                  <svg
                    className="ico"
                    width="94"
                    height="95"
                    viewBox="0 0 94 95"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M61.0403 23.3133C61.7522 22.3642 62.9355 23.5506 62.461 24.2624C61.0403 26.1576 59.8539 28.5274 58.6705 27.8156C57.7214 27.1037 59.6197 25.2085 61.0403 23.3133Z"
                      fill="#DD0000"
                    />
                    <path
                      d="M65.0684 24.9708C65.0684 24.0217 66.7264 24.0217 66.7264 24.9708C66.7264 27.3406 67.2009 29.9446 66.0145 29.9446C64.5939 29.9446 65.0654 27.3375 65.0654 24.9708H65.0684Z"
                      fill="#DD0000"
                    />
                    <path
                      d="M69.8074 24.4967C69.0955 23.5475 70.2819 22.6015 70.9907 23.3133C72.4114 25.2085 74.3065 26.6291 73.1232 27.5783C72.174 28.2901 70.9907 26.1576 69.8074 24.4997V24.4967Z"
                      fill="#DD0000"
                    />
                    <path
                      d="M55.8294 35.6304C81.8876 46.29 74.3071 85.38 46.1165 85.38C17.926 85.38 10.5789 46.2892 36.4036 35.6304C36.4036 27.3375 35.4545 26.8665 44.459 26.8665C44.459 19.2857 44.6963 8.62321 55.1187 8.15145C61.0416 7.67688 67.6732 11.7046 67.6732 19.5227C67.6732 21.6551 64.5947 21.6551 64.5947 19.5227C64.5947 14.5489 60.3297 10.9931 56.0651 11.2297C47.0637 11.2297 47.7722 20.706 47.7722 26.8657C55.5903 26.8657 55.8276 27.103 55.8276 35.6296L55.8294 35.6304ZM39.2468 74.4837C41.8539 75.9044 39.7213 79.6948 37.1143 78.2741C21.0034 68.7979 26.9264 45.3439 32.3747 48.4253C33.5581 48.8999 31.4256 51.5039 30.7168 55.7689C30.0049 61.4545 30.9545 69.7472 39.2468 74.4837Z"
                      fill="#12141B"
                    />
                  </svg>
                </div>
                <div className="card-item__content">
                  <p>
                    <Text>Self-destructed after a certain time elapses.</Text>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
