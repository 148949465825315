import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from '../../parts/Document/Document';
import { Input } from '../../utils';

import { ReactComponent as EyeIcon } from '../../../assets/svg/eye.svg';
import { ReactComponent as EyeClosedIcon } from '../../../assets/svg/eye-closed.svg';

export const inputTypes = {
  text: Symbol.for('text'),
  password: Symbol.for('password'),
};

const InputField = (props) => {
  const { label, type, inputClassName, valid, errorMessage, children, ...rest } = props;
  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  const textInput = (
    <Input
      type="text"
      className={classnames(inputClassName, {
        ['error']: valid === false,
      })}
      {...rest}
    />
  );

  const passwordInput = (
    <div className="form-field_with-ico">
      <div className="form-field__ico">
        {inputType === 'text' ? (
          <EyeClosedIcon className="ico" onClick={() => setInputType('password')} />
        ) : (
          <EyeIcon className="ico" onClick={() => setInputType('text')} />
        )}
      </div>
      <Input
        type={inputType}
        className={classnames(inputClassName, {
          ['error']: valid === false,
        })}
        {...rest}
      />
    </div>
  );

  const getInput = (type) => {
    const t = inputTypes[type] ?? inputTypes.text;

    switch (t) {
      case inputTypes.text:
        return textInput;
      case inputTypes.password:
        return passwordInput;
      default:
        return textInput;
    }
  };

  return (
    <div className="form-field">
      <label className="label-wrap">
        <Text
          className={classnames('form-field__title', {
            ['form-field__error-message']: valid === false,
          })}
        >
          {valid === false && errorMessage ? errorMessage : label}
        </Text>
        <div className="form-field__input-wrap">{getInput(type)}</div>
      </label>
      {children}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(inputTypes)),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  valid: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default InputField;
