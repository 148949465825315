import classNames from 'classnames';
import React from 'react'
import StringElement from './StringElement';

const FaqItem = ({ id, q, a, check, setAccordion }) => {
    return (
        <div className="faq appear-animation fadeInUp appear-animation-visible" >
            <div className="faq-header"
                onClick={() => setAccordion(id)}>
                <a className={classNames('', {"expand": !check}, {"collapse": check} )} >
                    {q}
                </a>
            </div>
            <div 
            id={id}
            className={classNames("faq-body", {"collapsed": !check}, {"expanded": check})}
            style={check ? { display:"block", maxHeight: "600px" } : {display:"none"}}>
                <StringElement info={a} />
            </div>
        </div>
    )
}

export default FaqItem;