import React from 'react';

import withAuthorization from '../../../hoc/withAuthorization';
import withScrollToTop from '../../../hoc/withScrollToTop';
import { PagePaper, FormWrapper } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import RecoverPasswordForm from '../../forms/RecoverPassword/RecoverPassword';

import routeConfig from '../../../config/routes.config';

export const config = { path: routeConfig.recoverPassword.path };

const RecoverPassword = (props) => {
  return (
    <PagePaper className="main">
      <PageToolbar />
      <FormWrapper>
        <RecoverPasswordForm />
      </FormWrapper>
    </PagePaper>
  );
};

export default withAuthorization(false)(withScrollToTop(RecoverPassword));
