import store, { rootActions } from '../storage';

class AppService {
  static handleServerExpiration() {
    const {
      appActions: { setConnectionState },
    } = rootActions;

    store.dispatch(setConnectionState(false));
  }
}

export default AppService;
