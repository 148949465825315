import React from 'react';

import { ReactComponent as Logo } from '../../../../../assets/svg/logo.svg';
import { ReactComponent as Close } from '../../../../../assets/svg/close.svg';

const Header = (props) => {
  const { onClose = () => {}, withoutCloseButton = false } = props;
  return (
    <div className="popup__header">
      <Logo className="popup__logo" />
      {withoutCloseButton ? null : <Close className="popup__close" onClick={onClose} />}
    </div>
  );
};

export default Header;
