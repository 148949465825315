import React, { useState } from 'react';
import { nanoid } from 'nanoid';

import PopupContext from '../../../context/PopupContext';
import Popup from './Popup/Popup';

import RegistrationForm from '../../forms/Registration/Registration';
import AuthorizationForm from '../../forms/Authorization/Authorization';
import RecoverPasswordForm from '../../forms/RecoverPassword/RecoverPassword';
import ResendEmailConfirmationForm from '../../forms/ResendEmailConfirmation/ResendEmailConfirmation';

const PopupController = (props) => {
  const { children } = props;
  const [current, setCurrent] = useState(null);

  const close = (id) => {
    setCurrent((current) => {
      if (current && current.id === id) return null;
      return current;
    });
  };

  const open = ({ id, content, variant, bodySize, open: o, onClose = () => {}, unclosable }) => {
    id = id ?? nanoid();
    const closeFn = () => close(id);

    setCurrent({
      id,
      content,
      variant,
      bodySize,
      open: o,
      unclosable,
      onClose: (...args) => {
        closeFn();
        onClose(...args);
      },
    });

    return {
      close: closeFn,
      update: ({ onClose, ...rest }) => {
        setCurrent((current) => {
          if (current && current.id === id) {
            return {
              ...current,
              ...rest,
              onClose: (...args) => {
                closeFn();
                onClose(...args);
              },
            };
          } else {
            return current;
          }
        });
      },
    };
  };

  const openRegistration = () => {
    const id = nanoid();
    open({
      id,
      content: <RegistrationForm onSuccess={() => close(id)} />,
      open: true,
    });
  };

  const openAuthorization = () => {
    const id = nanoid();
    open({
      id,
      content: <AuthorizationForm onSuccess={() => close(id)} />,
      open: true,
    });
  };

  const openRecoverPassword = () => {
    const id = nanoid();
    open({
      id,
      content: <RecoverPasswordForm onSuccess={() => close(id)} />,
      open: true,
    });
  };

  const openResendEmailConfirmation = () => {
    const id = nanoid();
    open({
      id,
      content: <ResendEmailConfirmationForm onSuccess={() => close(id)} />,
      open: true,
    });
  };

  return (
    <>
      <PopupContext.Provider
        value={{
          useOpen: () => open,
          openRegistration,
          openAuthorization,
          openRecoverPassword,
          openResendEmailConfirmation,
        }}
      >
        {children}
        <Popup {...current} />
      </PopupContext.Provider>
    </>
  );
};

export default PopupController;
