import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Validator from '../../../../libs/Validator';

import MailboxService from '../../../../api/services/MailboxService';
import NotificationService from '../../../../api/services/NotificationService';
import concatMailbox from '../../../../utils/concatMailbox';

import Header from './Header/Header';
import Toolbar from './Toolbar/Toolbar';
// import Timer from './Timer/Timer';
import Input from './Input/Input';

import { Button, Loader, PagePaper } from '../../../utils';
import { Text } from '../../../parts/Document/Document';
import Form, { ErrorMessage } from '../../../parts/Form/Form';

import routeConfig from '../../../../config/routes.config';

import { ReactComponent as InboxIcon } from '../../../../assets/svg/inbox.svg';
import Badge from './Badge';

const validator = new Validator({
  mailbox: Validator.Joi.string().max(50),
});

const MailboxForm = (props) => {
  const { isRegistered } = useSelector((state) => state.auth);
  const {
    data: getData,
    isFetched,
    isLoading,
    refetch: getMailboxes,
  } = MailboxService.useGetCurrent();


  const [refreshMailbox, { isLoading: isRefreshing }] = MailboxService.useRefreshMailbox();
  const [
    createMailbox,
    { isLoading: isCreating, isError: createError },
  ] = MailboxService.useCreateMailbox({
    onSuccess: () => {
      NotificationService.success('Mailbox successfully created.');
      getMailboxes();
    },
  });

  const [current, setCurrent] = useState(null);
  const [values, setValues] = useState([]);
  const [value, setValue] = useState('');
  const [validValue, setValidValue] = useState(null);
  const [disturbed, setDisturbed] = useState(false);
  const [displayCreateError, setDisplayCreateError] = useState(false);

  const changeHandler = (value, isDisturbed) => {
    setValue(value);
    setDisturbed(isDisturbed);
  };

  const refreshHandler = () => {
    refreshMailbox({ mailboxName: current?.name }).then(({ list, current }) => {
      setCurrent(current);
      setValues(list);
    });
  };

  const validate = () => {
    const { error } = validator.validate({ mailbox: value });

    if (error) {
      setValidValue(false);
      return false;
    } else {
      setValidValue(null);
      return true;
    }
  };


  const [errorMessage, setErrormessage] = useState('');

  const validMail1Prefix = (prefix) => {
    // send the prefix without @
    // we do not need to check the email domain
    setErrormessage('');
    if (!prefix || typeof prefix !== 'string'){
      setErrormessage('The input field is required.');
      return false
    } 
    // we will skip some of the common prefixes they may be dangerous to give to the user
    if (['abuse', 'admin', 'administrator', 'hostmaster', 'majordomo', 'postmaster', 'root', 'ssl-admin', 'webmaster', 'daemon', 'list'
      , 'bin', 'sys', 'mail', 'www-data', 'backup', 'nobody', 'manager','info'].includes(prefix.toLowerCase()))
     {
      setErrormessage(`"${prefix}" value is not allowed.`);
      return false
     }
    if(!(prefix.search(/^[a-zA-Z0-9_][a-zA-Z0-9_.-]{3,40}$/) === 0)){
      setErrormessage(`Invalid custom email address. Length between 4-30 chars, no special chars are allowed.`);
      return false
    }
    return true
  }

  const createHandler = () => {
    if (!disturbed) return;
    const validEmail = validate(value);
    const email = value.toLowerCase().trim()
    const result = validMail1Prefix(email)
    if (validEmail && result) createMailbox({ name: value, withRedirect: false });
  };

  useEffect(() => {
    if (!getData) return;
    setCurrent(getData.current);
    setValues(getData.list);
    setDisturbed(false);
  }, [getData]);

  useEffect(() => {
    if (current) {
      setValue(current.name);
      setDisturbed(false);
    }
  }, [current]);

  useEffect(() => {
    if (!disturbed) {
      setValidValue(null);
      setDisplayCreateError(false);
    }
  }, [disturbed]);

  useEffect(() => {
    if (!isCreating && createError) setDisplayCreateError(true);
    else setDisplayCreateError(false);
  }, [isCreating, createError]);

  const { list } = useSelector((state) => state.notification);


  const formLoading = (isFetched && isLoading) || isRefreshing || isCreating;

  const [blob, setBlob] = useState(false);

  useEffect(() => {
    clearTimeout(setBlobb);
    var setBlobb = setTimeout(() => setBlob(false)
    , 30000);
    if (list.length > 0 &&
      current && current.hasOwnProperty('mailbox')
      && list[0]['title'].includes(`New mail on ${current.mailbox}`)) {
        setBlob(true);
    }
    return () => clearTimeout(setBlobb)
  }, [list])

  const [enteredValue, setEnteredValue] = useState('');
  const [originalValue, setOriginalValue] = useState(value);

  const cancelHandler = () => {
    setEnteredValue('');
    setErrormessage('');
    changeHandler(originalValue, false);
  };

  return (
    <PagePaper className="mailbox mailbox_secondary">
      <Header />
      <div className="mailbox__content">
        <Form loading={formLoading} onSubmit={createHandler} className="mailbox__form">
          <div className="mailbox__title">
            <h1 className="title-text h1">
              <Text>Your Temporary Email Address is</Text>
              <strong>...</strong>
            </h1>
          </div>
          {!isFetched ? (
            <Loader />
          ) : (
            <>
              <Input
                readOnly={!isRegistered}
                value={value}
                values={values}
                domain={getData.current.hostname}
                isDisturbed={disturbed}
                onChange={changeHandler}
                onRefresh={refreshHandler}
                onSelect={setCurrent}
                onSubmit={createHandler}
                valid={validValue && errorMessage === ''}
                errorMessage={errorMessage}
                enteredValue={enteredValue}
                setEnteredValue={setEnteredValue}
                cancelHandler={cancelHandler}
                setOriginalValue={setOriginalValue}
                originalValue={originalValue}
              />
              <Toolbar
                isDisturbed={disturbed}
                value={concatMailbox(value, getData.current.hostname)}
                onRefresh={refreshHandler}
                onSubmit={createHandler}
                onCancel={cancelHandler}
              />
              {displayCreateError && (
                <ErrorMessage>Mailbox with given name already exists.</ErrorMessage>
              )}
              <div className="mailbox-field__action">
                {disturbed ? (
                  <Button 
                  className={classnames("btn btn_big btn_with-icon notification-badge",
                    { "blob": blob })} disabled>
                    <Text className="btn__text">Open inbox</Text>
                    <InboxIcon className="btn__ico" />
                    {current && current.hasOwnProperty('name') && 
                    current.hasOwnProperty('mailbox') &&
                      <Badge name={current['name']}
                        mailbox={current['mailbox']}
                        notification={list} />}
                  </Button>
                ) : (
                  <Link
                    className={classnames("btn btn_big btn_with-icon notification-badge",
                      {"blob": blob })}
                    to={`${routeConfig.mailbox.path}/${value}`}
                  >
                    <Text className="btn__text">Open inbox</Text>
                    <InboxIcon className="btn__ico" />
                    {current && current.hasOwnProperty('name') && current.hasOwnProperty('mailbox') &&
                      <Badge name={current['name']} mailbox={current['mailbox']} notification={list} />}
                  </Link>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
    </PagePaper>
  );
};

export default MailboxForm;
