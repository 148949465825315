import React from 'react';
import classnames from 'classnames';

import MailboxService from '../../../../api/services/MailboxService';

import Mailbox from './Mailbox/Mailbox';
import { Loader } from '../../../utils';
import { Text } from '../../../parts/Document/Document';

const Mailboxes = (props) => {
  const { data, isError, isFetched, isLoading, refetch } = MailboxService.useGetAll();

  const deleteHandler = () => {
    refetch();
  };

  const getMailboxes = (list = []) => {
    if (list.length === 0) return <Text>Empty</Text>;
    let foundActive = false;

    return list.map((mailbox) => {
      let active = false;

      if (!foundActive && !mailbox.remote) {
        active = true;
        foundActive = true;
      }

      return (
        <Mailbox
          key={mailbox.name + mailbox.domain_name}
          data={mailbox}
          onDeleted={deleteHandler}
          active={active}
        />
      );
    });
  };

  if (!isFetched) return <Loader />;
  if (isError) return <Text>Error occurred</Text>;
  return (
    <div className={classnames('mailboxes__list', { ['loading']: isLoading })}>
      {getMailboxes(data)}
    </div>
  );
};

export default Mailboxes;
