import debounce from 'lodash/debounce';

const calculateVh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const resizeDebouncedEvent = debounce(calculateVh, 150, { leading: false, trailing: true });
window.addEventListener('resize', resizeDebouncedEvent);
calculateVh();
