import React from 'react';

import { PagePaper, FormWrapper } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import { Container, Feedback, P, Title } from '../../parts/Document/Document';
import withScrollToTop from '../../../hoc/withScrollToTop';
import { Link } from 'react-router-dom';
import routeConfig from '../../../config/routes.config';

const PageNotFound = (props) => {
  return (
    <PagePaper className="main">
      <PageToolbar />
      <FormWrapper>
        <Title popUpTitle className="title-text">
          Page Not Found
        </Title>
        <P>
          <Link to={routeConfig.home.path} className="breadcrumbs__link">
            Back to Main page
          </Link>
        </P>
      </FormWrapper>
    </PagePaper>
  );
};

export default withScrollToTop(PageNotFound);
