import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import concatMailbox from '../../../../../utils/concatMailbox';

import Field from './Field/Field';
import Icons from './Icons/Icons';
import Selector from './Selector/Selector';

const Input = (props) => {
  const {
    readOnly = false,
    value = '',
    values = [],
    domain = '',
    isDisturbed = false,
    onChange = () => {},
    onRefresh = () => {},
    onSelect = () => {},
    onSubmit = () => {},
    valid = true,
    errorMessage = '',
    originalValue= '',
    cancelHandler = () => {},
    enteredValue = '',
    setEnteredValue = () => {},
    setOriginalValue = () => {}
  } = props;

  const iconRef = useRef();
  const [focused, setFocused] = useState(false);

  const [selector, setSelector] = useState(false);
  
  // const [enteredValue, setEnteredValue] = useState('');
  const [warning, setWarning] = useState(false);

  const changeHandler = (value) => {
    setEnteredValue(value);
    onChange(value, true);
  };

  // const cancelHandler = () => {
  //   setEnteredValue('');
  //   setErrorM('');
  //   onChange(originalValue, false);
  // };

  const selectHandler = (...args) => {
    setSelector(false);
    onSelect(...args);
  };

  const focusHandler = (event) => {
    if (readOnly) return;
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setFocused(true);
    }
  };

  useEffect(() => {
    const tt = setTimeout(() => setWarning(false), 0);
    return () => clearTimeout(tt);
  }, [warning]);

  useEffect(() => {
    if (!isDisturbed) setOriginalValue(value);
  }, [value, isDisturbed]);

  const resolvedValue = isDisturbed ? enteredValue : originalValue;

  
  return (
    <OutsideClickHandler onOutsideClick={() => setSelector(false)} disabled={!selector}>
      <div className="mailbox__field-wrapper" style={{textAlign:"left"}}>
        <div
          className={classnames(
            'mailbox__field mailbox-field',
            { ['error']: valid === false },
            { ['warning']: warning },
            { ['selector']: selector }
          )}
          onClick={focusHandler}
          style={errorMessage !== '' ? {borderColor: 'red'}: {}}
        >
          <Field
            value={resolvedValue}
            domain={domain}
            readOnly={readOnly}
            isDisturbed={isDisturbed}
            onChange={changeHandler}
            onError={() => setWarning(true)}
            focused={focused}
            setFocused={setFocused}
          />
          <Icons
            fullValue={concatMailbox(originalValue, domain)}
            isDisturbed={isDisturbed}
            withSelect={values.length > 1}
            open={selector}
            onRefresh={onRefresh}
            onOpen={() => setSelector(true)}
            onClose={() => setSelector(false)}
            onCancel={cancelHandler}
            onSubmit={onSubmit}
            iconRef={iconRef}
          />
        </div>
        {errorMessage !== '' && <div className="error-message">
                {errorMessage}</div>}
        {selector && (
          <Selector
            values={values}
            value={value}
            onClose={() => setSelector(false)}
            onSelect={selectHandler}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Input;
