import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import copyToClipboard from 'copy-to-clipboard';

import { Input } from '../../../../../utils';
import concatMailbox from '../../../../../../utils/concatMailbox';
import { Tooltip } from '../../../../../utils';

const Field = (props) => {
  const {
    value,
    readOnly,
    isDisturbed,
    domain,
    onChange = () => { },
    onError = () => { },
    focused = false,
    setFocused = () => { },
  } = props;

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [focused]);

  const focusHandler = () => {
    if (!readOnly) setFocused(true);
  };

  const blurHandler = () => {
    if (!isDisturbed) setFocused(false);
  };

  useEffect(() => {
    if (!isDisturbed || readOnly) setFocused(false);
  }, [isDisturbed, readOnly]);

  const copyHandler = () => {
    copyToClipboard(`${value}@${domain}`);
    setTooltip(true);
  };

  const [copyElement, setCopyElement] = useState(null);
  const [tooltip, setTooltip] = useState(false);

  const getFiled = () => {
    if (focused) {
      return (
        <Input
          className="mailbox-field__input"
          name="mailbox"
          domain={domain}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          onError={onError}
          validator={(val) => val.length <= 50}
          forwardRef={inputRef}
          onBlur={blurHandler}
          spellCheck="false"
        />
      );
    } else {
      return (
        <div
          className={classnames('mailbox-field__value', { ['read-only']: readOnly })}
          onClick={focusHandler}
          ref={setCopyElement}
        >
          {value}
        </div>
      );
    }
  };

  return (
    <>
      <div className="mailbox-field__input-wrapper"
      style={readOnly ? {cursor: "pointer"}:{}} onClick={readOnly ? copyHandler : undefined}>
        {getFiled()}
        <div className={classnames('mailbox-field__domain', { ['solid']: focused })}>
          {concatMailbox('', domain)}
        </div>
      </div>
      <Tooltip
        innerRef={copyElement}
        message="Copied to clipboard"
        timeout={1000}
        enabled={tooltip}
        onClose={() => setTooltip(false)}
      />
    </>
  );
};

export default Field;
