import Connector from './Connector';
import AuthConnector from './AuthConnector';
import MailboxConnector from './MailboxConnector';
import MailConnector from './MailConnector';
import HostConnector from './HostConnector';
import pathConfig from '../../config/paths.config';

const path = process.env.REACT_APP_NODE_HOST
  ? new URL(process.env.REACT_APP_BASE_URL_PATH, process.env.REACT_APP_NODE_HOST).href
  : process.env.REACT_APP_BASE_URL_PATH;

export const authConnector = new AuthConnector(path, pathConfig.user);
export const mailboxConnector = new MailboxConnector(path, pathConfig.mailbox);
export const mailConnector = new MailConnector(path, pathConfig.mail);
export const hostConnector = new HostConnector(path, pathConfig.host);

export default Connector;
