export const ACTIONS = {
  ADD: 'NOTIFICATION::ADD',
  REMOVE: 'NOTIFICATION::REMOVE',
  CLEAR: 'NOTIFICATION::CLEAR',
};

export const NOTIFICATIONS_LIMIT = 5;

/** @typedef {import('../../models/Notification').default} Notification */
/**
 * @typedef {Object} NotificationStoreContents
 * @property {Notification[]} list
 */

/** @type {NotificationStoreContents} */
const initialState = {
  list: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD:
      return {
        ...state,
        list: [...state.list.splice(0, NOTIFICATIONS_LIMIT - 1), action.notification],
      };
    case ACTIONS.REMOVE:
      return {
        ...state,
        list: [...state.list.filter((el) => el.id !== action.id)],
      };
    case ACTIONS.CLEAR:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
};

export default notificationReducer;
