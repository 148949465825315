import React, { useContext, useEffect } from 'react';

import { Feedback, Title, P, Text, Link, Space } from '../../../parts/Document/Document';
import PopupStyleContext from '../../../../context/PopupStyleContext';
import PopupContext from '../../../../context/PopupContext';
import routeConfig from '../../../../config/routes.config';
import withPreventDefault from '../../../../utils/withPreventDefault';

const Error = (props) => {
  const { onSuccess } = props;
  const { setVariant } = useContext(PopupStyleContext);
  const { openRecoverPassword } = useContext(PopupContext);

  useEffect(() => {
    setVariant('error');
    return () => setVariant('default');
  }, []);

  return (
    <Feedback>
      <Title popUpTitle className="title-text">Token seems to be expired</Title>
      <P>
        <Link to={routeConfig.home.path} onClick={onSuccess}>
          Go to home page
        </Link>
        <Space />
        <Text>or</Text>
        <Space />
        <Link
          to={routeConfig.recoverPassword.path}
          onClick={withPreventDefault(openRecoverPassword)}
        >
          send mail one more time.
        </Link>
      </P>
    </Feedback>
  );
};

export default Error;
