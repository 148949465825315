import React from 'react';

import HostLabel from '../../../../parts/HostLabel/HostLabel';
import { ReactComponent as Logo } from '../../../../../assets/svg/logo.svg';

const Header = (props) => {
  return (
    <div className="mailbox__header">
      <Logo className="mailbox__logo" />
      <HostLabel className="mailbox__host" />
    </div>
  );
};

export default Header;
