import React from 'react';

const withPopup = (WrappedComponent) => {
  const WithPopup = (props) => {
    if (!props.open) return null;
    return <WrappedComponent {...props} />;
  };

  return WithPopup;
};

export default withPopup;
