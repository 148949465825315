import React, { useContext, useEffect } from 'react';

import { Feedback, Title, Text, P, Link, Space } from '../../../parts/Document/Document';
import PopupStyleContext from '../../../../context/PopupStyleContext';
import PopupContext from '../../../../context/PopupContext';
import routeConfig from '../../../../config/routes.config';
import withPreventDefault from '../../../../utils/withPreventDefault';

const Success = (props) => {
  const { onSuccess } = props;
  const { setVariant } = useContext(PopupStyleContext);
  const { openAuthorization } = useContext(PopupContext);

  useEffect(() => {
    setVariant('success');
    return () => setVariant('default');
  }, []);

  return (
    <Feedback>
      <Title popUpTitle className="title-text">
        Registration successfully completed
      </Title>
      <P>
        <Text>You are successfully complete registration.</Text>
        <Space />
        <Link to={routeConfig.login.path} onClick={withPreventDefault(openAuthorization)}>
          Please login.
        </Link>
      </P>
    </Feedback>
  );
};

export default Success;
