import React, { useContext, useState } from 'react';
import axios from 'axios';
import Validator from '../../../../libs/Validator';
import AuthorizationService from '../../../../api/services/AuthorizationService';
import PopupContext from '../../../../context/PopupContext';
import withPreventDefault from '../../../../utils/withPreventDefault';

import Form, {
  InputField,
  FieldGroup,
  Submit,
  ErrorMessage,
  Message,
} from '../../../parts/Form/Form';
import { Text, Link, P, Space, Title } from '../../../parts/Document/Document';
import routeConfig from '../../../../config/routes.config';

const validator = new Validator({
  email: Validator.Presets.email,
});

const FormComponent = (props) => {
  const { onSuccess = () => { } } = props;

  const [email, setEmail] = useState('');
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const [verifiedValid, setVerifiedValid] = useState(null);
  const [verifyEmail, { isLoading, isError, error }] = AuthorizationService.useVerifyEmail({
    onSuccess,
  });

  const { openResendEmailConfirmation, openAuthorization } = useContext(PopupContext);

  const validate = () => {
    const { error } = validator.validate({ email });
    if (error) {
      setEmailValid(error.email);
      return false;
    } else {
      setEmailValid(null);
    }
    if (email !== verifiedEmail) {
      setVerifiedValid("Plese verify your email address.");
      return false;
    } else {
      setVerifiedValid(null);
      return true;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      try {
        const response = await axios.post("https://mail1.io/api/email/validate", { "email": email });
        if (response.data.hasOwnProperty('retcode') && response.data['retcode'] === 200) {
          verifyEmail({ email });
        } else if (response.data.hasOwnProperty('retcode') && response.data['retcode'] === -20) {
          setEmailValid(response.data.message)
        } else {
          setEmailValid("There was a problem with your request. Please try again later.")
        }
      } catch (error) {
        setEmailValid("There was a problem with your request. Please try again later.")
      }
    }
  };

  return (
    <>
      <Title popUpTitle className="title-text">
        Registration
      </Title>

      <div className="popup__content">
        <Form onSubmit={submitHandler} loading={isLoading}>
          <FieldGroup>
            <InputField
              label="Your E-mail:"
              placeholder="name@domain.com"
              name="email"
              value={email}
              onChange={setEmail}
              valid={!emailValid}
              errorMessage={emailValid}
            />
          </FieldGroup>


          {/* Verified email */}
          <FieldGroup>
            <InputField
              label="Verify your E-mail:"
              placeholder="name@domain.com"
              name="email"
              value={verifiedEmail}
              onChange={setVerifiedEmail}
              valid={!verifiedValid}
              errorMessage={verifiedValid}
            />
          </FieldGroup>

          {isError && <ErrorMessage>{error.message}</ErrorMessage>}
          {/* {verifiedValid && <ErrorMessage>{verifiedValid}</ErrorMessage>} */}
          <div className="form-actions">
            <div className="form-actions__item">
              <Submit wide>Sign up</Submit>
            </div>
          </div>
          <Message>
            <P>
              <Text>Already registered?</Text>
              <Space />
              <Link to={routeConfig.login.path} onClick={withPreventDefault(openAuthorization)}>
                Login!
              </Link>
            </P>
            <P>
              <Link
                to={routeConfig.resendConfirmation.path}
                onClick={withPreventDefault(openResendEmailConfirmation)}
                className="tiny-text"
              >
                Did not receive confirmation mail?
              </Link>
            </P>
          </Message>
        </Form>
      </div>
    </>
  );
};

export default FormComponent;
