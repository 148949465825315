import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import AuthorizationService from '../../api/services/AuthorizationService';
import { rootActions } from '../../api/storage';

import Home, { config as homeConfig } from '../pages/Home/Home';
import Mailbox, { config as mailboxConfig } from '../pages/Mailbox/Mailbox';
import Mail, { config as mailConfig } from '../pages/Mail/Mail';
import Registration, { config as registrationConfig } from '../pages/Registration/Registration';
import EmailConfirmation, {
  config as emailConfirmConfig,
} from '../pages/EmailConfirmation/EmailConfirmation';
import ResendConfirmation, {
  config as resendConfirmConfig,
} from '../pages/ResendConfirmation/ResendConfirmation';
import RecoverPassword, {
  config as recoverPassConfig,
} from '../pages/RecoverPassword/RecoverPassword';
import CompletePasswordRecovery, {
  config as completePassRecoveryConfig,
} from '../pages/CompletePasswordRecovery/CompletePasswordRecovery';
import Authorization, { config as authorizationConfig } from '../pages/Authorization/Authorization';
import Account, { config as accountConfig } from '../pages/Account/Account';
import IdentityTransfer, {
  config as identityTransferConfig,
} from '../pages/IdentityTransfer/IdentityTransfer';
import AuthorizationTransfer, {
  config as authTransferConfig,
} from '../pages/AuthorizationTransfer/AuthorizationTransfer';
import TermsConditions, { config as termsConfig } from '../pages/TermsConditions/TermsConditions';
import PrivacyPolicy, { config as ppConfig } from '../pages/PrivacyPolicy/PrivacyPolicy';
import ContactUs, { config as contactUsConfig } from '../pages/ContactUs/ContactUs';
import PageNotFound from '../pages/PageNotFound/PageNotFound';

import RedirectionPopup from '../popups/RedirectionPopup/RedirectionPopup';
import Page from '../parts/Page/Page';
import Notifications from '../Notifications/Notifications';
import PageSkeleton from '../parts/PageSkeleton/PageSkeleton';
import ErrorPage from '../parts/ErrorPage/ErrorPage';
import PopupController from '../parts/PopupController/PopupController';
import { ErrorBoundary } from '../utils';
import withBrowserRouter from '../../hoc/withBrowserRouter';
import Faq, { config as faqConfig } from '../pages/Faq/Faq';

const App = (props) => {
  AuthorizationService.useEstablishSession();
  const { connectionState } = useSelector((state) => state.app);
  const { established } = useSelector((state) => state.auth);
  const { fatalError: isError } = useSelector(state => state.app);
  const { fatalError } = rootActions.appActions.useAppActions();

  useEffect(() => {
    if (process.env.REACT_APP_ADSENSE_ID !== undefined) {
      try {
        const script = document.createElement('script');
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.REACT_APP_ADSENSE_ID}`;
        script.async = true;
        document.body.appendChild(script);
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  if (isError || (established === false && connectionState)) return <ErrorPage fixed />;

  return (
    <>
      <ErrorBoundary onError={fatalError}>
        <Switch>
          <PopupController>
            <RedirectionPopup open={!connectionState} />
            {established && (
              <>
                <Page>
                  <Switch>
                    <Route path={homeConfig.path} exact component={Home} />
                    <Route path={mailboxConfig.path} component={Mailbox} />
                    <Route path={mailConfig.path} component={Mail} />
                    <Route path={accountConfig.path} component={Account} />
                    <Route path={authorizationConfig.path} component={Authorization} />
                    <Route path={registrationConfig.path} component={Registration} />
                    <Route path={resendConfirmConfig.path} component={ResendConfirmation} />
                    <Route path={recoverPassConfig.path} component={RecoverPassword} />
                    <Route path={emailConfirmConfig.path} component={EmailConfirmation} />
                    <Route
                      path={completePassRecoveryConfig.path}
                      component={CompletePasswordRecovery}
                    />
                    <Route path={identityTransferConfig.path} component={IdentityTransfer} />
                    <Route path={authTransferConfig.path} component={AuthorizationTransfer} />
                    <Route path={termsConfig.path} component={TermsConditions} />
                    <Route path={ppConfig.path} component={PrivacyPolicy} />
                    <Route path={contactUsConfig.path} component={ContactUs} />
                    <Route path={faqConfig.path} component={Faq} />
                    <Route component={PageNotFound} />
                  </Switch>
                </Page>
                <Notifications />
              </>
            )}
          </PopupController>
        </Switch>
      </ErrorBoundary>

      <PageSkeleton display={established === null} />
    </>
  );
};

export default withBrowserRouter(App);
