import React from 'react';

import withScrollToTop from '../../../hoc/withScrollToTop';

import { PagePaper, Advertisement } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import { Container, Title } from '../../parts/Document/Document';

import routeConfig from '../../../config/routes.config';
import emailsConfig from '../../../config/emails.config';

export const config = { path: routeConfig.terms.path };

const TermsConditions = (props) => {
  return (
    <PagePaper variant="adaptive" className="main">
      <PageToolbar backLink={routeConfig.home.path} backLinkText="Back to Main page" />

      <div className="main__body main__body_secondary">
        <Container class="main__title">
          <Title className="title-text">Terms & Conditions</Title>
        </Container>
        <div className="main__content">
          <div className="main__section main__section_terms">
            <div className="row">
              <div className="col col_9 col_tab-12">
                <div className="main__sub-section main__sub-section_secondary wysiwyg">
                  <h2>Ownership of Copyrights in Content</h2>
                  <p>
                    All of the text, data, images, graphics, audio, video and audio-video clips,
                    software applications, and all of the other information, materials and tools
                    made available to you through this Site (collectively, the “Content”) is
                    copyrighted under Bulgarian laws and applicable international copyright laws and
                    treaty provisions. Unauthorized use of the Site’s content may violate such laws
                    and these Terms of Service.
                  </p>
                  <p>
                    Any rights not expressly granted by these Terms of Service or the end user
                    license agreement are reserved by SetupVPN and its licensors.
                  </p>
                  <br></br>
                  <h2>Software Use Restrictions</h2>
                  <p>
                    Any software that is made available to download from the Website (the
                    “Software”) is the copyrighted work of SetupVPN and/or Third Party Providers.
                    Use of the Software is governed by the terms of the end user license agreement
                    (the “EULA”). Except as set forth in the EULA, any further copying, reproduction
                    or redistribution of the Software is expressly prohibited.
                  </p>
                  <p>
                    Limited product support and maintenance, if any, of the Software is available
                    from SetupVPN and/or the Third Party Providers, as the case may be.
                  </p>
                  <p>
                    <strong>Contact Information:</strong> MAIL1.IO LLC, 815 Ponce De Leon Blvd P209,
                    33134 Coral Gables, Florida, USA. Email:{' '}
                    <a href={`mailto:${emailsConfig.support}`}>{emailsConfig.support}</a>
                  </p>
                  <br></br>
                  <p>
                    Welcome to the Web site (the “Site”) of SetupVPN (referred to as “SetupVPN,”
                    “we,” “us,” or “our” herein). Via the Site, SetupVPN makes a number of resources
                    available to you, including information, software, products, downloads,
                    documents, communications, text, graphics, content, tools, and services such as
                    technical support.
                  </p>
                  <p>
                    PLEASE READ THE TERMS OF SERVICE CAREFULLY BEFORE USING THE SITE. By accessing
                    and using the Site in any way, including browsing the Site, using any
                    information, content, or services, downloading any software or files, and
                    placing orders for services, you agree to abide by the Terms of Service
                    described on this page.
                  </p>
                  <p>
                    IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS SET FORTH IN THE TERMS OF
                    SERVICE, PLEASE DO NOT USE THE SITE IN ANY WAY. The Terms of Service are a
                    binding agreement between SetupVPN and you.
                  </p>
                  <p>
                    SetupVPN is continually improving and adding new functionality and features to
                    this Site. These improvements (or changes in the law) may require changes to
                    these Terms of Service. Accordingly, we reserve the right to update or modify
                    these Terms of Service at any time without prior notice. We will do so by
                    posting an updated or modified version of these Terms of Service on this Site.
                    Your use of this Site following any such change constitutes your agreement to
                    comply with and be bound by these Terms of Service. For this reason, we
                    encourage you to review these Terms of Service regularly. You may review these
                    Terms of Service at any time by clicking on the button marked “Terms of Service”
                    at the bottom of each page of this Site.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main__footer">
        <Advertisement />
      </div>
    </PagePaper>
  );
};

export default withScrollToTop(TermsConditions);
