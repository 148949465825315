import React from 'react';

import Animation, { AnimatePresence } from '../../parts/Animation/Animation';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';

const PageSkeleton = (props) => {
  const { display } = props;

  return (
    <AnimatePresence open={display}>
      <Animation initial="visible" duration={0.2}>
        <div className="page-preloader">
          <Logo />
        </div>
      </Animation>
    </AnimatePresence>
  );
};

export default PageSkeleton;
