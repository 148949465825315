import React, { useState } from 'react';
import { Text } from '../../../../../parts/Document/Document';
import copyToClipboard from 'copy-to-clipboard';
import { Tooltip } from '../../../../../utils';

const HeaderLabel = (props) => {

  const { name, value } = props;
  const [copyElement, setCopyElement] = useState(null);
  const [tooltip, setTooltip] = useState(false);

  const copyHandler = () => {
    copyToClipboard(value);
    setTooltip(true);
  };

  return (
    <div className="info__item info-item">
      <Text className="info-item__title">{name}</Text>
      <div onClick={name === "To" ? copyHandler : undefined}
        ref={setCopyElement}>
        <Text className="info-item__value" withoutTranslation>
          {value}
        </Text>
      </div>
      <Tooltip
        innerRef={copyElement}
        message="Copied to clipboard"
        timeout={1000}
        enabled={tooltip}
        onClose={() => setTooltip(false)}
      />
    </div>
  );
};

export default HeaderLabel;
