import { useEffect, useState } from 'react';

export default (breakPoints) => {
  const [value, setValue] = useState();

  useEffect(() => {
    let current;

    const handler = () => {
      const width = window.innerWidth ?? document.documentElement.clientWidth;

      for (let n in breakPoints) {
        const val = Number(n);

        if (typeof val === 'number' && val >= width) {
          if (val !== current) {
            setValue(breakPoints[n]);
            current = val;
          }

          return;
        }
      }

      if (breakPoints['default'] !== undefined && current !== 'default') {
        setValue(breakPoints['default']);
        current = 'default';
        return;
      }
    };

    handler();
    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [breakPoints]);

  return value;
};
