import React, { useContext, useEffect } from 'react';

import { Feedback, Title, Text, P, Link } from '../../../parts/Document/Document';
import PopupStyleContext from '../../../../context/PopupStyleContext';
import routeConfig from '../../../../config/routes.config';

const Response = (props) => {
  const { onSuccess } = props;
  const { setVariant } = useContext(PopupStyleContext);

  useEffect(() => {
    setVariant('success');
    return () => setVariant('default');
  }, []);

  return (
    <Feedback>
      <Title popUpTitle className="title-text">
        Submission succeeded!
      </Title>
      <P>
        <Text>You will receive an email with further instruction shortly.</Text>
      </P>
      <P className="feedback__action-bar">
        <Link className="btn" to={routeConfig.home.path} onClick={onSuccess}>
          Go to main page.
        </Link>
      </P>
    </Feedback>
  );
};

export default Response;
