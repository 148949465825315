export const ACTIONS = {
  SET_SESSION: 'AUTH::SET_SESSION',
  BREAK_SESSION: 'AUTH::BREAK_SESSION',
  SET_USER: 'AUTH::SET_USER',
  REMOVE_USER: 'AUTH::REMOVE_USER',
};

/**
 * @typedef {Object} AuthStoreContents
 * @property {boolean|null} session
 * @property {string|null} uid
 * @property {string|null} userName
 * @property {boolean} isRegistered
 * @property {boolean|null} established
 */

/** @type {AuthStoreContents} */
const initialState = {
  session: null,
  uid: null,
  userName: null,
  isRegistered: false,
  established: null,
};

const setEstablished = (session, uid) => {
  if (session === null || uid === null) return null;
  return session && !!uid;
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_SESSION:
      return {
        ...state,
        session: action.established,
        established: setEstablished(action.established, state.uid),
      };
    case ACTIONS.BREAK_SESSION:
      return {
        ...state,
        session: null,
        uid: null,
        userName: null,
        isRegistered: false,
        established: null,
      };
    case ACTIONS.SET_USER:
      return {
        ...state,
        uid: action.uid,
        userName: action.userName,
        isRegistered: action.isRegistered,
        established: setEstablished(state.session, action.uid),
      };
    case ACTIONS.REMOVE_USER:
      return {
        ...state,
        uid: null,
        userName: null,
        isRegistered: false,
        established: null,
      };
    default:
      return state;
  }
};

export default authReducer;
