import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import MailService from '../../../api/services/MailService';
import SocketService from '../../../api/services/SocketService';
import withAccountId from '../../../hoc/withAccountId';
import withScrollToTop from '../../../hoc/withScrollToTop';

import Header from './Header/Header';
import MailTable from './MailTable/MailTable';
import Pagination from './Pagination/Pagination';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import { Loader, PagePaper, Advertisement } from '../../utils';
import { Placeholder } from '../../parts/Document/Document';

import routeConfig from '../../../config/routes.config';
import './mailbox.scss';

export const config = { path: `${routeConfig.mailbox.path}/:name` };

const Mailbox = (props) => {
  const { name: mailboxName } = useParams();
  const [querySize] = useState(20);
  const [page, setPage] = useState(1);

  const { data, isLoading, isSuccess, isFetching, refetch, ...rest } = MailService.useGetPreviews(
    mailboxName,
    querySize,
    page - 1
  );

  const deleteMail = MailService.useDeleteMail();

  const pageMaxLength = data ? Math.max((data.length / querySize) | 0, 1) : 1;

  const deleteHandler = async (id) => {
    const result = await deleteMail(id);
    if (result) refetch();
  };

  useEffect(() => {
    const reload = () => {
      if (page === 1) refetch();
    };

    SocketService.subscribe.onNewMail(reload);
    return () => {
      SocketService.unsubscribe.offNewMail(reload);
    };
  }, [refetch]);

  return (
    <PagePaper>
      <PageToolbar />
      {isLoading ? (
        <Loader className="page-loader" />
      ) : (
        isSuccess && (
          <div className="main__body">
            <Header mailbox={data.mailbox.mailbox} onRefresh={refetch} />
            <div className="main__content">
              <div className="main__section main__section_mailbox">
                <div className="main__sub-section">
                  {data.length !== 0 ? (
                    <>
                      <MailTable loading={isFetching}
                      rows={data.list} onDelete={deleteHandler} />
                      {pageMaxLength > 1 && (
                        <Pagination
                          visibleRange={2}
                          currentPage={page}
                          maxPages={pageMaxLength}
                          onChange={setPage}
                        />
                      )}
                    </>
                  ) : (
                    <Placeholder message="Your Inbox is empty" className="mailbox__placeholder" />
                  )}
                </div>
                {isFetching && <Loader variant="absolute" />}
              </div>
            </div>
          </div>
        )
      )}
      <div className="main__footer">
        <Advertisement />
      </div>
    </PagePaper>
  );
};

export default withScrollToTop(withAccountId(Mailbox));
