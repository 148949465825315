import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

const Text = (props) => {
  const { className, children, withoutTranslation, ...rest } = props;
  const { t } = useTranslation();

  const getBody = () => {
    if (withoutTranslation) return children;
    return <Trans t={t}>{children}</Trans>;
  };

  return (
    <span className={className} {...rest}>
      {getBody()}
    </span>
  );
};

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  withoutTranslation: PropTypes.bool,
};

export default Text;
