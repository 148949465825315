import React, { useState } from 'react';

import Form from './Form/Form';
import Response from './Response/Response';

const ResendEmailConfirmation = (props) => {
  const { onSuccess = () => {} } = props;
  const [succeed, setSucceed] = useState(null);

  if (succeed) return <Response onSuccess={onSuccess} />;
  return <Form onSuccess={() => setSucceed(true)} />;
};

export default ResendEmailConfirmation;
