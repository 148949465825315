import React from 'react';
import classnames from 'classnames';

import InputField from './InputField';
import ViewField from './ViewField';
import { Button, Loader } from '../../utils';
import { Text } from '../../parts/Document/Document';

import './form.scss';

const Form = (props) => {
  const { children, onSubmit = () => {}, loading = false, className, ...rest } = props;

  const submitHandler = (e) => {
    e.preventDefault();
    if (!loading) return onSubmit(e);
  };

  return (
    <form className={className} onSubmit={submitHandler} {...rest}>
      {children}
      {loading && <Loader className={'loader'} variant="absolute" />}
    </form>
  );
};

const FieldGroup = (props) => {
  const { children, className } = props;
  return <div className={classnames('form-fields', className)}>{children}</div>;
};

const Toolbar = (props) => {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
};

const Submit = (props) => {
  const { children, className, wide = false, ...rest } = props;
  return (
    <Button
      className={classnames('btn', { ['btn_wide']: wide }, className)}
      type="submit"
      {...rest}
    >
      {children}
    </Button>
  );
};

const Message = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={classnames('form-field__message', className)} {...rest}>
      <Text>{children}</Text>
    </div>
  );
};

const ErrorMessage = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={classnames('mailbox__error-message', className)} {...rest}>
      <Text>{children}</Text>
    </div>
  );
};

export default Form;
export { FieldGroup, Toolbar, InputField, ViewField, Submit, Message, ErrorMessage };
