import React, { useState } from 'react';

import IconGroup from '../IconGroup/IconGroup';
import HostPopup from '../../../../popups/HostPopup/HostPopup';
import { Button } from '../../../../utils';
import { Text } from '../../../../parts/Document/Document';

import { ReactComponent as HostIcon } from '../../../../../assets/svg/hosts.svg';

const Toolbar = (props) => {
  const { isDisturbed, value, onRefresh, onCancel, onSubmit } = props;
  const [hostPopup, setHostPopup] = useState(false);

  return (
    <>
      <div className="mailbox__toolbar toolbar">
        <Button className="btn btn_with-icon btn_link" onClick={() => setHostPopup(true)}>
          <HostIcon className="btn__ico" />
          <Text className="btn__text">Change Hostname</Text>
        </Button>
        <IconGroup value={value}
          className=""
          onRefresh={onRefresh}
          isDisturbed={isDisturbed}
          onCancel={onCancel}
          onSubmit={onSubmit} />
      </div>

      <HostPopup open={hostPopup} onClose={() => setHostPopup(false)} />
    </>
  );
};

export default Toolbar;
