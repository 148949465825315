import React, { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';

import TranslationService from '../../../../api/services/TranslationService';
import ButtonGroup from './ButtonGroup/ButtonGroup';

import './header.scss';

const config = {
  loop: false,
  typeSpeed: 30,
};

const Header = (props) => {
  const typeRef = useRef(null);
  const [typed, setTyped] = useState(null);
  const { t } = TranslationService.useTranslation();

  useEffect(() => {
    if (!typeRef.current) return;

    const typed = new Typed(typeRef.current, {
      strings: [t('A free temporary, secure, anonymous, free, disposable email address')],
      ...config,
    });
    setTyped(typed);
    return () => typed.destroy();
  }, [typeRef, t]);

  const refreshTyped = () => {
    if (typed) typed.reset();
  };

  return (
    <header className="site-header">
      <div className="container">
        <div className="site-header__content">
          <div className="site-header__slogan-wrapper slogan">
            <div className="site-header__slogan slogan">
              <span className="slogan__text" ref={typeRef} onClick={refreshTyped}></span>
            </div>
          </div>
          <div className="site-header__action">
            <ButtonGroup />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
