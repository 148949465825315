import React from 'react';

import withPopup from '../../../hoc/withPopup';
import { Popup, Button } from '../../utils';
import { Title, Text, Toolbar, P } from '../../parts/Document/Document';

const ConfirmationPopup = (props) => {
  const { open, title, message, onClose = () => {} } = props;

  return (
    <Popup open={!!open} onClose={onClose.bind(null, false)}>
      <div className="popup__confirm">
        <div className="popup__title">
          <Title className="title-text">{title}</Title>
        </div>
        <div className="popup__content">
          <div className="popup__wysiwyg wysiwyg">
            {message ? (
              <P variant="grey">
                <Text>{message}</Text>
              </P>
            ) : null}
          </div>
          <Toolbar className="btn-actions">
            <div className="btn-actions__item">
              <Button className="btn  btn_secondary" onClick={() => onClose(true)} variant="submit">
                Submit
              </Button>
            </div>
            <div className="btn-actions__item">
              <Button className="btn" onClick={() => onClose(false)} variant="cancel">
                Cancel
              </Button>
            </div>
          </Toolbar>
        </div>
      </div>
    </Popup>
  );
};

export default withPopup(ConfirmationPopup);
