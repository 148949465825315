import React, { useState } from 'react';
import classnames from 'classnames';

import Row from './Row/Row';
import { Text } from '../../../parts/Document/Document';
import ConfirmationPopup from '../../../popups/ConfirmationPopup/ConfirmationPopup';

import './mail-table.scss';

const MailTable = (props) => {
  const { loading, rows = [], onDelete = () => {} } = props;
  const [deleteAlert, setDeleteAlert] = useState(null);

  const deleteHandler = (answer) => {
    if (answer) onDelete(deleteAlert);
    setDeleteAlert(null);
  };

  const getRows = (list = []) => {
    return list.map((mail) => <Row key={mail.uid} mail={mail} onDelete={setDeleteAlert} />);
  };

  return (
    <>
      <div className="mailbox__table mail-table">
        <table className={classnames('table', { ['loading']: loading })}>
          <thead className="head">
            <tr className="head-row">
              <th className="head-cell cell-from">
                <Text>From</Text>
              </th>
              <th className="head-cell cell-subject">
                <Text>Subject</Text>
              </th>
              <th className="head-cell cell-att"></th>
              <th className="head-cell cell-date">
                <Text>Date</Text>
              </th>
            </tr>
          </thead>
          <tbody className="body">{getRows(rows)}</tbody>
        </table>
      </div>

      <ConfirmationPopup
        open={deleteAlert}
        title="Are you sure?"
        message="Once deleted, mail cannot be restored."
        onClose={deleteHandler}
      />
    </>
  );
};

export default MailTable;
