import { useEffect, useContext, useState, memo } from 'react';
import PropTypes from 'prop-types';

import { variants } from '../../parts/PopupController/Popup/Popup';
import PopupContext from '../../../context/PopupContext';

const Popup = (props) => {
  const { open, children, variant, bodySize, onClose = () => {}, unclosable } = props;
  const [controls, setControls] = useState(null);
  const { useOpen } = useContext(PopupContext);
  const openContext = useOpen();

  useEffect(() => {
    const { close, update } = openContext({
      content: children,
      open,
      variant,
      bodySize,
      onClose,
      unclosable,
    });

    setControls({ close, update });

    return close;
  }, []);

  useEffect(() => {
    if (controls?.close && open === false) controls.close();
  }, [open]);

  useEffect(() => {
    if (controls?.update)
      controls.update({ content: children, variant, bodySize, onClose, unclosable });
  }, [children, variant, bodySize, onClose, unclosable]);

  return null;
};

Popup.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.keys(variants)),
  onClose: PropTypes.func.isRequired,
  unclosable: PropTypes.bool,
};

export default memo(Popup);
