import React from 'react';
import classnames from 'classnames';

import './loader.scss';

const variants = {
  absolute: 'absolute',
};

const Loader = (props) => {
  const { variant, className } = props;

  return (
    <div className={classnames('spinner-wrapper', variants[variant], className)}>
      <div className={'spinner'}></div>
    </div>
  );
};

export default Loader;
