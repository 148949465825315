import React from 'react';

import withAccountId from '../../../hoc/withAccountId';
import withAuthorization from '../../../hoc/withAuthorization';
import withScrollToTop from '../../../hoc/withScrollToTop';
import { PagePaper, FormWrapper } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import RegistrationForm from '../../forms/Registration/Registration';

import routeConfig from '../../../config/routes.config';

export const config = { path: routeConfig.register.path };

const Registration = (props) => {
  return (
    <PagePaper>
      <PageToolbar />
      <FormWrapper>
        <RegistrationForm />
      </FormWrapper>
    </PagePaper>
  );
};

export default withAuthorization(false)(withScrollToTop(withAccountId(Registration)));
