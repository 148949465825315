import i18n from 'i18next';
import { useDispatch } from 'react-redux';
import { ACTIONS } from './reducer';
import Debug from '../../../utils/debug';

const debug = Debug.extend('storage:actions:app');

export const setConnectionState = (connectionState = true) => {
  debug(`Get in setConnectionState action with connection state: %s`, connectionState);

  return async (dispatch) => {
    dispatch({
      type: ACTIONS.SET_CONNECTION_STATE,
      connectionState,
    });

    debug(`setConnectionState dispatched`);
  };
};

export const fatalError = (title = undefined, text = undefined) => {
  debug(`Get in fatalError action`, {title, text});

  return async (dispatch) => {
    dispatch({
      type: ACTIONS.FATAL_ERROR,
      title: title ?? i18n.t('Unknown problem'),
      text: text ?? i18n.t('Please try to reload the page after some time'),
    });

    debug(`fatalError dispatched`);
  };
};

export const useAppActions = () => {
  const dispatch = useDispatch();

  return {
    setConnectionState: (connectionState) => dispatch(setConnectionState(connectionState)),
    fatalError: (title, text) => dispatch(fatalError(title, text)),
  };
};
