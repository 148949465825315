import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthorizationService from '../../../../../api/services/AuthorizationService';
import withPreventDefault from '../../../../../utils/withPreventDefault';
import truncateString from '../../../../../utils/truncateString';

import { Text } from '../../../../parts/Document/Document';
import { Button } from '../../../../utils';
import PopupContext from '../../../../../context/PopupContext';

const ButtonGroup = (props) => {
  const { isRegistered, userName } = useSelector((state) => state.auth);
  const logout = AuthorizationService.useLogout();
  const { openRegistration, openAuthorization } = useContext(PopupContext);
  
  return (
    <div className="site-header__user-actions user-actions">
      <ul className="user-actions__list">
        {isRegistered ? (
          <>
            <li className="user-actions__item">
              <NavLink
                className="btn btn_inner user-actions__link"
                activeClassName="active"
                to="/account"
              >
                {userName ? (
                  <>
                    <svg
                      className="user-actions__ico user-actions__ico_avatar"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1034 8.1201C14.1034 10.1084 12.4921 11.7196 10.5039 11.7196C8.51573 11.7196 6.90441 10.1083 6.90441 8.1201C6.90441 6.1328 8.51573 4.5206 10.5039 4.5206C12.4921 4.5206 14.1034 6.1329 14.1034 8.1201Z"
                        fill="#6F6F6F"
                      />
                      <path
                        d="M10.5039 0.329102C7.85166 0.329102 5.30866 1.3828 3.43266 3.25785C1.55766 5.13383 0.503906 7.67685 0.503906 10.3291C0.503906 12.9814 1.55761 15.5244 3.43266 17.4004C5.30863 19.2754 7.85166 20.3291 10.5039 20.3291C13.1562 20.3291 15.6992 19.2754 17.5752 17.4004C19.4502 15.5244 20.5039 12.9814 20.5039 10.3291C20.501 7.67785 19.4463 5.13585 17.5714 3.2616C15.6974 1.3866 13.1554 0.331852 10.5039 0.329102ZM17.2687 15.8711C16.839 14.3194 15.1105 13.0704 12.7942 12.5059L12.7951 12.5049C11.4787 13.6397 9.52963 13.6397 8.21313 12.5049C5.89576 13.0137 4.16713 14.3047 3.73863 15.8691V15.8701C2.32946 14.1523 1.62731 11.9629 1.77281 9.7471C1.91832 7.5303 2.90171 5.4521 4.52281 3.9336C6.14488 2.41505 8.28256 1.57033 10.5043 1.57033C12.725 1.57033 14.8638 2.41505 16.4848 3.9336C18.1058 5.45215 19.0893 7.53035 19.2348 9.7471C19.3813 11.9629 18.6782 14.1524 17.269 15.8701L17.2687 15.8711Z"
                        fill="#6F6F6F"
                      />
                    </svg>
                    <Text className="user-actions__text">{userName}</Text>
                  </>
                ) : (
                  <Text className="user-actions__text">Account</Text>
                )}
              </NavLink>
            </li>
            <li className="user-actions__item">
              <Button className="btn  btn_inner btn_inner_tiny user-actions__link" onClick={logout}>
                <svg
                  className="ico"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.3377 5.46139V0.396484H0.244629V18.3965H11.3377V13.3626H9.24463V16.3035H2.33765V2.48951H9.24463V5.46139H11.3377Z"
                    fill="#DD0000"
                  />
                  <path
                    d="M12.0947 5.69602L14.7487 8.34997H5.89579V10.443H14.7487L12.0947 13.0969L13.5745 14.5768L18.7548 9.39655L13.5745 4.21631L12.0947 5.69602Z"
                    fill="#DD0000"
                  />
                </svg>
              </Button>
            </li>
          </>
        ) : (
          <>
            <li className="user-actions__item">
              <NavLink
                className="btn btn_secondary user-actions__link"
                activeClassName="active"
                onClick={withPreventDefault(openRegistration)}
                to="/register"
              >
                <Text className="user-actions__text">Register</Text>
              </NavLink>
            </li>
            <li className="user-actions__item">
              <NavLink
                className="btn btn_secondary user-actions__link"
                activeClassName="active"
                onClick={withPreventDefault(openAuthorization)}
                to="/login"
              >
                <Text className="user-actions__text">Login</Text>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default ButtonGroup;
