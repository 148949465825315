import Joi from 'joi';

import TranslationService from '../api/services/TranslationService';
import errorMessages from '../data/validator-messages.json';

class Validator {
  constructor(schema, config = Validator.getDefaultConfig()) {
    this.schema = null;
    this.config = config;
    this.setSchema(schema, config);
  }

  static get Joi() {
    return Joi;
  }

  static getDefaultConfig() {
    return {
      abortEarly: false,
      convert: true,
      presence: 'required',
      messages: errorMessages.errors,
      errors: {
        wrap: {
          label: false,
        },
      },
    };
  }

  static get Presets() {
    return {
      email: Joi.string().email({ tlds: { allow: false } }),
      password: Validator.Joi.string().min(3).max(128).label('Password'),
      repeatedPassword: (refName = 'password') =>
        Validator.Joi.string()
          .valid(Validator.Joi.ref(refName))
          .messages({ 'any.only': errorMessages.errors['password.repeated'] }),
    };
  }

  setConfig(config) {
    const { required = true, ...rest } = config;
    const validatorConfig = {
      presence: required ? 'required' : 'optional',
      ...rest,
    };
    this.config = Object.assign({}, this.config, validatorConfig);
  }

  setSchema(schema, config = Validator.getDefaultConfig()) {
    this.setConfig(config);

    if (schema instanceof Function) {
      const schemaObj = schema(Joi);

      try {
        this.schema = Joi.object(schemaObj);
      } catch (error) {
        this.schema = schemaObj;
      }

      return this;
    }

    if (Array.isArray(schema)) {
      let validationSchema = {};

      schema.forEach((setting) => {
        validationSchema[setting] = Joi.any();
      });
      this.schema = Joi.object(validationSchema);

      return this;
    }

    if (schema instanceof Object) {
      try {
        this.schema = Joi.object(schema);
      } catch (error) {
        this.schema = schema;
      }

      return this;
    }

    return this;
  }

  validate(data) {
    let result = null;
    let error = null;

    if (this.schema) {
      result = this.schema.validate(data, this.config);
    }

    if (result && result.error) {
      error = {};

      for (let ob of result.error.details) {
        ob.context.label = TranslationService.i18n.t(ob.context.label);
        error[ob.context.key] = TranslationService.i18n.t(ob.message, {
          ...ob.context,
          interpolation: {
            prefix: '{{{',
            suffix: '}}}',
          },
        });
      }
    }

    return { value: result.value, error };
  }
}

export default Validator;
