export default {
  i18n: {
    native: {
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      keySeparator: false,
      nsSeparator: '|',
    },
    service: {
      namespace: 'translation',
    },
  },
  languages: {
    ar: {
      shortName: 'ar',
      fullName: 'العربية',
      vocabulary: 'ar.json',
      direction: 'rtl',
    },
    en: {
      shortName: 'en',
      fullName: 'English',
      vocabulary: 'en.json',
    },
    ru: {
      shortName: 'ru',
      fullName: 'Русский',
      vocabulary: 'ru.json',
    },
  },
};
