import React, { useState } from 'react';

import TranslationService from '../../../../../api/services/TranslationService';

import HeaderLabel from './HeaderLabel/HeaderLabel';
import { Text } from '../../../../parts/Document/Document';
import ConfirmationPopup from '../../../../popups/ConfirmationPopup/ConfirmationPopup';
import { Button } from '../../../../utils';

const Header = (props) => {
  const { mail, onDelete = () => {} } = props;
  const moment = TranslationService.useMoment();
  const datetime = moment(mail.timestamp).format('MMM D, YYYY, HH:MM');

  const [deleteAlert, setDeleteAlert] = useState(false);

  const deleteHandler = async (answer) => {
    setDeleteAlert(false);
    if (answer && mail) onDelete();
  };

  return (
    <>
      <div className="main__sub-section main__sub-section_remove-padding main__sub-section_mail-header mail-header">
        <div className="mail-header__content">
          <div className="mail-header__info info">
            <div className="info__list">
              <HeaderLabel name="From" value={mail.from[0].address} />
              <HeaderLabel name="To" value={mail.to[0].address} />
              <HeaderLabel name="Date" value={datetime} />
            </div>
          </div>
          <div className="mail-header__info info">
            <div className="info__list info__list_secondary">
              <HeaderLabel name="Subject" value={mail.subject} />
            </div>
          </div>
        </div>
        <div className="mail-header__action">
          <div>
            <Button
              className="btn btn_with-icon btn_tertiary btn_delete btn_mobile-ico"
              onClick={() => setDeleteAlert(true)}
              adaptive
            >
              <Text className="btn__text">Delete</Text>
              <svg
                className="btn__ico"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.2102 0.280029C5.89183 0.280029 4.80955 1.36928 4.80955 2.68709V3.48524H0.798166C0.355585 3.48712 -0.00186766 3.84707 7.34283e-06 4.28965C0.000633338 4.73036 0.35746 5.08781 0.798166 5.08969H1.83294C1.7453 5.36012 1.70023 5.64808 1.72152 5.94732L2.28993 13.9388C2.38383 15.2516 3.49373 16.28 4.80965 16.28H11.2156C12.5314 16.28 13.6414 15.2509 13.7353 13.9388L14.31 5.94732C14.3313 5.64746 14.2856 5.36012 14.1973 5.08969H15.2271C15.6678 5.08781 16.0246 4.73036 16.0252 4.28965C16.0271 3.84707 15.6696 3.48712 15.2271 3.48524H11.2157V2.68709C11.2157 1.36872 10.1327 0.280029 8.81504 0.280029H7.2102ZM8.81405 1.8826C9.26602 1.8826 9.61846 2.23503 9.61846 2.68701V3.48516H6.4114V2.68701C6.4114 2.23503 6.75758 1.8826 7.20956 1.8826H8.81405Z"
                  fill="#DD0000"
                />
              </svg>
            </Button>
          </div>
        </div>
      </div>

      <ConfirmationPopup
        open={deleteAlert}
        title="Are you sure?"
        message="Once deleted, mail cannot be restored."
        onClose={deleteHandler}
      />
    </>
  );
};

export default Header;
