import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CheckIcon } from '../../../assets/svg/check.svg';

export const types = {
  select: Symbol.for('select'),
  link: Symbol.for('link'),
};

const OpenSelector = (props) => {
  const {
    type = 'select',
    value = '',
    values = [],
    onSelect = () => {},
    onAuxSelect = () => {},
    className,
    disabled = false,
  } = props;

  const getList = (list = []) => {
    return list.map((el) => {
      const isActive = el.value === value;

      if (isActive) {
        return (
          <div key={el.value} className="select__item select__item_current select-item">
            <span className="select-item__text">{el.name}</span>
            <CheckIcon className="select-item__ico" />
          </div>
        );
      } else {
        return (
          <div
            key={el.value}
            className="select__item select-item"
            onClick={disabled ? () => {} : onSelect.bind(null, el.value)}
            onAuxClick={disabled ? () => {} : onAuxSelect.bind(null, el.value)}
          >
            <span>{el.name}</span>
          </div>
        );
      }
    });
  };

  const getLinks = (list = []) => {
    return list.map((el) => {
      const isActive = el.value === value;
      if (el.name==='@coair.net') return false // disallow this domain to be displayed in the list - development domain
      return isActive ? (
        <div className="select__item select__item_current select-item" key={el.value}>
          <span className="select-item__text">{el.name}</span>
          <CheckIcon className="select-item__ico" />
        </div>
      ) : (
        <a key={el.value} href={disabled ? null : el.value} className="select-item__link">
          <div className="select__item select-item" key={el.value}>
            <span className="select-item__text">{el.name}</span>
          </div>
        </a>
      );
    });
  };

  const mapTypes = (chosenType) => {
    const res = types[chosenType] ?? types.select;

    switch (res) {
      case types.select:
        return getList;
      case types.link:
        return getLinks;
      default:
        return getList;
    }
  };

  return <div className={className}>{mapTypes(type)(values)}</div>;
};

OpenSelector.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)),
  value: PropTypes.any,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  onSelect: PropTypes.func,
  onAuxSelect: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default OpenSelector;
