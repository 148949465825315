import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router';

const withAccountId = (WrappedComponent) => {
  const WithAccount = (props) => {
    const location = useLocation();
    const history = useHistory();
    const { uid } = useSelector((state) => state.auth);
    const parsedQuery = qs.parse(location.search);

    useEffect(() => {
      if (uid !== parsedQuery.aid) {
        history.replace({
          ...location,
          search: '?' + qs.stringify({ ...parsedQuery, aid: uid }),
        });
      }
    }, [location, uid]);

    return <WrappedComponent {...props} />;
  };

  return WithAccount;
};

export default withAccountId;
