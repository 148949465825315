import { useEffect } from 'react';
import { useHistory } from 'react-router';
import urlJoin from 'url-join';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import i18n from 'i18next';
import Cookies from 'js-cookie';
import qs from 'query-string';
import { useQuery, useMutation } from 'react-query';

import NotificationService from './NotificationService';
import SocketService from './SocketService';
import ClientError from '../models/ClientError';
import Connector, { authConnector } from '../connectors';
import store, { rootActions } from '../storage';
import Debug from '../../utils/debug';
import routeConfig from '../../config/routes.config';

const debug = Debug.extend('service:authorization');
const { authActions } = rootActions;

/** @typedef {import('../connectors/Connector').ConnectorCustomErrorHandler} ConnectorCustomErrorHandler */

class AuthorizationService {
  constructor() {
    this.fpAgent = null;
    this.loaded = null;
  }

  static getRefreshToken() {
    const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_STORAGE_NAME);
    return refreshToken !== null ? refreshToken : undefined;
  }

  static setRefreshToken(refreshToken) {
    localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_STORAGE_NAME, refreshToken);
  }

  static removeRefreshToken() {
    localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_STORAGE_NAME);
  }

  static extractAccountUid() {
    return qs.parse(window.location.search).aid;
  }

  static async init() {
    const authorizationService = new AuthorizationService();
    return authorizationService.init();
  }

  async init() {
    await this.setFingerprintAgent();
    return this;
  }

  /**
   * @private
   */
  async setFingerprintAgent() {
    if (this.loaded) return;
    this.loaded = FingerprintJS.load();
    this.fpAgent = await this.loaded;
    return this;
  }

  /**
   * @private
   */
  async getFingerprint() {
    if (this.fpAgent === null) await this.setFingerprintAgent();
    if (this.loaded) await this.loaded;

    let resultedFingerprint = null;
    const fingerprint = (await this.fpAgent.get()).visitorId;
    const fingerprintVerification = (await this.fpAgent.get()).visitorId;

    if (fingerprint !== fingerprintVerification) {
      debug(`Fingerprint inconsistency spotted`);
      const storedFp = localStorage.getItem(process.env.REACT_APP_FINGERPRINT_STORAGE_NAME);

      if (!storedFp) {
        resultedFingerprint = fingerprint;
        localStorage.setItem(process.env.REACT_APP_FINGERPRINT_STORAGE_NAME, resultedFingerprint);
      } else {
        resultedFingerprint = storedFp;
      }
    } else {
      resultedFingerprint = fingerprint;
    }

    debug(`fingerprint '%s'`, resultedFingerprint);
    return resultedFingerprint;
  }

  /**
   * @private
   */
  setTokenPair(tokenPair) {
    if (!tokenPair) return false;

    debug(`Setting token pair`);
    const bearerToken = `Bearer ${tokenPair.token}`;
    Connector.authorize(bearerToken);
    SocketService.connect(bearerToken);
    AuthorizationService.setRefreshToken(tokenPair.refreshToken);
    // Path prefix for attachments is defined in backend: src/utils/urlPath.js, attachmentUrlPrefix
    Cookies.set('auth', tokenPair.token, {path: '/attachment'});
  }

  /**
   * @private
   */
  removeTokenPair() {
    debug(`Removing token pair`);
    Connector.unauthorize();
    AuthorizationService.removeRefreshToken();
  }

  async updateCurrentUser() {
    try {
      authConnector.withErrorHandler(this.authErrorHandler);
      debug('Attempting to update current user from backend');
      const user = await authConnector.getUser();
      store.dispatch(authActions.setUser(user));
      debug('Current user is updated', {user});
    } finally {
      authConnector.withoutErrorHandler(this.authErrorHandler);
    }
  }

  /**
   * @private
   */
  async setAuthorization(tokenPair, updateUser = true) {
    try {
      authConnector.withErrorHandler(this.authErrorHandler);
      this.setTokenPair(tokenPair);
      store.dispatch(authActions.setSession(true));
      if (updateUser) {
        const user = await authConnector.getUser();
        store.dispatch(authActions.setUser(user));
      }
    } finally {
      authConnector.withoutErrorHandler(this.authErrorHandler);
    }
  }

  /**
   * @private
   */
  async createSession(fingerprint) {
    try {
      authConnector.withErrorHandler(this.authErrorHandler);
      debug(`Creating new session`);
      const tokenPair = await authConnector.createSession(fingerprint);
      debug(`Session successfully created`);
      await this.setAuthorization(tokenPair);
    } finally {
      authConnector.withoutErrorHandler(this.authErrorHandler);
    }
  }

  /**
   * @private
   */
  async refreshSession(refreshToken, fingerprint, accountUid, redirection) {
    debug(`Refreshing session with fingerprint: '%s', account uid: '%s'`, fingerprint, accountUid);

    try {
      authConnector.withErrorHandler(this.authErrorHandler);
      const tokenPair = await authConnector.refreshSession(refreshToken, fingerprint, accountUid);
      debug(`Session successfully refreshed`);
      await this.setAuthorization(tokenPair, false);
      return true;
    } catch (error) {
      debug(`Error occurred while refreshing session: %s`, error?.message);
      this.removeTokenPair();
      this.createSession(fingerprint);

      if (error?.status === 500) debug(`Hijacking Attempt Reported`);
      if (error?.status === 307 && redirection) return redirection();

      return false;
    } finally {
      authConnector.withoutErrorHandler(this.authErrorHandler);
    }
  }

  /**
   * Custom error handler to catch situation when authentication request
   * results in HTTP 401 response
   *
   * In this case default error handler will attempt to re-establish session,
   * but since we're already in this process - it will effectively cause infinite loop
   * which we should avoid.
   *
   * Such situation can be treated as fatal error because we can't run application
   * without a session and can't establish a session.
   *
   * It may happen in a case if backend have some kind of issue with JWT verification,
   * for example in a case if JWT secret was changed.
   *
   * @type {ConnectorCustomErrorHandler}
   * @private
   */
  authErrorHandler(response, payload) {
    if (response.status !== 401) {
      // Let default error handler to handle all remaining cases
      return true;
    }
    debug('authErrorHandler() error', {response, payload});
    const {fatalError} = rootActions.appActions;
    store.dispatch(fatalError(
        i18n.t('Authentication problem'),
        i18n.t('There is currently a problem with authentication on service\'s backend'),
    ));
  }

  async establishSession(refreshToken = AuthorizationService.getRefreshToken(), redirection) {
    debug(`Establishing session with refresh token: %s`, !!refreshToken);
    try {
      authConnector.withErrorHandler(this.authErrorHandler);

      const fp = await this.getFingerprint();
      const accountUid = AuthorizationService.extractAccountUid();

      if (refreshToken || accountUid) {
        const result = await this.refreshSession(refreshToken, fp, accountUid, redirection);
        if (result) {
          await this.updateCurrentUser();
        }
      } else {
        await this.createSession(fp);
      }

      return true;
    } finally {
      authConnector.withoutErrorHandler(this.authErrorHandler);
    }
  }

  /**
   * @private
   */
  async logout() {
    this.removeTokenPair();
    store.dispatch(authActions.breakSession());
    const fp = await this.getFingerprint();
    await this.createSession(fp);
  }

  useEstablishSession(refreshToken = AuthorizationService.getRefreshToken()) {
    const history = useHistory();

    useEffect(() => {
      this.establishSession(refreshToken, () => history.push(routeConfig.login.path));
    }, []);
  }

  useVerifyEmail(config) {
    return useMutation(
      async ({ email }) => {
        try {
          const response = await authConnector.verifyUserEmail(email);
          return response;
        } catch (error) {
          switch (error.status) {
            case 400:
              throw new ClientError('Email already exists or is a temporary mail.');
            default:
              throw new ClientError('Unknown problem.');
          }
        }
      },
      { ...config }
    );
  }

  useConfirmEmailByToken(token, config) {
    return useQuery(
      'AuthorizationService.useConfirmEmailByToken',
      () => authConnector.confirmUserEmailByToken(token),
      {
        retry: false,
        refetchOnWindowFocus: false,
        ...config,
      }
    );
  }

  useResendVerification(config) {
    return useMutation(
      async ({ email }) => {
        let response = null;

        try {
          response = await authConnector.reverifyUserEmail(email);
          return response;
        } catch (error) {
          if (error.status >= 500) {
            throw new ClientError('Unknown problem.');
          } else {
            return response;
          }
        }
      },
      { ...config }
    );
  }

  useCompleteRegistration(config) {
    return useMutation(
      async ({ activationKey, password }) => {
        try {
          const response = await authConnector.completeRegistration(activationKey, password);
          return response;
        } catch (error) {
          switch (error.status) {
            case 400:
              throw new ClientError('Token seems to be expired.');
            default:
              throw new ClientError('Unknown problem.');
          }
        }
      },
      { ...config }
    );
  }

  useAuthorize(config) {
    return useMutation(
      async ({ email, password }) => {
        try {
          debug(`Login user`);
          const tokenPair = await authConnector.login(await this.getFingerprint(), email, password);
          await this.setAuthorization(tokenPair);
          debug(`User successfully logged in`);
          return true;
        } catch (error) {
          switch (error.status) {
            case 400:
              throw new ClientError('Invalid email or password.');
            default:
              throw new ClientError('Unknown problem.');
          }
        }
      },
      { ...config }
    );
  }

  useRecoverPassword(config) {
    return useMutation(({ email }) => authConnector.recoverPassword(email), { ...config });
  }

  useConfirmRecoveryPassword(recoverPasswordKey, config) {
    return useQuery(
      'AuthorizationService.useConfirmRecoveryPasswordToken',
      () => authConnector.confirmRecoverPassword(recoverPasswordKey),
      {
        retry: false,
        refetchOnWindowFocus: false,
        ...config,
      }
    );
  }

  useResetPassword(config) {
    return useMutation(
      async ({ recoverPasswordKey, password }) => {
        try {
          const response = await authConnector.resetPassword(recoverPasswordKey, password);
          return response;
        } catch (error) {
          switch (error.status) {
            case 400:
              throw new ClientError('Token seems to be expired.');
            default:
              throw new ClientError('Unknown problem.');
          }
        }
      },
      { ...config }
    );
  }

  useLogout() {
    const history = useHistory();

    return async () => {
      await this.logout();
      history.push(routeConfig.home.path);
    };
  }

  useTransferIdentity(config) {
    return useMutation(
      async ({ domain, mailbox, newTab }) => {
        const result = await authConnector.createIdentityTransfer(domain, mailbox);
        const ref = urlJoin(result.url, routeConfig.identityTransfer.path, result.key);
        window.open(ref, newTab ? '_blank' : '_self');
        return result;
      },
      {
        onError() {
          NotificationService.error('Error occurred while redirecting to another mailbox');
        },
        ...config,
      }
    );
  }

  useConfirmIdentityTransfer(transferKey, config) {
    const history = useHistory();

    return useQuery(
      'AuthorizationService.useConfirmIdentityTransfer',
      async () => {
        const result = await authConnector.verifyIdentityTransfer(
          transferKey,
          await this.getFingerprint()
        );

        await this.setAuthorization(result.tokenPair);
        if (result.targetedMailbox)
          history.push(urlJoin(routeConfig.mailbox.path, result.targetedMailbox));

        return result;
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        ...config,
      }
    );
  }

  useTransferAuth(config) {
    return useQuery(
      'AuthorizationService.useTransferAuth',
      async () => authConnector.createAuthTransfer(),
      {
        retry: false,
        refetchOnWindowFocus: false,
        ...config,
      }
    );
  }

  useConfirmAuthTransfer(transferKey, config) {
    const history = useHistory();

    return useQuery(
      'AuthorizationService.useConfirmAuthTransfer',
      async () => {
        const result = await authConnector.confirmAuthTransfer(
          transferKey,
          await this.getFingerprint()
        );

        await this.setAuthorization(result);
        history.push(routeConfig.account.path);

        return result;
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        ...config,
      }
    );
  }

  useSetNewPassword(config) {
    return useMutation(
      async ({ newPassword }) => {
        try {
          const response = await authConnector.setNewPassword(newPassword);
          return response;
        } catch (error) {
          throw new ClientError('Unknown problem.');
        }
      },
      {
        onSuccess() {
          NotificationService.success('Password successfully changed');
        },
        ...config,
      }
    );
  }

  useDeleteAccount(config) {
    const history = useHistory();

    return useMutation(
      async () => {
        await authConnector.deleteAccount();
        await this.logout();
        history.push(routeConfig.home.path);
      },
      {
        onError() {
          NotificationService.error('Error occurred while deleting account');
        },
        ...config,
      }
    );
  }
}

export default new AuthorizationService();
