export const ACTIONS = {
  SET_CONNECTION_STATE: 'APP::SET_CONNECTION_STATE',
  FATAL_ERROR: 'APP::FATAL_ERROR',
};

/**
 * @typedef {Object} AppStoreContents
 * @property {boolean} connectionState
 * @property {boolean} fatalError
 * @property {string|undefined} errorTitle
 * @property {string|undefined} errorText
 */

/** @type {AppStoreContents} */
const initialState = {
  connectionState: true,
  fatalError: false,
  errorTitle: undefined,
  errorText: undefined,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_CONNECTION_STATE:
      return {
        ...state,
        connectionState: action.connectionState,
      };
    case ACTIONS.FATAL_ERROR:
      return {
        ...state,
        fatalError: true,
        errorTitle: action.title,
        errorText: action.text,
      };
    default:
      return state;
  }
};

export default appReducer;
