import urlJoin from 'url-join';
import Connector from './Connector';
import Debug from '../../utils/debug';

const debug = Debug.extend('connector:host');

class HostConnector extends Connector {
  constructor(baseURL, { base, paths }) {
    super(urlJoin(baseURL + base));

    this.paths = {
      getAll: paths.getAll ?? '',
    };
  }

  async getAll() {
    debug(`Get in getAll`);
    const result = await super.get(this.paths.getAll);
    debug(`Get out getAll with results: %s`, result.list.length);
    return result;
  }
}

export default HostConnector;
