import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';

import './setup';

import App from './components/App/App';
import store from './api/storage';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './assets/js/calculateVh';
import './assets/css/variables.scss';
import './assets/css/default.scss';
import './assets/css/base.scss';
import './assets/css/fonts.scss';


Sentry.init({
  dsn: "https://80e84aab99914fe3942db2c503389459@sentry.def24.com/24",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: .5,
});

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
