import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import TranslationService from '../../../../../api/services/TranslationService';
import useWidthCondition from '../../../../../hooks/useWidthCondition';

import { ReactComponent as ClipIcon } from '../../../../../assets/svg/clip.svg';
import { ReactComponent as WarnIcon } from '../../../../../assets/svg/guard-warn.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/svg/trash.svg';

const breakPoints = {
  767: true,
  default: false,
};

const Row = (props) => {
  const { mail, onDelete = () => {} } = props;
  const isAdaptive = useWidthCondition(breakPoints);
  const moment = TranslationService.useMoment();

  const link = `/mail/${mail.uid}`;
  const from = mail.from.name !== '' ? mail.from.name : mail.from.address;

  const getIcon = () => {
    if (mail.spam) {
      return (
        <div className="icon-wrapper">
          <WarnIcon className="warn-icon" />
        </div>
      );
    } else if (mail.attachmentsLength > 0) {
      return (
        <div className="icon-wrapper">
          <ClipIcon className="clip-icon" />
        </div>
      );
    }
  };

  return (
    <tr className={classnames('body-row', { ['new']: !mail.read })}>
      {isAdaptive ? (
        <td className="cell cell-combined">
          <Link to={link}>{from}</Link>
          <Link to={link}>
            <span className="subject">{mail.subject}</span>
          </Link>
        </td>
      ) : (
        <>
          <td className="cell cell-from">
            <Link to={link}>{from}</Link>
          </td>

          <td className="cell cell-subject">
            <Link to={link}>
              <span className="subject">{mail.subject}</span>
            </Link>
          </td>
        </>
      )}

      <td className="cell cell-att">{getIcon()}</td>

      <td className="cell cell-date">
        {moment(mail.timestamp).format('MMM D')}
        <div className="delete">
          <DeleteIcon className="trash-icon" onClick={() => onDelete(mail.uid)} />
        </div>
      </td>
    </tr>
  );
};

export default Row;
