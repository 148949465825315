import React from 'react';
import PropTypes from 'prop-types';

import TranslationService from '../../../api/services/TranslationService';

const Input = (props) => {
  const {
    onChange = () => { },
    onError = () => { },
    value,
    textarea = false,
    filter,
    name,
    domain,
    validator,
    forwardRef,
    placeholder,
    ...rest
  } = props;
  let currentValue = value === null ? '' : value;
  let { t } = TranslationService.useTranslation();

  

  const filterValue = (value) => {
    if (!filter) return value;
    if (filter instanceof Array) for (let one of filter) value = one(value);
    if (filter instanceof Function) value = filter(value);
    return value;
  };

  const validateValue = (value) => {
    let result = null;
    if (!validator) return true;

    const validatorManager = () => {
      if (validator instanceof RegExp) return validator.test(value);
      if (validator instanceof Function) return validator(value);
      if (validator instanceof String) return validator === value;
      return true;
    };

    const validatorAnswerHandler = (answer) => {
      if (typeof answer === 'boolean') return answer;
      return false;
    };

    if (validator instanceof Array) {
      for (let i = 0; i < validator.length; i++) {
        result = validatorManager(validator[i]);
        if (!validatorAnswerHandler(result)) {
          onError();
          return result;
        }
      }
    } else {
      result = validatorManager(validator);
      if (!validatorAnswerHandler(result)) {
        onError();
        return result;
      }
    }

    // if (name === 'mailbox') {
    //   let email;
    //   if (typeof value === 'string') email = value.toLowerCase().trim()
    //   // const emailTest = `${email}@${domain}`
    //   // result = (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(emailTest);
      
    //   result = validMail1Prefix(email)
    //   if (!validatorAnswerHandler(result)) {
    //     onError();
    //     return result;
    //   }
    // }
    return result;
  };


  

  const validationHandler = (validationResult) => {
    const result = validationResult ? validationResult : false;
    return result;
  };

  const changeHandler = (event) => {
    const name = event.target.name;
    let fieldValue = event.target.value;
    let validationResult = null;

    fieldValue = filterValue(fieldValue);
    validationResult = validateValue(fieldValue);
    validationResult = validationHandler(validationResult);

    if (validationResult && onChange) return onChange(fieldValue, name);
  };

  if (textarea) {
    return (
      <textarea
        value={currentValue}
        onChange={changeHandler}
        ref={forwardRef}
        placeholder={t(placeholder)}
        {...rest}
      ></textarea>
    );
  }

  return (
    <input
      value={currentValue}
      onChange={changeHandler}
      ref={forwardRef}
      placeholder={t(placeholder)}
      {...rest}
    />
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  onError: PropTypes.func,
  value: PropTypes.string,
  textarea: PropTypes.bool,
  filter: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.func), PropTypes.func]),
  validator: PropTypes.oneOfType([
    PropTypes.instanceOf(RegExp),
    PropTypes.func,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOf([PropTypes.instanceOf(RegExp), PropTypes.func, PropTypes.string])
    ),
  ]),
  forwardRef: PropTypes.object,
  placeholder: PropTypes.string,
};

export default Input;
