import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';

import NotificationService from './NotificationService';
import { mailConnector } from '../connectors';
import routesConfig from '../../config/routes.config';

class MailService {
  static handleMailError(error, history) {
    if (error.status === 403) {
      NotificationService.error('Privilege denied.');
      history.replace(routesConfig.home.path);
      return null;
    }

    if (error.status === 404) {
      NotificationService.error('Mailbox expired.');
      history.replace(routesConfig.home.path);
      return null;
    }

    throw error;
  }

  static useGetPreviews(mailboxName, length, offset, config) {
    const history = useHistory();

    return useQuery(
      ['MailService.useGetPreviews', mailboxName, length, offset],
      async () => {
        try {
          return await mailConnector.getPreviews(mailboxName, length, offset);
        } catch (error) {
          return MailService.handleMailError(error, history);
        }
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        forceFetchOnMount: false,
        ...config,
      }
    );
  }

  static useGetMail(id, config) {
    const history = useHistory();

    return useQuery(
      ['MailService.useGetMail', id],
      async () => {
        try {
          return await mailConnector.getMail(id);
        } catch (error) {
          return MailService.handleMailError(error, history);
        }
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        forceFetchOnMount: false,
        ...config,
      }
    );
  }

  static useDeleteMail() {
    const history = useHistory();

    return useCallback(async (id, backLink) => {
      try {
        await mailConnector.deleteMail(id);
        NotificationService.success('Mail successfully deleted.');
        if (backLink) history.push(backLink);
        return true;
      } catch (error) {
        NotificationService.error('Error occurred while deleting mail.');
        return false;
      }
    }, []);
  }
}

export default MailService;
