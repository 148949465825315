import React from 'react';

import withAccountId from '../../../hoc/withAccountId';
import withScrollToTop from '../../../hoc/withScrollToTop';
import MailboxForm from './MailboxForm/MailboxForm';
import About from './About/About';
import { Advertisement } from '../../utils';
import routeConfig from '../../../config/routes.config';
import './home.scss';

import ExtensionDownload from './ExtensionDownload';

export const config = { path: routeConfig.home.path };

const Home = (props) => {

  return (
    <>
      <MailboxForm />
      <ExtensionDownload home={true}/>
      <Advertisement variant="large" withWrapper />
      <About />
    </>
  );
};

export default withScrollToTop(withAccountId(Home));
