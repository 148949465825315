import React from 'react';
import PropTypes from 'prop-types';

const Space = (props) => {
  const { lineBreak } = props;

  if (lineBreak) return <br />;
  return <>&nbsp;</>;
};

Space.propTypes = {
  lineBreak: PropTypes.bool,
};

export default Space;
