import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import NotificationService from './NotificationService';
import { mailboxConnector } from '../connectors';
import { rootActions } from '../storage';
import Debug from '../../utils/debug';
import routeConfig from '../../config/routes.config';

const debug = Debug.extend('service:mailbox');
const { mailboxActions } = rootActions;

class MailboxService {
  static useGetCurrent(config) {
    const { uid, isRegistered } = useSelector((state) => state.auth);
    const { setExpirationTime } = mailboxActions.useMailboxActions();

    const query = useQuery(
      'MailboxService.getCurrent',
      async () => {
        debug('Get current mailbox for unauthorized user');
        const result = await mailboxConnector.getCurrent();
        setExpirationTime(result.name, result.expiredTime);
        return { current: result, list: [] };
      },
      {
        onError() {
          NotificationService.error('Error occurred');
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
        ...config,
      }
    );

    const regQuery = useQuery(
      'MailboxService.getCurrent',
      async () => {
        debug('Get active mailbox list for authorized user');
        const result = await mailboxConnector.getLocalWithCurrent();
        result.list.forEach((mb) => setExpirationTime(mb.name, mb.expiredTime));
        return result;
      },
      {
        onError() {
          NotificationService.error('Error occurred');
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
        ...config,
      }
    );

    useEffect(() => {
      if (!uid) return;
      if (isRegistered) regQuery.refetch();
      else query.refetch();
    }, [uid]);

    return isRegistered ? regQuery : query;
  }

  static useRefreshMailbox(config) {
    const { setExpirationTime } = mailboxActions.useMailboxActions();
    const { isRegistered } = useSelector((state) => state.auth);

    const query = useMutation(
      async () => {
        const result = await mailboxConnector.refreshMailbox();
        setExpirationTime(result.current.name, result.current.expiredTime);
        return result;
      },
      {
        onError() {
          NotificationService.error('Error occurred while refreshing mailbox');
        },
        ...config,
      }
    );

    const authQuery = useMutation(
      async ({ mailboxName }) => {
        const result = await mailboxConnector.refreshMailbox(mailboxName);
        result.list.forEach((mb) => setExpirationTime(mb.name, mb.expiredTime));
        return result;
      },
      {
        onError() {
          NotificationService.error('Error occurred.');
        },
        ...config,
      }
    );

    return isRegistered ? authQuery : query;
  }

  static useCreateMailbox(config) {
    const { setExpirationTime } = mailboxActions.useMailboxActions();
    const history = useHistory();

    return useMutation(
      async ({ name, withRedirect }) => {
        const result = await mailboxConnector.createMailbox(name);
        setExpirationTime(result.name, result.expiredTime);
        if (withRedirect) history.push(`${routeConfig.mailbox.path}/${result.name}`);
        return result;
      },
      { ...config }
    );
  }

  static useGetAllRemote(config) {
    return useQuery('MailboxService.useGetAllRemote', () => mailboxConnector.getAllRemote(), {
      retry: false,
      refetchOnWindowFocus: false,
      ...config,
    });
  }

  static useGetAll(config) {
    return useQuery('MailboxService.useGetAll', () => mailboxConnector.getAll(), {
      retry: false,
      refetchOnWindowFocus: false,
      ...config,
    });
  }

  static useDeleteMailbox(config) {
    return useMutation((mailbox) => mailboxConnector.delete(mailbox), {
      onError() {
        NotificationService.error('Error occurred while deleting mailbox');
      },
      ...config,
    });
  }
}

export default MailboxService;
