export default {
  user: {
    base: '/user',
    paths: {
      createSession: '/session/create',
      refreshSession: '/session/refresh',
      getUser: '/',
      verifyEmail: '/email/verify',
      reverifyEmail: '/email/reverify',
      confirmEmail: '/email/confirm',
      register: '/register',
      login: '/login',
      passwordRecovery: '/password-recovery',
      confirmPasswordRecovery: '/password-recovery/confirm',
      resetPassword: '/password-recovery/reset',
      identityTransfer: '/transfer/identity',
      authTransfer: '/transfer/authorization',
      setPassword: '/set-password',
      deleteAccount: '/account/delete',
    },
  },
  mailbox: {
    base: '/mailbox',
    paths: {
      getCurrent: '/current',
      refresh: '/refresh',
      create: '/create',
      getLocalWithCurrent: './local-list-with-current',
      getAllRemote: './remote-list',
      getAll: './all-list',
      delete: './delete',
    },
  },
  mail: {
    base: '/mail',
    paths: {
      getPreviews: '/preview',
      getMail: '/',
      deleteMail: '/remove',
    },
  },
  host: {
    base: '/host',
    paths: {
      getAll: '/all',
    },
  },
  socket: {
    base: '/socket',
  },
};
