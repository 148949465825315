import React, { useEffect, useState } from 'react';

import withPopup from '../../../hoc/withPopup';
import { Title, Text } from '../../parts/Document/Document';
import { Popup } from '../../utils';

const RedirectionPopup = (props) => {
  const { open, onClose = () => {} } = props;
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((state) => {
        const next = state - 1;
        if (next <= 0) clearInterval(timer);
        return next;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      window.location.reload();
    }
  }, [seconds]);

  return (
    <Popup open={open} onClose={onClose} unclosable>
      <div className="redirection__container">
        <Title popUpTitle className="title-text">
          Server Redirection
        </Title>
        <div className="h1 title-text redirection__count-down">{seconds}</div>
        <Text className="redirection__message">
          Looks like current server went offline. We will try to redirect you to another server.
        </Text>
      </div>
    </Popup>
  );
};

export default withPopup(RedirectionPopup);
