import React from 'react';
import { useParams } from 'react-router';

import AuthorizationService from '../../../api/services/AuthorizationService';
import { Feedback, Title, P, Link, Container } from '../../parts/Document/Document';
import { Loader, PagePaper, FormWrapper } from '../../utils';
import withScrollToTop from '../../../hoc/withScrollToTop';

import routeConfig from '../../../config/routes.config';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import CompleteRegistration from '../../forms/CompleteRegistration/CompleteRegistration';

export const config = { path: `${routeConfig.authTransfer.path}/:id` };

const AuthorizationTransfer = (props) => {
  const { id } = useParams();
  const { isLoading, isError } = AuthorizationService.useConfirmAuthTransfer(id);

  if (isLoading) return <Loader className="page-loader" />;

  if (isError)
    return (
      <PagePaper>
        <PageToolbar />
        <FormWrapper>
          <Title popUpTitle className="title-text">
            Link seems to be expired
          </Title>
          <P>
            <Link to={routeConfig.home.path}>Go to main page.</Link>
          </P>
        </FormWrapper>
      </PagePaper>
    );

  return null;
};

export default withScrollToTop(AuthorizationTransfer);
