import React, { useContext } from 'react';

import { Link, Text, Space, P } from '../../../../parts/Document/Document';
import PopupContext from '../../../../../context/PopupContext';
import withPreventDefault from '../../../../../utils/withPreventDefault';

import routeConfig from '../../../../../config/routes.config';

const Notification = (props) => {
  const { openRegistration, openAuthorization } = useContext(PopupContext);

  return (
    <div className="notification">
      <P className="notification__alert">
        <Text>Your temporary email will be deleted after a short time of inactivity.</Text>
      </P>
      <P>
        <Link to={routeConfig.register.path} onClick={withPreventDefault(openRegistration)}>
          Please register
        </Link>
        <Space />
        <Text>to keep this email address or</Text>
        <Space />
        <Link to={routeConfig.login.path} onClick={withPreventDefault(openAuthorization)}>
          SignIn
        </Link>
        <Space />
        <Text> with the existing account.</Text>
      </P>
    </div>
  );
};

export default Notification;
