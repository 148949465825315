import { useCallback, useEffect, useState } from 'react';

export default (action = () => {}) => {
  const [mouseUpHandler, setMouseUpHandler] = useState(null);

  const closeHandler = useCallback((firstEvent) => {
    if (firstEvent.currentTarget !== firstEvent.target) return;
    const el = firstEvent.currentTarget;
    const handler = (secondEvent) => {
      if (el === secondEvent.target) return action();
    };

    document.addEventListener('mouseup', handler, { once: true });
    setMouseUpHandler(() => handler);
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', mouseUpHandler);
    };
  }, [mouseUpHandler]);

  return closeHandler;
};
