import { useDispatch } from 'react-redux';
import { ACTIONS } from './reducer';
import Debug from '../../../utils/debug';

const debug = Debug.extend('storage:actions:mailbox');

export const setExpirationTime = (name, expirationTime) => {
  debug(`Set expiration time to mailbox: %s`, name);

  return async (dispatch) => {
    dispatch({
      type: ACTIONS.SET_TIMER,
      name,
      expirationTime,
    });
  };
};

export const useMailboxActions = () => {
  const dispatch = useDispatch();

  return {
    setExpirationTime: (name, expirationTime) => dispatch(setExpirationTime(name, expirationTime)),
  };
};
