import React from 'react';
import { useParams } from 'react-router';

import MailService from '../../../api/services/MailService';
import withAccountId from '../../../hoc/withAccountId';
import withScrollToTop from '../../../hoc/withScrollToTop';

import { Loader, PagePaper, Advertisement } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import MailBody from './Body/Body';

import { ReactComponent as InboxIcon } from '../../../assets/svg/inbox.s.svg';
import routeConfig from '../../../config/routes.config';
import './mail.scss';

export const config = { path: `${routeConfig.mail.path}/:id` };

const Mail = (props) => {
  const { id: mailId } = useParams();
  const { data, isLoading } = MailService.useGetMail(mailId);
  const deleteMail = MailService.useDeleteMail();

  const mail = data?.mail;
  const mailbox = data?.mailbox;
  const backLink = mailbox
    ? {
        pathname: `${routeConfig.mailbox.path}/${mailbox.name}`,
        state: { fromMails: true },
      }
    : null;

  const deleteHandler = async () => {
    await deleteMail(data.mail.uid, backLink);
  };

  return (
    <>
      <PagePaper>
        <PageToolbar backLink={backLink} backLinkText="Back to Inbox" backLinkIcon={InboxIcon} />

        <div className="main__body main__body_secondary main__body_remove-padding">
          {isLoading ? (
            <Loader className="page-loader" />
          ) : (
            <MailBody mail={mail} onDelete={deleteHandler} />
          )}
        </div>
        <div className="main__footer">
          <Advertisement />
        </div>
      </PagePaper>
    </>
  );
};

export default withScrollToTop(withAccountId(Mail));
