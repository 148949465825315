import React from 'react';

import concatMailbox from '../../../utils/concatMailbox';
import HostService from '../../../api/services/HostService';
import withPopup from '../../../hoc/withPopup';

import { Popup, Loader } from '../../utils';
import { Feedback, Title, Text, P } from '../../parts/Document/Document';
import OpenSelector from '../../utils/OpenSelector/OpenSelector';

const HostPopup = (props) => {
  const { open, onClose = () => {} } = props;
  const { data, isLoading, isError } = HostService.useGetAll();

  const getBody = (isLoading, isError) => {
    if (isLoading) return <Loader />;
    if (isError)
      return (
        <Feedback>
          <Title>Error</Title>
          <P>
            <Text>Error occurred while trying to connect to other servers.</Text>
          </P>
        </Feedback>
      );

    return (
      <>
        <div className="popup__host">
          <div className="popup__title">
            <Title className="title-text h1">Change Hostname</Title>
            <svg
              className="title-ico"
              width="55"
              height="42"
              viewBox="0 0 55 42"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#6F6F6F"
                d="M 1.515625 28.667969 C 1.367188 28.667969 1.1875 28.6875 1.035156 28.753906 C 0.902344 28.820312 0.75 28.90625 0.640625 29.039062 C 0.53125 29.148438 0.441406 29.28125 0.378906 29.4375 C 0.335938 29.589844 0.289062 29.746094 0.289062 29.898438 L 0.289062 39.078125 C 0.289062 39.234375 0.335938 39.386719 0.378906 39.539062 C 0.441406 39.695312 0.53125 39.828125 0.640625 39.9375 C 0.75 40.046875 0.902344 40.15625 1.035156 40.222656 C 1.1875 40.265625 1.363281 40.308594 1.515625 40.308594 L 53.460938 40.308594 C 53.613281 40.308594 53.769531 40.265625 53.921875 40.222656 C 54.074219 40.15625 54.226562 40.046875 54.335938 39.9375 C 54.445312 39.828125 54.535156 39.695312 54.601562 39.539062 C 54.664062 39.386719 54.707031 39.234375 54.707031 39.078125 L 54.707031 29.898438 C 54.707031 29.746094 54.664062 29.589844 54.601562 29.4375 C 54.535156 29.285156 54.449219 29.152344 54.335938 29.042969 C 54.226562 28.910156 54.074219 28.820312 53.921875 28.757812 C 53.769531 28.691406 53.613281 28.667969 53.460938 28.667969 Z M 9.492188 32.035156 C 10.761719 32.035156 11.835938 33.136719 11.835938 34.414062 C 11.835938 35.691406 10.785156 36.769531 9.492188 36.769531 C 8.199219 36.769531 7.148438 35.714844 7.148438 34.414062 C 7.148438 33.113281 8.199219 32.035156 9.492188 32.035156 Z M 9.492188 32.035156 "
              />
              <path
                fill="#6F6F6F"
                d="M 1.515625 14.582031 C 1.367188 14.582031 1.1875 14.625 1.035156 14.691406 C 0.902344 14.734375 0.75 14.84375 0.640625 14.957031 C 0.53125 15.066406 0.441406 15.21875 0.378906 15.351562 C 0.335938 15.507812 0.289062 15.660156 0.289062 15.835938 L 0.289062 24.992188 C 0.289062 25.144531 0.335938 25.320312 0.378906 25.476562 C 0.441406 25.628906 0.53125 25.742188 0.640625 25.875 C 0.75 25.980469 0.902344 26.070312 1.035156 26.136719 C 1.1875 26.203125 1.363281 26.226562 1.515625 26.226562 L 53.460938 26.226562 C 53.613281 26.226562 53.769531 26.203125 53.921875 26.136719 C 54.074219 26.070312 54.226562 25.984375 54.335938 25.875 C 54.445312 25.742188 54.535156 25.628906 54.601562 25.476562 C 54.664062 25.324219 54.707031 25.148438 54.707031 24.992188 L 54.707031 15.835938 C 54.707031 15.660156 54.664062 15.507812 54.601562 15.351562 C 54.535156 15.21875 54.449219 15.0625 54.335938 14.957031 C 54.226562 14.847656 54.074219 14.734375 53.921875 14.691406 C 53.769531 14.625 53.636719 14.582031 53.460938 14.582031 Z M 2.765625 17.070312 L 52.234375 17.070312 L 52.234375 23.738281 L 2.765625 23.738281 Z M 2.765625 17.070312 "
              />
              <path
                fill="#6F6F6F"
                d="M 9.492188 17.972656 C 8.199219 17.972656 7.148438 19.027344 7.148438 20.328125 C 7.148438 21.625 8.199219 22.683594 9.492188 22.683594 C 10.785156 22.683594 11.835938 21.605469 11.835938 20.328125 C 11.835938 19.050781 10.789062 17.972656 9.492188 17.972656 Z M 9.492188 17.972656 "
              />
              <path
                fill="#6F6F6F"
                d="M 1.515625 0.519531 C 1.363281 0.519531 1.1875 0.566406 1.035156 0.628906 C 0.902344 0.699219 0.75 0.761719 0.640625 0.871094 C 0.53125 1.007812 0.441406 1.136719 0.378906 1.289062 C 0.335938 1.445312 0.289062 1.621094 0.289062 1.777344 L 0.289062 10.910156 C 0.289062 11.085938 0.335938 11.261719 0.378906 11.394531 C 0.441406 11.546875 0.53125 11.679688 0.640625 11.789062 C 0.75 11.898438 0.902344 11.988281 1.035156 12.054688 C 1.1875 12.121094 1.363281 12.164062 1.515625 12.164062 L 53.460938 12.164062 C 53.613281 12.164062 53.769531 12.121094 53.921875 12.054688 C 54.074219 11.988281 54.226562 11.902344 54.335938 11.789062 C 54.445312 11.679688 54.535156 11.546875 54.601562 11.394531 C 54.664062 11.265625 54.707031 11.085938 54.707031 10.910156 L 54.707031 1.777344 C 54.707031 1.621094 54.664062 1.445312 54.601562 1.289062 C 54.535156 1.136719 54.449219 1.007812 54.339844 0.871094 C 54.226562 0.761719 54.074219 0.699219 53.921875 0.628906 C 53.769531 0.566406 53.613281 0.519531 53.460938 0.519531 Z M 9.492188 3.890625 C 10.785156 3.890625 11.835938 4.964844 11.835938 6.265625 C 11.835938 7.542969 10.765625 8.601562 9.492188 8.601562 C 8.199219 8.601562 7.148438 7.546875 7.148438 6.265625 C 7.148438 4.96875 8.199219 3.890625 9.492188 3.890625 Z M 9.492188 3.890625 "
              />
            </svg>
          </div>
          <div className="popup__content">
            <OpenSelector
              className="select select_popup"
              type="link"
              variant="form"
              value={data.current.url}
              values={data.list.map((el) => ({
                name: concatMailbox('', el.domain),
                value: el.url,
              }))}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Popup open={open} onClose={onClose} variant={isError ? 'error' : 'default'}>
      {getBody(isLoading, isError)}
    </Popup>
  );
};

export default withPopup(HostPopup);
