import React, { useContext, useEffect } from 'react';

import { Feedback, Title, P, Text, Link } from '../../../parts/Document/Document';
import PopupStyleContext from '../../../../context/PopupStyleContext';
import PopupContext from '../../../../context/PopupContext';
import routeConfig from '../../../../config/routes.config';
import withPreventDefault from '../../../../utils/withPreventDefault';

const Success = (props) => {
  const { onSuccess } = props;
  const { setVariant } = useContext(PopupStyleContext);
  const { openAuthorization } = useContext(PopupContext);

  useEffect(() => {
    setVariant('success');
    return () => setVariant('default');
  }, []);

  return (
    <Feedback>
      <Title popUpTitle className="title-text">
        Password recovery successfully completed
      </Title>
      <P>
        <Text>Try to login.</Text>
      </P>
      <P className="feedback__action-bar">
        <Link
          className="btn"
          to={routeConfig.login.path}
          onClick={withPreventDefault(openAuthorization)}
        >
          Go to login page.
        </Link>
      </P>
    </Feedback>
  );
};

export default Success;
