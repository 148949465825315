import React from 'react';

import withAccountId from '../../../hoc/withAccountId';
import withScrollToTop from '../../../hoc/withScrollToTop';
import { PagePaper, FormWrapper } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import ResendEmailConfirmation from '../../forms/ResendEmailConfirmation/ResendEmailConfirmation';

import routeConfig from '../../../config/routes.config';

export const config = { path: routeConfig.resendConfirmation.path };

const ResendConfirmation = (props) => {
  return (
    <PagePaper className="main">
      <PageToolbar />
      <FormWrapper>
        <ResendEmailConfirmation />
      </FormWrapper>
    </PagePaper>
  );
};

export default withScrollToTop(withAccountId(ResendConfirmation));
