import React from 'react';
import classnames from 'classnames';

import { Text } from '../Document/Document';

const HostLabel = (props) => {
  const { className } = props;

  return (
    <div className={classnames('host', className)}>
      <Text className="host__title">Current host</Text>
      <Text className="host__domain">{window.location.host}</Text>
    </div>
  );
};

export default HostLabel;
