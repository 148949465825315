import React, { useEffect, useState } from 'react'
import MailService from '../../../../api/services/MailService';

const Badge = ({ name, notification, mailbox }) => {

    const [unread, setUnread] = useState(0);
    const { data } = MailService.useGetPreviews(
        name,
        100,
        0
    );

    useEffect(() => {
        let total = 0;
        if (data && data.hasOwnProperty('list') && data.list.length > 0) {
            data.list.forEach(item => {
                if (!item.read) total = total + 1;
            })
            setUnread(total);
        }else {
            setUnread(0);
        }

    }, [data])

    const [itemLength , setItemLength ] = useState(0);

    useEffect(() => {
        
        if(notification.length > 0 && notification.length > itemLength && 
            notification[0]['title'].includes(`New mail on ${mailbox}`)) {
            setUnread(prev => prev + 1)
        }
        setItemLength(notification.length);
    }, [notification])


    return (<>
        {unread !== 0 && <span className='badge-notification'> {unread} </span>}
    </>

    )
}

export default Badge;

