import React from 'react';

import { PagePaper, FormWrapper } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import CompleteRegistration from '../../forms/CompleteRegistration/CompleteRegistration';
import withAuthorization from '../../../hoc/withAuthorization';
import withScrollToTop from '../../../hoc/withScrollToTop';

import routeConfig from '../../../config/routes.config';

export const config = { path: `${routeConfig.emailConfirm.path}/:id` };

const EmailConfirmation = (props) => {
  return (
    <PagePaper>
      <PageToolbar />
      <FormWrapper>
        <CompleteRegistration />
      </FormWrapper>
    </PagePaper>
  );
};

export default withAuthorization(false)(withScrollToTop(EmailConfirmation));
