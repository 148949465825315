import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import AuthorizationService from '../../../api/services/AuthorizationService';
import Success from './Success/Success';
import Error from './Error/Error';
import Form from './Form/Form';
import { Loader } from '../../utils';

const CompletePasswordRecovery = (props) => {
  const { onSuccess = () => {} } = props;

  const { id } = useParams();
  const [recovered, setRecovered] = useState(false);
  const { isLoading, isError } = AuthorizationService.useConfirmRecoveryPassword(id);

  if (isLoading) return <Loader />;
  if (recovered) return <Success onSuccess={onSuccess} />;
  if (isError) return <Error onSuccess={onSuccess} />;
  return <Form recoverPasswordKey={id} onSuccess={() => setRecovered(true)} />;
};

export default CompletePasswordRecovery;
