import React, { useState } from 'react';

import Validator from '../../../../libs/Validator';
import AuthorizationService from '../../../../api/services/AuthorizationService';

import Form, { InputField, FieldGroup, Submit, ErrorMessage } from '../../../parts/Form/Form';
import { Title } from '../../../parts/Document/Document';

const validator = new Validator({
  password: Validator.Presets.password,
  rePassword: Validator.Presets.repeatedPassword(),
});

const FormComponent = (props) => {
  const { recoverPasswordKey, onSuccess = () => {} } = props;

  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(null);
  const [rePassword, setRePassword] = useState('');
  const [rePasswordValid, setRePasswordValid] = useState(null);

  const [resetPassword, { isLoading, isError, error }] = AuthorizationService.useResetPassword({
    onSuccess,
  });

  const validate = () => {
    const { error } = validator.validate({ password, rePassword });

    if (error) {
      setPasswordValid(error.password);
      setRePasswordValid(error.rePassword);
      return false;
    } else {
      setPasswordValid(null);
      setRePasswordValid(null);
      return true;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) resetPassword({ recoverPasswordKey, password });
  };

  return (
    <>
      <Title popUpTitle className="title-text">
        Complete password recovery
      </Title>

      <div className="popup__content">
        <Form onSubmit={submitHandler} loading={isLoading}>
          <FieldGroup>
            <InputField
              label="New Password:"
              type="password"
              placeholder="Please enter new password"
              name="password"
              value={password}
              onChange={setPassword}
              valid={!passwordValid}
              errorMessage={passwordValid}
            />
            <InputField
              label="Repeat New Password:"
              type="password"
              placeholder="Please repeat new password"
              name="rePassword"
              value={rePassword}
              onChange={setRePassword}
              valid={!rePasswordValid}
              errorMessage={rePasswordValid}
            />
          </FieldGroup>

          {isError && <ErrorMessage>{error.message}</ErrorMessage>}

          <div className="form-actions">
            <div className="form-actions__item">
              <Submit wide>Reset password</Submit>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default FormComponent;
