import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './toolbar.module.scss';

const variants = {
  auto: styles['auto'],
  top: styles['top'],
  bottom: styles['bottom'],
};

const Toolbar = (props) => {
  const { variant = 'auto', className, children, ...rest } = props;
  return (
    <div className={classnames(styles['toolbar'], variants[variant], className)} {...rest}>
      {children}
    </div>
  );
};

Toolbar.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Toolbar;
