import React from 'react';
import PropTypes from 'prop-types';

const P = (props) => {
  const { children, ...rest } = props;
  return <p {...rest}>{children}</p>;
};

P.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default P;
