import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import TranslationService from '../../../../api/services/TranslationService';

const Title = (props) => {
  const { children, className, popUpTitle = false, ...rest } = props;
  const { t } = TranslationService.useTranslation();

  const body = (
    <span className={classnames('h1', className)} {...rest}>
      {<Trans t={t}>{children}</Trans>}
    </span>
  );

  if (popUpTitle) return <div className="popup__title">{body}</div>;
  return body;
};

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
