import urlJoin from 'url-join';

import TranslationService from '../services/TranslationService';
import Connector from './Connector';

class AuthConnector extends Connector {
  constructor(baseURL, { base, paths }) {
    super(urlJoin(baseURL + base));

    this.paths = {
      createSession: paths.createSession ?? '',
      refreshSession: paths.refreshSession ?? '',
      getUser: paths.getUser ?? '',
      verifyEmail: paths.verifyEmail ?? '',
      reverifyEmail: paths.reverifyEmail ?? '',
      confirmEmail: paths.confirmEmail ?? '',
      register: paths.register ?? '',
      login: paths.login ?? '',
      passwordRecovery: paths.passwordRecovery ?? '',
      confirmPasswordRecovery: paths.confirmPasswordRecovery ?? '',
      resetPassword: paths.resetPassword ?? '',
      identityTransfer: paths.identityTransfer ?? '',
      authTransfer: paths.authTransfer ?? '',
      setPassword: paths.setPassword ?? '',
      deleteAccount: paths.deleteAccount ?? '',
    };
  }

  static getFingerprintHeader(fingerprint, headers = {}) {
    return {
      headers: { ...headers, [process.env.REACT_APP_FINGERPRINT_HEADER]: fingerprint },
    };
  }

  static getLanguageHeader(lng, headers = {}) {
    return {
      headers: { ...headers, [process.env.REACT_APP_LANGUAGE_HEADER]: lng },
    };
  }

  async createSession(fingerprint) {
    return super.get(
      this.paths.createSession,
      undefined,
      AuthConnector.getFingerprintHeader(fingerprint)
    );
  }

  async refreshSession(refreshToken, fingerprint, accountUid) {
    const data = { refreshToken, accountUid };
    return super.unauthorize().post(this.paths.refreshSession, data, {
      ...AuthConnector.getFingerprintHeader(fingerprint),
    });
  }

  async getUser() {
    return super.authorize().get(this.paths.getUser);
  }

  async verifyUserEmail(email) {
    return super.post(
      this.paths.verifyEmail,
      { email },
      { ...AuthConnector.getLanguageHeader(TranslationService.getCurrentLanguage()) }
    );
  }

  async reverifyUserEmail(email) {
    return super.post(
      this.paths.reverifyEmail,
      { email },
      { ...AuthConnector.getLanguageHeader(TranslationService.getCurrentLanguage()) }
    );
  }

  async confirmUserEmailByToken(token) {
    return super.get(urlJoin(this.paths.confirmEmail, token));
  }

  async completeRegistration(activationKey, password) {
    return super.post(this.paths.register, { activationKey, password });
  }

  async login(fingerprint, email, password) {
    return super.post(
      this.paths.login,
      { email, password },
      { ...AuthConnector.getFingerprintHeader(fingerprint) }
    );
  }

  async recoverPassword(email) {
    return super.post(
      this.paths.passwordRecovery,
      { email },
      { ...AuthConnector.getLanguageHeader(TranslationService.getCurrentLanguage()) }
    );
  }

  async confirmRecoverPassword(recoverPasswordKey) {
    return super.get(urlJoin(this.paths.confirmPasswordRecovery, recoverPasswordKey));
  }

  async resetPassword(recoverPasswordKey, password) {
    return super.post(this.paths.resetPassword, { recoverPasswordKey, password });
  }

  async createIdentityTransfer(domain, mailbox) {
    return super.post(this.paths.identityTransfer, { domain, mailbox });
  }

  async verifyIdentityTransfer(transferKey, fingerprint) {
    return super.get(urlJoin(this.paths.identityTransfer, transferKey), undefined, {
      ...AuthConnector.getFingerprintHeader(fingerprint),
    });
  }

  async createAuthTransfer() {
    return super.get(this.paths.authTransfer);
  }

  async confirmAuthTransfer(transferKey, fingerprint) {
    return super.get(urlJoin(this.paths.authTransfer, transferKey), undefined, {
      ...AuthConnector.getFingerprintHeader(fingerprint),
    });
  }

  async setNewPassword(newPassword) {
    return super.post(this.paths.setPassword, { newPassword });
  }

  async deleteAccount() {
    return super.get(this.paths.deleteAccount);
  }
}

export default AuthConnector;
