import React, { useState } from 'react';
import classnames from 'classnames';
import copyToClipboard from 'copy-to-clipboard';

import { Tooltip } from '../../../../utils';
import ConfirmationPopup from '../../../../popups/ConfirmationPopup/ConfirmationPopup';

import { ReactComponent as CopyIcon } from '../../../../../assets/svg/copy.svg';
import { ReactComponent as RefreshIcon } from '../../../../../assets/svg/refresh.svg';
import { ReactComponent as CancelIcon } from '../../../../../assets/svg/times.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/svg/check.svg';

const IconGroup = (props) => {
  const {
    value = '',
    className,
    isDisturbed = false,
    onRefresh = () => { },
    onCancel = () => { },
    onSubmit = () => { },
  } = props;
  const [copyElement, setCopyElement] = useState(null);
  const [tooltip, setTooltip] = useState(false);
  const [refreshAlert, setRefreshAlert] = useState(false);

  const copyHandler = () => {
    copyToClipboard(value);
    setTooltip(true);
  };

  const refreshHandler = (answer) => {
    setRefreshAlert(false);
    if (answer) onRefresh();
  };

  return (
    <>
      <div className={classnames('toolbar__actions actions', className)}>
        <div className="actions__list actions-list">
          {isDisturbed ? (
            <>
              <CheckIcon className="ico actions__item check-icon" onClick={onSubmit} />
              <CancelIcon className="ico actions__item cancel-icon" onClick={onCancel} />
            </>
          ) : (
            <>
              <CopyIcon
                className="actions__item actions-item_copy"
                onClick={copyHandler}
                ref={setCopyElement}
              />
              <RefreshIcon
                className="actions__item actions-item_refresh"
                onClick={() => setRefreshAlert(true)}
              />
            </>
          )}
        </div>
      </div>

      <Tooltip
        innerRef={copyElement}
        message="Copied to clipboard"
        timeout={1000}
        enabled={tooltip}
        onClose={() => setTooltip(false)}
      />

      <ConfirmationPopup
        open={refreshAlert}
        title="Are you sure you want to refresh current mailbox?"
        message="Once refreshed, all mails would be deleted."
        onClose={refreshHandler}
      />
    </>
  );
};

export default IconGroup;
