import React from 'react';
import classnames from 'classnames';
import {useSelector} from 'react-redux';

import { Text, Title, P } from '../../parts/Document/Document';
import { PagePaper } from '../../utils';

import './error-page.scss';

const ErrorPage = (props) => {
  const {errorTitle, errorText} = useSelector(state => state.app);

  const {
    fixed = false,
    title = errorTitle,
    message = errorText,
    subMessage = 'If the problem persists, please contact us.',
  } = props;

  return (
    <div className={classnames('error-page', { ['fixed']: fixed })}>
      <PagePaper className="error-page__container">
        <Title className="error-page__title">{title}</Title>
        <P>
          <Text>{message}</Text>
        </P>
        <P>
          <Text>{subMessage}</Text>
        </P>
      </PagePaper>
    </div>
  );
};

export default ErrorPage;
