import React from 'react';

const PopupContext = React.createContext({
  useOpen: () => {
    return ({ content, variant, bodySize, open, onClose, unclosable }) => {
      return {
        close: () => {},
        update: ({ content, variant, bodySize, open, onClose, unclosable }) => {},
      };
    };
  },

  openRegistration: () => {},
  openAuthorization: () => {},
  openRecoverPassword: () => {},
  openResendEmailConfirmation: () => {},
});

export default PopupContext;
