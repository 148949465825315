export const ACTIONS = {
  SET_TIMER: 'MAILBOX::SET_TIMER',
};

/**
 * @typedef {Object} MailboxInfo
 * @property {string} name
 * @property {string} expirationTime
 */
/**
 * @typedef {Object} MailboxStoreContents
 * @property {MailboxInfo[]} mailboxes
 */

/** @type {MailboxStoreContents} */
const initialState = {
  mailboxes: [],
};

const appendTime = (name, expirationTime, state) => {
  let newMailbox;
  let index = state.mailboxes.findIndex((mb) => mb.name === name);
  let result;

  if (index === -1) newMailbox = { name };
  else newMailbox = { ...state.mailboxes[index] };

  newMailbox.expirationTime = expirationTime;
  result = [...state.mailboxes];
  result[Math.max(index, 0)] = newMailbox;

  return result;
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_TIMER:
      return {
        ...state,
        mailboxes: appendTime(action.name, action.expirationTime, state),
      };
    default:
      return state;
  }
};

export default authReducer;
