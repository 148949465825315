import axios from 'axios';
import store from '../storage';
import AppService from '../services/AppService';
import AuthorizationService from '../services/AuthorizationService';
import NotificationService from '../services/NotificationService';
import Debug from '../../utils/debug';

const debug = Debug.extend('connector');

/** @typedef {import('axios').AxiosResponse} AxiosResponse */
/** @typedef {import('axios').AxiosRequestConfig} AxiosConfig */

/**
 * @typedef {Object} ConnectorErrorHandlerPayload
 * @property {string} method
 * @property {string} path
 * @property {AxiosConfig} config
 */
/**
 * @callback ConnectorCustomErrorHandler
 * @param {AxiosResponse} response
 * @param {ConnectorErrorHandlerPayload} payload
 * @return {boolean}
 */

class Connector {
  static authorizationToken = null;
  static depth = 5;
  static customErrorHandlers = new Map();

  constructor(baseURL, config = {}) {
    this.baseURL = baseURL;
    this.config = config;
  }

  static authorize(token) {
    Connector.authorizationToken = token;
  }

  static unauthorize() {
    Connector.authorizationToken = null;
  }

  authorize() {
    const conn = new Connector(this.baseURL, this.config);
    conn.axios = axios.create({
      baseURL: this.baseURL,
      ...this.config,
      headers: {
        ...(this.config.headers || {}),
        Authorization: Connector.authorizationToken,
      },
    });

    return conn;
  }

  unauthorize() {
    const conn = new Connector(this.baseURL, this.config);
    const headers = { ...(this.config.headers || {}) };
    delete headers['Authorization'];
    conn.axios = axios.create({
      baseURL: this.baseURL,
      ...this.config,
      headers,
    });

    return conn;
  }

  /**
   * @private
   */
  get Axios() {
    if (this.axios) return this.axios;
    if (Connector.authorizationToken) return this.authorize().axios;
    return axios.create({ ...this.config, baseURL: this.baseURL });
  }

  /**
   * @private
   */
  async query(path, method, config = {}, depth = 1) {
    // Prevent access to backend in a case of fatal error
    const state = /** @type {AppState} */store.getState();
    if (state.app.fatalError) {
      throw new Error('Application is in "fatal error" state, access to backend is disabled');
    }
    try {
      const response = await this.Axios.request({
        url: path,
        method,
        ...config,
      });

      return response.data.result;
    } catch (error) {
      if (error.response) return this.errorHandler(error.response, {method, path, config}, depth);
      throw error;
    }
  }

  async get(path, params, config) {
    return this.query(path, 'GET', { ...config, params });
  }

  async post(path, data, config) {
    return this.query(path, 'POST', { ...config, data });
  }

  /**
   * @private
   */
  async errorHandler(response, payload, depth) {
    const error = new Error('Error occurred during request to the application backend');
    error.method = payload.method;
    error.path = payload.path;
    error.config = payload.config;
    if (response) {
      error.response = response;
      error.status = response.status;
      if (typeof response.data === 'object' && typeof response.data.error === 'string') {
        error.message = response.data.error;
      }
    }

    if (depth >= Connector.depth) throw error;

    if (Connector.customErrorHandlers.size) {
      let useDefault = true;
      for (let handler of Connector.customErrorHandlers.keys()) {
        const r = handler(response, payload);
        if (r === false) {
          useDefault = false;
        }
      }
      if (!useDefault) {
        return;
      }
    }

    if (!response) {
      NotificationService.error('Error occurred while requesting to the server');
      throw error;
    }

    switch (response.status) {
      case 401:
        debug(`Error Handler: trying to handle request error; code: 401, source: '%O'`, response);
        await AuthorizationService.establishSession();
        return this.query(payload.path, payload.method, payload.config, depth + 1);
      case 509:
        debug(`Error Handler: trying to handle request error; code: 509, source: '%O'`, response);
        AppService.handleServerExpiration();
        throw error;
      default:
        break;
    }

    throw error;
  }

  /**
   * Add given custom error handler for the request
   *
   * @param {ConnectorCustomErrorHandler} handler
   * @return {this}
   */
  withErrorHandler(handler) {
    if (!Connector.customErrorHandlers.has(handler)) {
      Connector.customErrorHandlers.set(handler, 0);
    }
    Connector.customErrorHandlers.set(handler, Connector.customErrorHandlers.get(handler) + 1);
    return this;
  }

  /**
   * Remove given custom error handler
   *
   * @param {ConnectorCustomErrorHandler} handler
   * @return {this}
   */
  withoutErrorHandler(handler) {
    if (!Connector.customErrorHandlers.has(handler)) {
      return this;
    }
    Connector.customErrorHandlers.set(handler, Connector.customErrorHandlers.get(handler) - 1);
    if (Connector.customErrorHandlers.get(handler) <= 0) {
      Connector.customErrorHandlers.delete(handler);
    }
    return this;
  }
}

export default Connector;
