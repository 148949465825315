import React, { useState } from 'react';
import classnames from 'classnames';
import QRCode from 'qrcode.react';
import copyToClipboard from 'copy-to-clipboard';

import { Text } from '../../parts/Document/Document';
import { Input, Tooltip } from '../../utils';

import { ReactComponent as Copy } from '../../../assets/svg/copy.svg';

export const viewType = {
  text: Symbol.for('text'),
  qr: Symbol.for('qr'),
};

const ViewField = (props) => {
  const { label, type, className, inputClassName, value, ...rest } = props;
  const [copyElement, setCopyElement] = useState(null);
  const [tooltip, setTooltip] = useState(false);

  const copyHandler = () => {
    copyToClipboard(value);
    setTooltip(true);
  };

  const closeTooltipHandler = () => {
    setTooltip(false);
  };

  const textView = (
    <>
      <div className="form-field form-field_with-ico small-ico">
        <Input
          type="text"
          readOnly
          className={classnames('form-field__input', inputClassName)}
          value={value}
          {...rest}
        />
        <div className="form-field__ico" ref={setCopyElement}>
          <Copy className="ico" onClick={copyHandler} />
        </div>
      </div>
      <Tooltip
        innerRef={copyElement}
        message="Copied to clipboard"
        timeout={1000}
        enabled={tooltip}
        onClose={closeTooltipHandler}
      />
    </>
  );

  const qrView = (
    <QRCode className="qrCode" value={value} size={200} bgColor="#fff" fgColor="#000" />
  );
  const getView = (type) => {
    const t = viewType[type] ?? viewType.text;

    switch (t) {
      case viewType.text:
        return textView;
      case viewType.qr:
        return qrView;
      default:
        return textView;
    }
  };

  return (
    <div className={classnames(className)}>
      <div className="form-field">
        <Text size="xs" color="grey" className="form-field__title">
          {label}
        </Text>
        {getView(type)}
      </div>
    </div>
  );
};

export default ViewField;
