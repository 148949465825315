import React from 'react';

import IconGroup from '../../IconGroup/IconGroup';

import { ReactComponent as DownIcon } from '../../../../../../assets/svg/chevron-down.svg';
import { ReactComponent as UpIcon } from '../../../../../../assets/svg/chevron-up.svg';
import { ReactComponent as CancelIcon } from '../../../../../../assets/svg/close.svg';

const Icons = (props) => {
  const {
    fullValue = '',
    isDisturbed = false,
    withSelect = false,
    open = false,
    onRefresh = () => {},
    onOpen = () => {},
    onClose = () => {},
    onCancel = () => {},
    onSubmit = () => {},
    iconRef,
  } = props;

  const getFieldIcon = () => {
    if (isDisturbed) {
      return <CancelIcon className="ico actions__item" onClick={onCancel} />;
    } else if (withSelect) {
      if (open) {
        return <UpIcon className="ico actions__item" onClick={onClose} />;
      } else {
        return <DownIcon className="ico actions__item" onClick={onOpen} />;
      }
    }
  };

  return (
    <div className="mailbox-field__actions actions" ref={iconRef}>
      <IconGroup
        className="reverse"
        value={fullValue}
        isDisturbed={isDisturbed}
        onRefresh={onRefresh}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default Icons;
