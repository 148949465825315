import React, { useEffect, useState } from 'react';
import Validator from '../../../../libs/Validator';
import AuthorizationService from '../../../../api/services/AuthorizationService';

import Form, { InputField, Submit, ErrorMessage, Message } from '../../../parts/Form/Form';

const validator = new Validator({
  newPassword: Validator.Presets.password,
  reNewPassword: Validator.Presets.repeatedPassword('newPassword'),
});

const ChangePassword = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordValid, setNewPasswordValid] = useState(null);
  const [reNewPassword, setReNewPassword] = useState('');
  const [reNewPasswordValid, setReNewPasswordValid] = useState(null);

  const [
    resetPassword,
    { isLoading, isSuccess, isError, error },
  ] = AuthorizationService.useSetNewPassword();

  const validate = () => {
    const { error } = validator.validate({ newPassword, reNewPassword });

    if (error) {
      setNewPasswordValid(error.newPassword);
      setReNewPasswordValid(error.reNewPassword);
      return false;
    } else {
      setNewPasswordValid(null);
      setReNewPasswordValid(null);
      return true;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) resetPassword({ newPassword });
  };

  useEffect(() => {
    if (isLoading === false && isSuccess === true) {
      setNewPassword('');
      setReNewPassword('');
    }
  }, [isSuccess, isLoading]);

  return (
    <Form onSubmit={submitHandler} loading={isLoading}>
      <div className="row row_tiny">
        <div className="col col_5 col_tab-6 col_mob-12">
          <InputField
            label="New Password:"
            type="password"
            name="password"
            placeholder="Please enter new password"
            value={newPassword}
            onChange={setNewPassword}
            valid={!newPasswordValid}
            errorMessage="Password should be at least 3 symbols long"
          />
        </div>
        <div className="col col_5 col_tab-6 col_mob-12">
          <InputField
            label="Repeat New Password:"
            type="password"
            name="rePassword"
            placeholder="Please repeat new password"
            value={reNewPassword}
            onChange={setReNewPassword}
            valid={!reNewPasswordValid}
            errorMessage="Passwords not matching"
          />
        </div>

        <div className="col col_2 col_tab-12">
          <div className="form-field form-field_secondary-margin">
            <Submit className="btn_submit">Save</Submit>
          </div>
        </div>
      </div>

      {isError && <ErrorMessage>{error.message}</ErrorMessage>}
    </Form>
  );
};

export default ChangePassword;
