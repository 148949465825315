import React, { useRef, useEffect } from 'react';


const StringElement = ({ info }) => {

    const el = useRef(null);

    useEffect(() => {
        if (el.current !== null) {
            el.current.innerHTML = info
        }
    }, [info])
    return <p ref={el} style={{padding:0 }}>
    </p>


}

export default StringElement;