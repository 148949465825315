import React from 'react';
import { motion, AnimatePresence as AP } from 'framer-motion';
import PropTypes from 'prop-types';

const variants = {
  fade: {
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    },
    transition: { type: 'tween', ease: 'linear' },
  },
  popup: {
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
      hidden: { opacity: 0, scale: 0.95 },
      visible: { opacity: 1, scale: 1 },
    },
  },
  'horizontal-slide': {
    initial: 'start',
    animate: 'default',
    exit: 'end',
    variants: {
      start: { opacity: 0, x: '120%' },
      default: { opacity: 1, x: 0 },
      end: { opacity: 0, x: '-120%' },
    },
    transition: { type: 'tween' },
  },
};

const AnimatePresence = (props) => {
  const { open, onClose = () => {}, children, ...rest } = props;

  return (
    <AP onExitComplete={onClose} {...rest}>
      {open && children}
    </AP>
  );
};

AnimatePresence.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

const Animation = (props) => {
  const { duration = 0.2, variant = 'fade', children, ...rest } = props;
  const chosenVariant = variants[variant] ?? {};

  return (
    <motion.div {...chosenVariant} transition={{ ...chosenVariant.transition, duration }} {...rest}>
      {children}
    </motion.div>
  );
};

Animation.propTypes = {
  duration: PropTypes.number,
  variant: PropTypes.oneOf(Object.keys(variants)),
  children: PropTypes.node,
};

export default Animation;
export { AnimatePresence };
