import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { reducer as appReducer, appActions } from './app';
import { reducer as authReducer, authActions } from './auth';
import { reducer as notificationReducer, notificationActions } from './notification';
import { reducer as mailboxReducer, mailboxActions } from './mailbox';

/** @typedef {import('app/reducer').AppStoreContents} AppStoreContents */
/** @typedef {import('auth/reducer').AuthStoreContents} AuthStoreContents */
/** @typedef {import('mailbox/reducer').MailboxStoreContents} MailboxStoreContents */
/** @typedef {import('notification/reducer').NotificationStoreContents} NotificationStoreContents */
/**
 * @typedef {Object} AppState
 * @property {AppStoreContents} app
 * @property {AuthStoreContents} auth
 * @property {MailboxStoreContents} mailbox
 * @property {NotificationStoreContents} notification
 */

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  notification: notificationReducer,
  mailbox: mailboxReducer,
});

export const rootActions = {
  appActions,
  authActions,
  notificationActions,
  mailboxActions,
};

export default createStore(rootReducer, applyMiddleware(thunk));
