import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import Text from '../Text/Text';

import styles from './link.module.scss';

const Link = (props) => {
  const { className, children, to, href, ...rest } = props;
  const { t } = useTranslation();

  if (to) {
    return (
      <RouterLink className={classnames(styles['link'], className)} to={to} {...rest}>
        <Trans t={t}>{children}</Trans>
      </RouterLink>
    );
  } else if (href) {
    return (
      <a className={classnames(styles['link'], className)} href={href} {...rest}>
        <Trans t={t}>{children}</Trans>
      </a>
    );
  }

  return (
    <Text className={className} {...rest}>
      {children}
    </Text>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.any,
};

export default Link;
