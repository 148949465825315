import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import moment from 'moment';

import Debug from '../../utils/debug';
import config from '../../config/translation.config';
import { useEffect, useState } from 'react';

const debug = Debug.extend('service:translation');

class TranslationService {
  constructor() {
    this.resources = this.getResources();
    this.language = null;
  }

  static getCurrentLanguage() {
    return localStorage.getItem(process.env.REACT_APP_LANGUAGE_STORAGE_NAME);
  }

  static setCurrentLanguage(lng) {
    this.language = lng;
    return localStorage.setItem(process.env.REACT_APP_LANGUAGE_STORAGE_NAME, lng);
  }

  static setAttributes(lng) {
    document.documentElement.lang = lng;
    document.documentElement.dir = config.languages[lng].direction ?? 'ltr';
  }

  static setMomentLanguage(lng) {
    try {
      require(`moment/locale/${lng}`);
    } catch (error) {
      debug(`moment localization '%s' not found`, lng);
    }
    moment.locale(lng);
  }

  static availableLanguages() {
    return Object.keys(i18n.services.resourceStore.data);
  }

  static availableLanguage(lng) {
    return TranslationService.availableLanguages().includes(lng);
  }

  get i18n() {
    return i18n;
  }

  getResources() {
    const resources = {};

    for (let lngName in config.languages) {
      const lng = config.languages[lngName];

      try {
        const vocabulary = require(`../../data/strings/${lng.vocabulary}`);
        resources[lng.shortName] = {
          [config.i18n.service.namespace]: vocabulary,
        };
      } catch (error) {
        debug(`module '%s' not found`, lng.vocabulary);
      }
    }

    return resources;
  }

  getLanguages(resources = this.resources) {
    return Object.keys(resources);
  }

  getCurrentLanguage() {
    return i18n.language;
  }

  getSelector() {
    if (!this.resources) return [];
    const result = [];

    for (let lngName in this.resources) {
      const lng = config.languages[lngName];

      result.push({
        name: lng.fullName,
        value: lng.shortName,
      });
    }

    return result;
  }

  init() {
    debug('Init i18n');

    this.language = TranslationService.getCurrentLanguage();

    if (!this.language) {
      this.language = config.i18n.native.lng;
      TranslationService.setCurrentLanguage(this.language);
    }

    TranslationService.setAttributes(this.language);
    TranslationService.setMomentLanguage(this.language);
    i18n.use(initReactI18next).init({
      ...config.i18n.native,
      languages: this.getLanguages(),
      lng: this.language,
      resources: this.resources,
    });
  }

  changeLanguage(lng) {
    if (!TranslationService.availableLanguage(lng)) return;
    TranslationService.setCurrentLanguage(lng);
    TranslationService.setAttributes(lng);
    TranslationService.setMomentLanguage(lng);
    i18n.changeLanguage(lng);
  }

  useTranslation() {
    return useTranslation();
  }

  useMoment() {
    const { t } = useTranslation();
    const [localMoment, setMoment] = useState(() => moment);

    useEffect(() => {
      setMoment(() => moment);
    }, [t]);

    return localMoment;
  }

  useChangeLanguage() {
    const { i18n } = useTranslation();

    return (lng) => {
      if (!TranslationService.availableLanguage(lng)) return;
      TranslationService.setCurrentLanguage(lng);
      TranslationService.setAttributes(lng);
      TranslationService.setMomentLanguage(lng);
      i18n.changeLanguage(lng);
    };
  }
}

export default new TranslationService();
