import React, { useEffect } from 'react';
import classnames from 'classnames';

const Selector = (props) => {
  const { values = [], value, onClose = () => {}, onSelect = () => {} } = props;

  useEffect(() => {
    const keyPress = (e) => {
      if (e.keyCode === 27) onClose();
    };

    document.addEventListener('keyup', keyPress);

    return () => {
      document.removeEventListener('keyup', keyPress);
    };
  }, []);

  const getValues = (list = []) => {
    return list.map((mb) => {
      const isActive = mb.name === value;
      return (
        <div
          key={mb.name}
          className={classnames('select__item select-item', { ['select__item_current']: isActive })}
          onClick={() => {
            if (!isActive) onSelect(mb);
          }}
        >
          {mb.mailbox}
        </div>
      );
    });
  };

  return <div className="mailbox__select select">{getValues(values)}</div>;
};

export default Selector;
