import React from 'react';

import { PagePaper, FormWrapper } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import AuthorizationForm from '../../forms/Authorization/Authorization';
import withAccountId from '../../../hoc/withAccountId';
import withAuthorization from '../../../hoc/withAuthorization';
import withScrollToTop from '../../../hoc/withScrollToTop';

import routeConfig from '../../../config/routes.config';

export const config = { path: routeConfig.login.path };

const Authorization = (props) => {
  return (
    <PagePaper>
      <PageToolbar />
      <FormWrapper>
        <AuthorizationForm />
      </FormWrapper>
    </PagePaper>
  );
};

export default withAuthorization(false)(withScrollToTop(withAccountId(Authorization)));
