import React from 'react';

import './advertisement.scss';

const Advertisement = (props) => {
  return ''; // @TODO Re-implement ads at a later stage

  const { withWrapper = false } = props;

  const getWrapper = (body) => {
    return <div className="advertisement-wrap">{body}</div>;
  };

  const body = (
    <div className="advertisement">
      <span className={'advertisement__text'}>AD BLOCK</span>
    </div>
  );

  if (withWrapper) return getWrapper(body);
  return body;
};
export default Advertisement;
