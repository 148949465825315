import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Feedback = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={classnames('wysiwyg', className)} {...rest}>
      {children}
    </div>
  );
};

Feedback.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Feedback;
