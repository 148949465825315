import React from 'react';
import {useSelector} from 'react-redux';

import AuthorizationService from '../../../../api/services/AuthorizationService';
import {Text, Link} from '../../Document/Document';
import {Button} from '../../../utils';

import {ReactComponent as ShortLogo} from '../../../../assets/svg/logo-short.svg';
import routeConfig from '../../../../config/routes.config';
import './footer.scss';

const Footer = () => {
    const {isRegistered} = useSelector((state) => state.auth);
    const logout = AuthorizationService.useLogout();

    return (
        <footer className="site-footer">
            <div className="container container_secondary">
                <div className="site-footer__top">
                    <div className="site-footer__logo logo">
                        <ShortLogo className="logo__ico"/>
                        <Text size="xs" className="logo__text">
                            Copyright <a href="#">MAIL1.io</a><br/>
                            All Rights Reserved
                        </Text>
                    </div>

                    <div className="site-footer__nav site-footer__nav_main">

                        <ul className="nav__list">

                            {isRegistered ? (
                                <>
                                    <li className="nav__item">
                                        <Link className="nav__link" to={routeConfig.account.path}>
                                            Account
                                        </Link>
                                    </li>
                                    <li className="nav__item">
                                        <Button className="nav__link btn_link" onClick={logout}>
                                            Logout
                                        </Button>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="nav__item">
                                        <Link className="nav__link" to={routeConfig.register.path}>
                                            Register
                                        </Link>
                                    </li>
                                    <li className="nav__item">
                                        <Link className="nav__link" to={routeConfig.login.path}>
                                            Login
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>

                        <ul className="nav__list">
                            {/* <li className="nav__item">
                                <Link className="nav__link" to={routeConfig.terms.path}>
                                    Terms of Service
                                </Link>
                            </li> */}
                            <li className="nav__item">
                                <Link className="nav__link" to={routeConfig.pp.path}>
                                    Privacy Policy
                                </Link>
                            </li>
                            <li className="nav__item">
                                <Link className="nav__link" to={routeConfig.contacts.path}>
                                    Contact Us
                                </Link>
                            </li>
                        </ul>


                        <ul className="nav__list">
                            <li className="nav__item">
                                <Link className="nav__link" to={routeConfig.faq.path}>
                                    Frequently Asked Questions
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
              <div className="site-footer__bottom">
                <div className="site-footer__support support">
			        Service of <a href="https://def24.com">DEF24.com</a>
                </div>
              </div>
            </div>
        </footer>
    );
};

export default Footer;
