import React, { useEffect, useState } from 'react';
import withAccountId from '../../../hoc/withAccountId';
import withAuthorization from '../../../hoc/withAuthorization';
import withScrollToTop from '../../../hoc/withScrollToTop';

import { PagePaper } from '../../utils';
import { Container, Title } from '../../parts/Document/Document';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';

import routeConfig from '../../../config/routes.config';
import faqData from './faqData';
import FaqItem from './FaqItem';
import './faq.scss';

export const config = { path: routeConfig.faq.path };

const Faq = () => {

  const [data, setData] = useState([])

  useEffect(() => {
    const dat = faqData.map(item => {
      item.faqs.map(alt => {
        return Object.assign(alt, {check: true})
      })
      return item
    })
    setData(dat);
  }, [])

  const setAccordion = (id) => {
    const updateData = data.map(item => {
      item.faqs.map(alt => {
        if (alt.id === id) {
          alt.check = !alt.check
          return alt;
        } else {
          return alt;
        }
      })
      return item;
    })
    setData(updateData)
  }

  return (
    <>
      <PagePaper className="main">
        <PageToolbar backLink={routeConfig.home.path}
          backLinkText="Back to Inbox" />
        <div className="main__body main__body_secondary main__body_remove-padding">
          <Container className="main__title">
            <Title className="title-text">Frequently Asked Questions</Title>
          </Container>
          <Container className="main__content">
            <div className="main__section main__section_contact">
              <div className="main__sub-section">
                {data.map(cat => (
                  <div key={cat.category}>
                    <h2 className="p-header" >{cat.category}</h2>
                    <div className="accordion">
                      {cat.faqs.map(item => (
                        <FaqItem
                          key={item.id}
                          a={item.a}
                          q={item.q}
                          id={item.id}
                          check={item.check}
                          setAccordion={setAccordion} />
                      ))}
                    </div>
                  </div>))}
              </div>
            </div>
          </Container>
        </div>
      </PagePaper>
    </>
  );
};

export default withScrollToTop(Faq);