import React, { useEffect, useState } from 'react';
import urlJoin from 'url-join';

import AuthorizationService from '../../../api/services/AuthorizationService';
import MailboxService from '../../../api/services/MailboxService';
import withAccountId from '../../../hoc/withAccountId';
import withAuthorization from '../../../hoc/withAuthorization';
import withScrollToTop from '../../../hoc/withScrollToTop';

import ChangePassword from './ChangePassword/ChangePassword';
import Mailboxes from './Mailboxes/Mailboxes';
import { Button, Loader, PagePaper } from '../../utils';
import { Container, Text, Title } from '../../parts/Document/Document';
import HostLabel from '../../parts/HostLabel/HostLabel';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import ConfirmationPopup from '../../popups/ConfirmationPopup/ConfirmationPopup';

import routeConfig from '../../../config/routes.config';
import { ReactComponent as InboxIcon } from '../../../assets/svg/inbox.s.svg';
import './account.scss';
import ExtensionDownload from '../Home/ExtensionDownload';

export const config = { path: routeConfig.account.path };

const Account = (props) => {
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteAccount, { isLoading }] = AuthorizationService.useDeleteAccount();
  const { data: mailboxList } = MailboxService.useGetAll();
  const [backLink, setBackLink] = useState(null);

  const deleteHandler = (answer) => {
    setDeleteAlert(false);
    if (answer) deleteAccount();
  };

  useEffect(() => {
    if (!mailboxList) return;
    for (let i = 0; i < mailboxList.length; i++) {
      if (!mailboxList[i].remote) {
        setBackLink(urlJoin(routeConfig.mailbox.path, mailboxList[i].name));
        return;
      }
    }
  }, [mailboxList]);

  return (
    <>
      <PagePaper className="main">
        <PageToolbar backLink={backLink} backLinkText="Back to Inbox" backLinkIcon={InboxIcon} />

        <div className="main__body main__body_secondary main__body_remove-padding">
          <Container className="main__title">
            <Title className="title-text">Profile</Title>
            <svg
              className="title-ico"
              width="46"
              height="47"
              viewBox="0 0 46 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.8333 18.5163C30.8333 23.0022 27.1978 26.6375 22.7122 26.6375C18.2265 26.6375 14.591 23.002 14.591 18.5163C14.591 14.0326 18.2265 10.3952 22.7122 10.3952C27.1978 10.3952 30.8333 14.0329 30.8333 18.5163Z"
                fill="#6F6F6F"
              />
              <path
                d="M22.7122 0.938477C16.7282 0.938477 10.9908 3.31581 6.75817 7.54625C2.52784 11.7788 0.150391 17.5163 0.150391 23.5002C0.150391 29.4842 2.52772 35.2216 6.75817 39.4542C10.9907 43.6846 16.7282 46.062 22.7122 46.062C28.6961 46.062 34.4336 43.6847 38.6661 39.4542C42.8965 35.2217 45.2739 29.4842 45.2739 23.5002C45.2673 17.5186 42.8877 11.7834 38.6577 7.55471C34.4296 3.32438 28.6944 0.944681 22.7122 0.938477ZM37.9746 36.004C37.0051 32.5029 33.1053 29.685 27.8794 28.4114L27.8816 28.4092C24.9115 30.9694 20.514 30.9694 17.5438 28.4092C12.3154 29.5571 8.41528 32.4698 7.4485 35.9995V36.0017C4.26916 32.1261 2.68498 27.1863 3.01326 22.1871C3.34155 17.1857 5.56025 12.4969 9.21774 9.07086C12.8774 5.64475 17.7004 3.7389 22.7131 3.7389C27.7234 3.7389 32.5489 5.64475 36.2061 9.07086C39.8634 12.497 42.0823 17.1858 42.4106 22.1871C42.7411 27.1864 41.1547 32.1262 37.9754 36.0017L37.9746 36.004Z"
                fill="#6F6F6F"
              />
            </svg>

            <HostLabel />
          </Container>
          <Container className="main__content">
            <div className="main__section main__section_account">
              <div className="main__sub-section main__sub-section_password">
                <div className="sub-section__title">
                  <Title className="title-text h2">Change Password</Title>
                </div>
                <ChangePassword />
              </div>
              <ExtensionDownload home={false}/>

              <div className="main__sub-section main__sub-section_mailboxes">
                <div className="sub-section__title">
                  <Title className="title-text h2">My Mailboxes</Title>
                </div>
                <Mailboxes />
              </div>
              <div className="main__sub-section main__sub-section_delete-account">
                <div className="remove-account__action">
                  <Button
                    variant="delete"
                    onClick={() => setDeleteAlert(true)}
                    className="btn btn_tertiary btn_with-icon btn_delete"
                  >
                    <Text className="btn__text">Delete account</Text>
                    <svg
                      className="btn__ico"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.12182 0.747559C6.80345 0.747559 5.72117 1.83681 5.72117 3.15461V3.95277H1.70979C1.26721 3.95465 0.909753 4.3146 0.911628 4.75718C0.912254 5.19789 1.26908 5.55534 1.70979 5.55722H2.74456C2.65692 5.82765 2.61185 6.11561 2.63314 6.41485L3.20155 14.4064C3.29545 15.7191 4.40535 16.7476 5.72127 16.7476H12.1272C13.4431 16.7476 14.553 15.7184 14.6469 14.4064L15.2216 6.41485C15.2429 6.11499 15.1972 5.82765 15.1089 5.55722H16.1387C16.5794 5.55534 16.9362 5.19789 16.9368 4.75718C16.9387 4.3146 16.5813 3.95465 16.1387 3.95277H12.1273V3.15461C12.1273 1.83625 11.0443 0.747559 9.72666 0.747559H8.12182ZM9.72567 2.35013C10.1776 2.35013 10.5301 2.70256 10.5301 3.15453V3.95269H7.32302V3.15453C7.32302 2.70256 7.66921 2.35013 8.12118 2.35013H9.72567Z"
                        fill="#DD0000"
                      />
                    </svg>
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {isLoading && <Loader variant="absolute" />}
      </PagePaper>

      <ConfirmationPopup
        open={deleteAlert}
        title="Are you sure?"
        message="Once deleted, account cannot be restored."
        onClose={deleteHandler}
      />
    </>
  );
};

export default withAuthorization(true)(withScrollToTop(withAccountId(Account)));
