import React from 'react';

import Header from './Header/Header';
import Footer from './Footer/Footer';

const Page = (props) => {
  const { children } = props;

  return (
    <div className="wrapper">
      <Header />
      <main className="main-wrap">
        <div className="container">{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default Page;
