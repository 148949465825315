import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Container = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={classnames('bottom-separator', className)} {...rest}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Container;
