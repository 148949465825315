import React from 'react';

const FormWrapper = (props) => {
  const { children, ...rest } = props;

  return (
    <div className="main__form-wrapper" {...rest}>
      <div className="main__form">{children}</div>
    </div>
  );
};

export default FormWrapper;
