import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from '../../../utils';
import { Text, Title } from '../../../parts/Document/Document';
import AccountsPopup from '../../../popups/AccountsPopup/AccountsPopup';
import Notification from './Notification/Notification';
import copyToClipboard from 'copy-to-clipboard';



const Header = (props) => {
  const { mailbox, onRefresh = () => { } } = props;
  const [changeAccount, setChangeAccount] = useState(false);
  const { isRegistered } = useSelector((state) => state.auth);

  const [copyElement, setCopyElement] = useState(null);
  const [tooltip, setTooltip] = useState(false);

  const copyHandler = () => {
    copyToClipboard(mailbox);
    setTooltip(true);
  };

  return (
    <>
      <div className="main__title">
        <Title className="title-text">Inbox</Title>
        <svg
          className="title-ico"
          width="40"
          height="30"
          viewBox="0 0 40 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.1689 0H7.82834L0.0380859 14.6974V30H39.962L39.9604 14.6974L32.1689 0ZM30.4768 14.6974L27.4153 19.2217L12.111 19.2233L9.0511 14.6989L5.1646 14.6974L10.832 3.6959H29.1666L34.8324 14.6974H30.4768Z"
            fill="#6F6F6F"
          />
        </svg>
      </div>

      <div className="main__sub-title sub-title inbox__sub-title">
        <Tooltip
          innerRef={copyElement}
          message="Copied to clipboard"
          timeout={1000}
          enabled={tooltip}
          onClose={() => setTooltip(false)}
        />
        <span className="sub-title__text" style={{ cursor: "pointer" }}
          ref={setCopyElement}
          onClick={copyHandler}>{mailbox}</span>
        {isRegistered ? (
          <Button className="btn btn_link" onClick={() => setChangeAccount(true)}>
            Change Account
          </Button>
        ) : (
          <Notification />
        )}
        <Button className="sub-title__refresh-btn btn btn_with-icon btn_link" 
        onClick={onRefresh}>
          <svg
            className="btn__ico"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.20559 16.108C4.54932 15.6126 1.9976 12.2419 2.49237 8.58562C2.98782 4.92935 6.37698 2.37687 10.0148 2.89182C11.272 3.06292 12.3953 3.57771 13.3096 4.32013L10.6813 6.58599L17.8986 8.3379L17.5557 0.720263L15.1186 2.79645C13.8235 1.63446 12.1862 0.834718 10.3389 0.586955C5.42538 -0.0989369 0.873468 3.34835 0.188262 8.26191C-0.49763 13.1755 2.94965 17.7461 7.88169 18.4126C11.291 18.8887 14.5092 17.3838 16.4136 14.7748L14.5665 13.3465C13.1761 15.3267 10.7577 16.4693 8.2054 16.1079L8.20559 16.108Z"
              fill="#3075BF"
            />
          </svg>
          <Text className="btn__text">Refresh</Text>
        </Button>
      </div>

      <AccountsPopup
        open={changeAccount}
        onClose={() => setChangeAccount(false)}
        onSubmit={() => setChangeAccount(false)}
      />
    </>
  );
};

export default Header;
