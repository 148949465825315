import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routeConfig from '../config/routes.config';

const withAuthorization = (
  registered = true,
  authenticated = true,
  redirectLink = routeConfig.home.path
) => {
  return (WrapperComponent) => {
    const WithAuthorization = (props) => {
      const { isRegistered, uid } = useSelector((state) => state.auth);
      const redirect = <Redirect to={redirectLink} />;

      if (authenticated !== null ? authenticated !== !!uid : false) return redirect;
      if (registered !== null ? registered !== isRegistered : false) return redirect;

      return <WrapperComponent {...props} />;
    };

    return WithAuthorization;
  };
};

export default withAuthorization;
