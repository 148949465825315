import { useDispatch } from 'react-redux';
import { ACTIONS } from './reducer';
import Debug from '../../../utils/debug';

const debug = Debug.extend('storage:actions:user');

export const setSession = (established) => {
  debug(`Get in setSession action with established: %s`, established);

  return async (dispatch) => {
    dispatch({
      type: ACTIONS.SET_SESSION,
      established,
    });

    debug(`setSession dispatched`);
  };
};

export const breakSession = () => {
  debug(`Get in breakSession action`);

  return (dispatch) => {
    dispatch({
      type: ACTIONS.BREAK_SESSION,
    });
    debug(`breakSession dispatched`);
  };
};

export const setUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_USER,
      uid: user.uid,
      userName: user.userName,
      isRegistered: user.isRegistered,
    });
  };
};

export const removeUser = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.REMOVE_USER,
    });
  };
};

export const useAuthActions = () => {
  const dispatch = useDispatch();

  return {
    setSession: (established) => dispatch(setSession(established)),
    breakSession: () => dispatch(breakSession()),
    setUser: (user) => dispatch(setUser(user)),
    removeUser: () => dispatch(removeUser()),
  };
};
