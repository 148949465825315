import urlJoin from 'url-join';
import Connector from './Connector';
import Debug from '../../utils/debug';

const debug = Debug.extend('connector:mail');

class MailConnector extends Connector {
  constructor(baseURL, { base, paths }) {
    super(urlJoin(baseURL + base));

    this.paths = {
      getPreviews: paths.getPreviews ?? '',
      getMail: paths.getMail ?? '',
      deleteMail: paths.deleteMail ?? '',
    };
  }

  static mailErrorHandler(error) {}

  async getPreviews(mailboxName, length, offset) {
    debug(
      `Get in getPreviews, mailbox name: '%s', length: '%s', offset: '%s'`,
      mailboxName,
      length,
      offset
    );
    const result = await super.get(this.paths.getPreviews, {
      mailbox: mailboxName,
      length,
      offset,
    });
    debug(`Get out getPreviews with prevues: '%s'`, result.list.length);
    return result;
  }

  async getMail(id) {
    debug(`Get in getMail, mail id: '%s'`, id);
    const result = await super.get(urlJoin(this.paths.getMail, id));
    debug(`Get out getMail with mail: '%s'`, result.mail.uid);
    return result;
  }

  async deleteMail(id) {
    debug(`Get in deleteMail, mail id: '%s'`, id);
    const result = await super.post(this.paths.deleteMail, { uid: id });
    debug(`Get out deleteMail with mail: '%O'`, result);
    return result;
  }
}

export default MailConnector;
