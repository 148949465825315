import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import urlJoin from 'url-join';

import AuthorizationService from '../../../../../api/services/AuthorizationService';
import MailboxService from '../../../../../api/services/MailboxService';

import Info from './Info/Info';
import ConfirmationPopup from '../../../../popups/ConfirmationPopup/ConfirmationPopup';
import { Loader } from '../../../../utils';

import routeConfig from '../../../../../config/routes.config';

import { ReactComponent as Inbox } from '../../../../../assets/svg/inbox.svg';
import { ReactComponent as Trash } from '../../../../../assets/svg/trash.svg';

const Mailbox = (props) => {
  const { data, onDeleted = () => {}, active } = props;
  const [deleteAlert, setDeleteAlert] = useState(false);

  const [getTransfer, { isLoading: transferLoading }] = AuthorizationService.useTransferIdentity();
  const [
    deleteMailbox,
    { isLoading: deleteLoading, isSuccess: deleteSuccess },
  ] = MailboxService.useDeleteMailbox();

  const inboxHandler = () => {
    if (!transferLoading)
      getTransfer({ domain: data.domain_name, mailbox: data.name, newTab: false });
  };

  const inboxAuxHandler = () => {
    if (!transferLoading)
      getTransfer({ domain: data.domain_name, mailbox: data.name, newTab: true });
  };

  const deleteHandler = (answer) => {
    setDeleteAlert(false);
    if (answer) deleteMailbox(data.name);
  };

  const getToolbar = (mailbox) => {
    if (mailbox.remote) {
      return <Inbox className="icon" onClick={inboxHandler} onAuxClick={inboxAuxHandler} />;
    } else {
      return (
        <>
          <Link to={urlJoin(routeConfig.mailbox.path, mailbox.name)} className="action-item">
            <Inbox className="ico" />
          </Link>
          <Trash className="action-item ico warning" onClick={() => setDeleteAlert(true)} />
        </>
      );
    }
  };

  useEffect(() => {
    if (deleteSuccess) onDeleted();
  }, [deleteSuccess]);

  const loading = transferLoading || deleteLoading;

  return (
    <>
      <div className="mailboxes__item mailboxes-item">
        <div
          className={classnames(
            'mailboxes-item__content',
            { ['loading']: loading },
            { ['active']: active }
          )}
        >
          <Info
            mailbox={data.name}
            domain={data.domain_name}
            expirationDate={data.expirationDate}
          />
          <div className="mailboxes-item__action">{getToolbar(data)}</div>
        </div>
        {loading && <Loader variant="absolute" />}
      </div>

      <ConfirmationPopup
        open={deleteAlert}
        title="Are you sure you want to delete this?"
        message="After deletion of this item, you will not have a chance to restore it. Please think twice. If you are sure, please confirm."
        onClose={deleteHandler}
      />
    </>
  );
};

export default Mailbox;
