import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Animation, { AnimatePresence } from '../../../parts/Animation/Animation';
import useDirectClickListener from '../../../../hooks/useDirectClickListener';
import PopupStyleContext from '../../../../context/PopupStyleContext';
import Header from './Header/Header';

import './popup.scss';

export const variants = {
  default: '',
  error: 'error',
  success: 'success',
};

const Popup = (props) => {
  const {
    id,
    open = false,
    content = null,
    variant = 'default',
    onClose = () => {},
    unclosable = false,
  } = props;

  const [chosenVariant, setChosenVariant] = useState(variant);
  const [localOpen, setLocalOpen] = useState(open);
  const [localId, setLocalId] = useState(null);
  const [localContent, setLocalContent] = useState(null);
  const [contentTransition, setContentTransition] = useState(false);

  const localClose = () => {
    if (!unclosable) setLocalOpen(false);
  };

  const closeHandler = useDirectClickListener(localClose);

  const endBodyAnimation = () => {
    setLocalContent(content);
    setContentTransition(false);
  };

  useEffect(() => {
    if (!localId || id === localId) {
      setLocalId(id);
      setLocalContent(content);
    } else {
      setLocalId(id);

      if (content) {
        if (localContent) setContentTransition(true);
        else setLocalContent(content);
      } else {
        setLocalContent(null);
      }
    }
  }, [id, content]);

  // const closeHandler = useCallback((firstEvent) => {
  //   if (firstEvent.currentTarget !== firstEvent.target) return;
  //   const el = firstEvent.currentTarget;

  //   document.addEventListener(
  //     'mouseup',
  //     (secondEvent) => {
  //       if (el === secondEvent.target) return localClose();
  //     },
  //     { once: true }
  //   );
  // }, []);

  useEffect(() => {
    const keyPress = (e) => {
      if (e.keyCode === 27 && !unclosable) localClose();
    };

    setLocalOpen(open);
    if (open === true) document.addEventListener('keyup', keyPress);

    return () => {
      document.removeEventListener('keyup', keyPress);
    };
  }, [open]);

  useEffect(() => {
    if (variant) setChosenVariant(variant);
  }, [variant]);

  return (
    <AnimatePresence open={localOpen} onClose={onClose}>
      <Animation variant="fade" duration={0.125}>
        <PopupStyleContext.Provider
          value={{
            variant: chosenVariant,
            variants,
            setVariant: (variant) => setChosenVariant(variant),
          }}
        >
          <div
            className={classnames('popup-wrap', variants[chosenVariant], {
              ['unclosable']: unclosable,
            })}
            onMouseDown={unclosable ? () => {} : closeHandler}
          >
            <div className="popup">
              <Header onClose={localClose} withoutCloseButton={unclosable} />
              <div className="popup__body">
                {contentTransition ? (
                  <div className="popup__main">{content}</div>
                ) : (
                  <div className="popup__main">{localContent}</div>
                )}
              </div>
            </div>
          </div>
        </PopupStyleContext.Provider>
      </Animation>
    </AnimatePresence>
  );
};

Popup.propTypes = {
  id: PropTypes.any,
  open: PropTypes.bool,
  content: PropTypes.node,
  variant: PropTypes.oneOf(Object.keys(variants)),
  onClose: PropTypes.func,
  bodySize: PropTypes.string,
};

export default Popup;
