import React from 'react';

import { Text } from '../../../../parts/Document/Document';

import { ReactComponent as GuardWarn } from '../../../../../assets/svg/guard-warn.svg';

const MailAlert = (props) => {
  return (
    <div className="mail__alert alert">
      <div className="alert__media">
        <GuardWarn className="icon" />
      </div>
      <div className="alert__content">
        <Text className="alert__title">Spam or virus alert</Text>
        <Text className="alert__text">This message might contain a scam or virus.</Text>{' '}
        <Text className="alert__advice">Please be attentive.</Text>
      </div>
    </div>
  );
};

export default MailAlert;
