import React, { useContext, useState } from 'react';

import Validator from '../../../libs/Validator';
import AuthorizationService from '../../../api/services/AuthorizationService';
import PopupContext from '../../../context/PopupContext';
import withPreventDefault from '../../../utils/withPreventDefault';
import Form, { InputField, FieldGroup, Submit, ErrorMessage } from '../../parts/Form/Form';
import { Title, Link, Text, P, Space } from '../../parts/Document/Document';

import routeConfig from '../../../config/routes.config';

const validator = new Validator({
  email: Validator.Joi.string().email({ tlds: { allow: false } }),
  password: Validator.Joi.string(),
});

const Authorization = (props) => {
  const { onSuccess = () => {} } = props;

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(null);

  const [authorize, { isLoading, isError, error }] = AuthorizationService.useAuthorize({
    onSuccess,
  });
  const { openRecoverPassword, openRegistration } = useContext(PopupContext);

  const validate = () => {
    const { error } = validator.validate({ email, password });

    if (error) {
      setEmailValid(error.email);
      setPasswordValid(error.password);
      return false;
    } else {
      setEmailValid(null);
      setPasswordValid(null);
      return true;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) authorize({ email, password });
  };

  return (
    <>
      <Title popUpTitle className="title-text">
        Login
      </Title>

      <div className="popup__content">
        <Form onSubmit={submitHandler} loading={isLoading}>
          <FieldGroup>
            <InputField
              label="Your E-mail:"
              placeholder="name@domain.com"
              name="email"
              value={email}
              onChange={setEmail}
              valid={!emailValid}
              errorMessage={emailValid}
            />

            <InputField
              label="Your Password:"
              type="password"
              placeholder="Please enter your password"
              name="password"
              value={password}
              onChange={setPassword}
              valid={!passwordValid}
              errorMessage={passwordValid}
            >
              <div className="form-field__link">
                <Link
                  to={routeConfig.recoverPassword.path}
                  onClick={withPreventDefault(openRecoverPassword)}
                >
                  Forgot password?
                </Link>
              </div>
            </InputField>
          </FieldGroup>

          {isError && <ErrorMessage>{error.message}</ErrorMessage>}

          <div className="form-actions">
            <div className="form-actions__item">
              <Submit wide className="btn_wide-mini">
                Enter
              </Submit>
            </div>
            <div className="form-actions__item">
              <P>
                <Text>New user?</Text>
                <Space />
                <Link to={routeConfig.register.path} onClick={withPreventDefault(openRegistration)}>
                  Register!
                </Link>
              </P>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Authorization;
