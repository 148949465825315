import React from 'react';

import withScrollToTop from '../../../hoc/withScrollToTop';

import { PagePaper, Advertisement } from '../../utils';
import PageToolbar from '../../parts/PageToolbar/PageToolbar';
import { Container, Title } from '../../parts/Document/Document';

import routeConfig from '../../../config/routes.config';
import emailsConfig from '../../../config/emails.config';

export const config = { path: routeConfig.pp.path };

const PrivacyPolicy = (props) => {
  return (
    <PagePaper variant="adaptive" className="main">
      <PageToolbar backLink={routeConfig.home.path} backLinkText="Back to Main page" />

      <div className="main__body main__body_secondary">
        <Container class="main__title">
          <Title className="title-text">Privacy Policy</Title>
        </Container>
        <div className="main__content">
          <div className="main__section main__section_terms">
            <div className="row">
              <div className="col col_12 col_tab-12">
                <div className="main__sub-section main__sub-section_secondary wysiwyg">
                  <h2>General Terms</h2>
                  <p>Following Privacy Policy refers to mail1 free to use web site and browser extensions, hereinafter “mail1”, based on url https://mail1.io.</p>
                  <p>Using the temporary mail allows you to completely protect your real mailbox against the loss of personal information. Your temporary e-mail address is completely anonymous towards site vendors. Your details: information about your person and users with whom you communicate, IP-address, e-mail address are protected and completely confidential to site owners.</p>
                  
                  <h2>What does mail1 do?</h2>
                  <p>Our website provides you with a temporary email address that you can use to receive emails and get notified of a new emails received to this inbox. The user may change addresses instantly and copy it to the clipboard. Registered users can keep their mailboxes for 365days and not registered users 24hours. We do not guarantee any uptime nor retaintion of emails, this is a temporary email service.</p>

                  <h2>What information do we collect?</h2>
                  <p>No personal data is collected. Like most standard web site servers we use log files. This includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks to analyze trends, administer the site, track user's movement in the aggregate, and gather broad demographic information for aggregate use, emails subject and the email-id. We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process.However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Sites is at your own risk. You should only access the services within a secure environment.Mail1 does log your IP-address to prevent illegal users. We do retain those logs just for law enforcement reasons.</p>

                  <h2>Cookies</h2>
                  <p>Yes, we do use cookies technology; when the user visiting the website from the browser interface, we do use cookies. Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information. We use cookies to keep track of advertisements and remember certain information and enhance your experience. Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to your website or other websites. Google's use of advertising cookies enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet. Users may opt out of personalized advertising by visiting Ads Settings. (Alternatively, you can direct users to opt out of a third-party vendor's use of cookies for personalized advertising by visiting www.aboutads.info.)</p>
                  <p>If you have not opted out of third-party ad serving, the cookies of other third-party vendors or ad networks may also be used to serve ads, which are disclosed further in section “Third party links”.</p>

                  <h2>Local Storage</h2>
                  <p>Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for storing client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly enhanced capacity and no information stored in the HTTP request header.</p>

                  <h2>Sessions</h2>
                  <p>We use "Sessions" to identify the areas of our website that you have visited. A Session is a small piece of data stored on your computer or mobile device by your web browser.</p>

                  <h2>Do we disclose any information to outside parties?</h2>
                  <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>

                  <h2>Could my information be transferred to other countries?</h2>
                  <p>We are incorporated in U.S.A. Information collected via our website, through direct interactions with you, or from use of our help services may be transferred from time to time to our offices or personnel, or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including countries that may not have laws of general applicability regulating the use and transfer of such data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the trans- border transfer and hosting of such information.</p>

                  <h2>Sale of Business</h2>
                  <p>We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of us or any of its Corporate Affiliates (as defined herein), or that portion of us or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.</p>

                  <h2>How Long Do We Keep Your Information?</h2>
                  <p>We keep your information only so long as we need it to provide service to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we'll either remove it from our systems or depersonalize it so that we can't identify you.</p>

                  <h2>Governing Law</h2>
                  <p>The laws of United States of America, excluding its conflicts of law rules, shall govern this Agreement and your use of our service. Your use of our service may also be subject to other local, state, national, or international laws.</p>

                  <h2>How do we use collected data?</h2>
                  <p>We do use this data to provide and maintain our service, to manage your account, to improve performance, to contact you, to manage your requests and law enforcement reasons.</p>

                  <h2>Third party links</h2>
                  <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our website and welcome any feedback about these sites. </p>

                  <h2>Third Party Ad Companies</h2>
                  <p>We allow third-party companies to serve ads and/or collect certain anonymous information when you work with our website. These companies may use non-personally identifiable information (e.g., click stream information, browser type, time and date, subject of advertisements clicked) during your work with this and other websites in order to provide advertisements about goods and services likely to be of greater interest to you. These companies typically use a cookie or third party web beacon to collect this information. To learn more about this behavioral advertising practice or to opt-out of this type of advertising, you can visit www.networkadvertising.org.</p>

                  <h2>Google AdSense™</h2>
                  <p>Some of the ads may be served by Google. Google’s use of the DART cookie enables it to serve ads to Users based on their visit to our Site and other sites on the Internet. DART uses "non personally identifiable information" and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at https://www.google.com/policies/technologies/ads/</p>

                  <h2>California Privacy Rights</h2>
                  <p>California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their Personal Information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of Personal Information disclosed to those parties. mail1 does not share Personal Information with third parties for their own marketing purposes.</p>

                  <h2>Children`s Privacy</h2>
                  <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>

                  <h2>Law enforcement</h2>
                  <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

                  <h2>Your Consent</h2>
                  <p>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use this Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>

                  <h2>Changes to our Privacy Policy</h2>
                  <p>If we decide to change our Privacy policy, we will post those changes on this page. Since most of our users can use our service without an account; we do not have technical possibility to update your about the changes. </p>

                  <h2>Contact Us</h2>
                  <p>If there are any questions regarding this privacy policy or you wish to unsubscribe from our site and services you may contact us using the information below or by writing to us at: Email: info@mail1.io - DEF24 Inc, 815 Ponce De Leon Blvd Second Floor, 33134 Coral Gables, Florida, U.S.A</p>

                  <p>This policy is effective as of 2/1/2022</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main__footer">
        <Advertisement />
      </div>
    </PagePaper>
  );
};

export default withScrollToTop(PrivacyPolicy);
