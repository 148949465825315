import React, { useContext, useEffect } from 'react';

import { Feedback, Title, Text, P, Link, Space } from '../../../parts/Document/Document';
import PopupStyleContext from '../../../../context/PopupStyleContext';
import PopupContext from '../../../../context/PopupContext';
import routeConfig from '../../../../config/routes.config';
import withPreventDefault from '../../../../utils/withPreventDefault';

const Error = (props) => {
  const { onSuccess } = props;
  const { setVariant } = useContext(PopupStyleContext);
  const { openResendEmailConfirmation, openRegistration } = useContext(PopupContext);

  useEffect(() => {
    setVariant('error');
    return () => setVariant('default');
  }, []);

  return (
    <Feedback>
      <Title popUpTitle className="title-text">Token seems to be expired</Title>
      <P>
        <Text>Try to send another request to your email address.</Text>
      </P>
      <P>
        <Link to={routeConfig.register.path} onClick={withPreventDefault(openRegistration)}>
          Go to registration page
        </Link>
        <Space />
        <Text>or</Text>
        <Space />
        <Link
          to={routeConfig.resendConfirmation.path}
          onClick={withPreventDefault(openResendEmailConfirmation)}
        >
          resend activation mail.
        </Link>
      </P>
    </Feedback>
  );
};

export default Error;
