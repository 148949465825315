import React from 'react';
import classnames from 'classnames';

import { ReactComponent as ArrowLeft } from '../../../../assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/arrow-right.svg';
import './pagination.scss';

function PageButton(props) {
  const { children, value, separator, disabled, active, onClick } = props;
  const body = (
    <div
      className={classnames(
        ['pagination__value'],
        { ['active']: active },
        { ['disabled']: disabled },
        { ['clickable']: !separator && !disabled && !active }
      )}
      onClick={!(active || disabled) ? onClick : undefined}
    >
      {separator ? '...' : value || children}
    </div>
  );

  return body;
}

const PreviousPage = (props) => {
  return (
    <PageButton {...props}>
      <ArrowLeft className="icon" />
    </PageButton>
  );
};

const NextPage = (props) => {
  return (
    <PageButton {...props}>
      <ArrowRight className="icon" />
    </PageButton>
  );
};

const Pagination = (props) => {
  const { visibleRange = 2, currentPage, maxPages, onChange = () => {} } = props;
  if (maxPages < 1) return null;

  const generateList = () => {
    const setPage = (page) => {
      return (
        <PageButton
          key={page}
          active={page === currentPage}
          value={page}
          onClick={onChange.bind(null, page)}
        />
      );
    };
    const minPageInRange = Math.max(currentPage - visibleRange, 2);
    const maxPageInRange = Math.min(currentPage + visibleRange, maxPages - 1);
    let result = [];
    let numGroup = [];

    result.push(
      <PreviousPage
        key="previous"
        disabled={currentPage === 1}
        onClick={onChange.bind(null, currentPage - 1)}
      />
    );
    numGroup.push(setPage(1));

    if (minPageInRange !== 2) numGroup.push(<PageButton key="sep-1" separator />);
    for (let i = minPageInRange; i <= maxPageInRange; i++) numGroup.push(setPage(i));
    if (maxPageInRange !== maxPages - 1) numGroup.push(<PageButton key="sep-2" separator />);
    if (maxPageInRange) numGroup.push(setPage(maxPages));
    if (numGroup.length)
      result.push(
        <div key="num-group" className="pagination__item pagination__item_number">
          {numGroup}
        </div>
      );

    result.push(
      <NextPage
        key="next"
        disabled={currentPage === maxPages}
        onClick={onChange.bind(null, currentPage + 1)}
      />
    );

    return result;
  };

  return <div className="pagination body-wrapper">{generateList()}</div>;
};

export default Pagination;
