import React, { useState } from 'react';

import Validator from '../../../../libs/Validator';
import AuthorizationService from '../../../../api/services/AuthorizationService';

import Form, { InputField, FieldGroup, Submit, ErrorMessage } from '../../../parts/Form/Form';
import { Title } from '../../../parts/Document/Document';

const validator = new Validator({
  email: Validator.Presets.email,
});

const FormComponent = (props) => {
  const { onSuccess = () => {} } = props;

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const [
    resendVerification,
    { isLoading, isError, error },
  ] = AuthorizationService.useResendVerification({
    onSuccess: onSuccess,
  });

  const validate = () => {
    const { error } = validator.validate({ email });

    if (error) {
      setEmailValid(error.email);
      return false;
    } else {
      setEmailValid(null);
      return true;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) resendVerification({ email });
  };

  return (
    <>
      <Title popUpTitle className="title-text">
        Resend confirmation
      </Title>

      <div className="popup__content">
        <Form onSubmit={submitHandler} loading={isLoading}>
          <FieldGroup className="form-fields">
            <InputField
              label="Your E-mail:"
              placeholder="name@domain.com"
              name="email"
              value={email}
              onChange={setEmail}
              valid={!emailValid}
              errorMessage={emailValid}
            />
          </FieldGroup>

          {isError && <ErrorMessage>{error.message}</ErrorMessage>}

          <div className="form-actions">
            <div className="form-actions__item">
              <Submit wide>Resend confirmation email</Submit>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default FormComponent;
