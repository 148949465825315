import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Title from '../Title/Title';

const Placeholder = (props) => {
  const { className, message, ...rest } = props;

  return (
    <div className={classnames('body_placeholder', className)} {...rest}>
      <Title className="title-text">{message}</Title>
      {props.children}
    </div>
  );
};

Placeholder.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default Placeholder;
