import React from 'react';
import { BrowserRouter } from 'react-router-dom';

const withBrowserRouter = (WrappedComponent, basename = '/') => {
  const WithBrowserRouter = (props) => {
    return (
      <BrowserRouter basename={basename}>
        <WrappedComponent {...props} />
      </BrowserRouter>
    );
  };

  return WithBrowserRouter;
};

export default withBrowserRouter;
