import React, { useEffect, useState } from 'react';

import concatMailbox from '../../../utils/concatMailbox';
import MailboxService from '../../../api/services/MailboxService';
import AuthorizationService from '../../../api/services/AuthorizationService';

import { Loader, Popup, OpenSelector } from '../../utils';
import { Title, Text, Placeholder } from '../../parts/Document/Document';
import withPopup from '../../../hoc/withPopup';

const AccountsPopup = (props) => {
  const { open, onClose = () => {} } = props;
  const [crm, setCrm] = useState(null);
  const [aux, setAux] = useState(false);

  const {
    data: getData,
    isLoading: getLoading,
    isError: getError,
  } = MailboxService.useGetAllRemote();

  const [
    getTransfer,
    { isError: transferError, isLoading: transferLoading, isSuccess: transferSuccess },
  ] = AuthorizationService.useTransferIdentity();
  const error = getError || transferError;

  useEffect(() => {
    if (crm) getTransfer({ domain: crm.domain_name, mailbox: crm.name, newTab: aux });
  }, [crm]);

  useEffect(() => {
    if (transferSuccess) onClose();
  }, [transferSuccess]);

  const getBody = () => {
    if (getLoading) return <Loader />;
    if (error)
      return (
        <>
          <Title>Error</Title>
          <div>
            <Text>Error occurred while creating transfer link.</Text>
          </div>
        </>
      );
    if (!getData.length)
      return <Placeholder color="grey" 
      message="You don't have any other accounts yet." />;

    return (
      <Placeholder>
        <div className="popup__accounts">
          <div className="popup__title">
            <span className="title-text h1">Switch account</span>
          </div>
          <div className="popup__content">
            <div className="popup__wysiwyg wysiwyg">
              <OpenSelector className="select select_popup"
                  disabled={transferLoading}
                  variant="form"
                  values={getData.map((el) => ({
                    name: concatMailbox(el.name, el.domain_name),
                    value: el,
                  }))}
                  onSelect={setCrm}
                  onAuxSelect={(v) => {
                    setCrm(v);
                    setAux(true);
                  }}
              />
            </div>
          </div>
        </div>

        {transferLoading && <Loader variant="absolute" />}
      </Placeholder>
    );
  };

  return (
    <Popup open={open} onClose={onClose} variant={error ? 'error' : 'default'}>
      {getBody()}
    </Popup>
  );
};

export default withPopup(AccountsPopup);
