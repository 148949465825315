import { EventEmitter } from 'events';
import urlJoin from 'url-join';
import { io } from 'socket.io-client';

import NotificationService from './NotificationService';
import store, { rootActions } from '../storage';
import pathConfig from '../../config/paths.config';
import Debug from '../../utils/debug';

const debug = Debug.extend('service:socket');
const {
  notificationActions: { add: addNotification },
} = rootActions;

class SocketService extends EventEmitter {
  constructor() {
    super();
    this.socket = null;
  }

  get isConnected() {
    return !!this.socket;
  }

  get Events() {
    return {
      newMail: 'new mail',
    };
  }

  emitNewMail(mailbox) {
    return super.emit(this.Events.newMail, mailbox);
  }

  get subscribe() {
    return {
      onNewMail: (listener) => {
        return super.on(this.Events.newMail, listener);
      },
    };
  }

  get unsubscribe() {
    return {
      offNewMail: (listener) => {
        return super.off(this.Events.newMail, listener);
      },
    };
  }

  connect(bearerToken) {
    debug(`Get in connect with bearer token: %s`, !!bearerToken);
    if (this.isConnected) this.socket.disconnect();

    const config = {
      path: urlJoin(process.env.REACT_APP_BASE_URL_PATH, pathConfig.socket.base),
      extraHeaders: { Authorization: bearerToken },
    };

    this.socket = io.connect(process.env.REACT_APP_NODE_HOST, config);

    this.socket.on('connect', () => {
      debug(`Socket successfully connected with id: '%s'`, this.socket.id);
    });

    this.socket.on(this.Events.newMail, (mailbox) => {
      NotificationService.info(`New mail on ${mailbox.mailbox}`);
      this.emitNewMail(mailbox);
    });

    this.socket.on('connect_error', (error) => {
      debug(`Socket connection error: '%O'`, error);
    });
  }
}
export default new SocketService();
