import store, { rootActions } from '../storage';
import Debug from '../../utils/debug';
import notificationConfig from '../../config/notification-type.json';

const debug = Debug.extend('service:notification');
const {
  notificationActions: { add: addNotification },
} = rootActions;

class NotificationService {
  static push(notification) {
    store.dispatch(addNotification(notification));
  }

  static success(title, message) {
    store.dispatch(addNotification({ title, message, type: notificationConfig.success }));
  }

  static info(title, message) {
    store.dispatch(addNotification({ title, message, type: notificationConfig.info }));
  }

  static warn(title, message, timeout = null) {
    store.dispatch(addNotification({ title, message, timeout, type: notificationConfig.warn }));
  }

  static error(title, message, timeout = null) {
    store.dispatch(addNotification({ title, message, timeout, type: notificationConfig.error }));
  }
}

export default NotificationService;
