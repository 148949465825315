import React, { memo, useEffect } from 'react';
import classnames from 'classnames';

import { Text } from '../../parts/Document/Document';
import notificationTypes from '../../../config/notification-type.json';

import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';

const variants = {
  [notificationTypes.info]: 'info',
  [notificationTypes.success]: 'success',
  [notificationTypes.warn]: 'warn',
  [notificationTypes.error]: 'error',
};

const Notification = (props) => {
  const { notification, onClose = () => {} } = props;
  const type = notification.type ?? notificationTypes.info;

  useEffect(() => {
    const timeout = notification.timeout
      ? setTimeout(() => {
          onClose(notification.id);
        }, notification.timeout)
      : null;

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      className={classnames('page-notification', variants[type])}
      elevation={5}
      onClick={() => onClose(notification.id)}
    >
      <div className="content">
        <Text>{notification.title}</Text>
        {notification.message && <Text>{notification.message}</Text>}
      </div>
      <div className="close-wrapper">
        <CloseIcon className="close-btn" />
      </div>
    </div>
  );
};

export default memo(Notification);
